import { InboxOutlined, SearchOutlined } from "@material-ui/icons";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Row,
  Spin,
  Typography,
} from "antd";
import Modal from "antd/lib/modal/Modal";
import Dragger from "antd/lib/upload/Dragger";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  AddTaskPhoneNumber,
  findCustomerOlderThanThreeDay,
  getContactWithTask,
  getTaskUserIdAndMonth,
  getUserCrmDailyStat,
  quantityOfContact,
  updatePhoneStatusCrm,
} from "../../RestServices/authService";
import { getOrderCountWRTStatus } from "../../RestServices/ecommerenceService";
import {
  getCommissionForDownandRev,
  getCommissionsAmount,
} from "../../RestServices/postingService";
import { getDailyTaskContactsReport } from "../../RestServices/statService";
import { uploadThumnail } from "../../RestServices/videosService";
import { checkPermission, phoneStatuses, sumCalculate } from "../../shared";
import "./crm.css";
import PhoneNumberFilter from "./PhoneNumberFilter";
import AddNumber from "./Stages/addNumber";
import StatDashboard from "./statDashboard/StatDashboard";
import StatDetails from "./statDetails/StatDetails";

export default function CrmComponent({ ...props }) {
  const [phoneNumberFilter, setPhoneNumberFilter] = useState(false);
  const showModal = () => {
    setPhoneNumberFilter(true);
  };

  const [addPhoneNumberLoading, setAddPhoneNumberLoading] = useState(false);

  const [reviewPictureModel, setReviewPicture] = useState(false);

  const [phoneInCategory, setPhoneInCategory] = useState({
    INITIAL: [],
    APP_DOWNLOADED: [],
    MESSAGE_SENT: [],
    CALLED_NUMBER: [],
    blocked: [],
    REVIEWED: [],
  });

  const [phoneNumber, setPhoneNumber] = useState("");

  const [selectedDate, setSelectedDate] = useState(
    moment().format("MMMM YYYY"),
  );

  const [modelAddNumVisible, setModelAddNumVisible] = useState(false);

  const [taskDetail, setTaskDetail] = useState(undefined);

  const [userAndProfile, setUserAndProfile] = useState([]);
  const [monthCommission, setMonthlyCommission] = useState([]);

  const {
    token,
    _id: userId,
    profileDetails: { permissions },
  } = useSelector((state) => state.Authentication.user);

  const [statusItem, setStatusItem] = useState("INITIAL");
  const [userType, setUserType] = useState("");

  const [contactCount, setContactCount] = useState();
  const [totalContact, setTotalContact] = useState();
  const [loading, setLoading] = useState(false);

  const getSetUp = async () => {
    try {
      setLoading(true);
      setTimeout(async () => {
        const { contacts } = await getContactWithTask(
          {
            userId: checkPermission(permissions, "AllResources")
              ? undefined
              : userId,
            status: statusItem,
            userType,
          },
          token,
        );
        const phoneInCategory = {
          INITIAL: [],
          APP_DOWNLOADED: [],
          MESSAGE_SENT: [],
          CALLED_NUMBER: [],
          blocked: [],
          REVIEWED: [],
        };
        for (let contact of contacts) {
          phoneInCategory["INITIAL"].push(contact);
        }
        setPhoneInCategory(phoneInCategory);
        setLoading(false);
      }, 2000);
    } catch (err) {
      console.log(err);
      setLoading(true);
    }

    try {
      const response = await getTaskUserIdAndMonth({
        month: selectedDate,
        userId: checkPermission(permissions, "AllResources")
          ? undefined
          : userId,
      });

      if (response.tasks.length) {
        setTaskDetail(response.tasks[0]);
        getDailyTaskContacts(response?.tasks[0]?._id);
      }
      getCommission();
    } catch (err) {
      console.log(err);
    }
  };

  const getDailyTaskContacts = async (taskId) => {
    try {
      const data = await getDailyTaskContactsReport({
        taskId: checkPermission(permissions, "AllResources")
          ? undefined
          : userId,
        date: moment().subtract(1, "day").format("MMMM DD YYYY"),
      });
      setUserAndProfile(data);
    } catch (err) {
      console.log(err);
    }
  };

  const getCommission = async (taskId) => {
    try {
      const data = await getCommissionsAmount(
        {
          referenceOf: checkPermission(permissions, "AllResources")
            ? undefined
            : userId,
          month: selectedDate,
        },
        token,
      );
      setMonthlyCommission(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getSetUp();
  }, [statusItem, userType]);

  useEffect(() => {
    getSetUp();
    contactList();
    getCommission();
  }, [selectedDate]);

  const handleRefreshClick = () => {
    getSetUp();
    contactList();
    getCommission();
  };

  const handleOk = () => {
    setPhoneNumberFilter(false);
  };

  const onChangeDatePicker = (date, dateString) => {
    setSelectedDate(date?.format("MMMM YYYY"));
  };

  const handleCancel = () => {
    setPhoneNumberFilter(false);
  };

  const contactList = async () => {
    try {
      const contacts = await quantityOfContact(
        {
          userId: checkPermission(permissions, "AllResources")
            ? undefined
            : userId,
        },
        token,
      );
      setTotalContact(
        sumCalculate(contacts.contact.contact.map((item) => item.count)),
      );
      setContactCount(contacts.contact);
    } catch (err) {
      console.log(err);
    }
  };

  const [searchByPhoneNumber, setSearchByPhoneNumber] = useState("");

  // INITIAL

  const onUpdateContactStatus = async ({
    _id,
    taskId,
    status,
    blocked,
    userType,
    phone,
  }) => {
    try {
      await updatePhoneStatusCrm(
        {
          contactId: _id,
          taskId,
          status,
          userType,
          blockedReason: blocked,
          blocked: Boolean(blocked),
        },
        token,
      );
      if (!userType) {
        return;
      }
      if ([phoneStatuses.REVIEWED].includes(status))
        try {
          const dataPayload = {
            category:
              phoneStatuses.APP_DOWNLOADED === status ? "Download" : "Review",
            subCategory:
              phoneStatuses.APP_DOWNLOADED === status ? "download" : "review",
            createdBy: phone,
            taskId: taskId,
            referenceOf: userId,
            userType: userType,
          };
          await getCommissionForDownandRev(dataPayload, token);
        } catch (err) {
          console.log(err);
        }
      getSetUp();
    } catch (err) {
      if (err.response) {
        return message.error(err.response.data.message);
      }
      return message.error(err.message);
    }
  };

  const [userDetails, setUserDetail] = useState({
    activeUser: 0,
    totalUser: 0,
    idolUser: 0,
    sluggishUser: 0,
  });
  useEffect(() => {
    getSetUpUserDailyStat();
    getOrderCount();
  }, []);

  const [totalOrder, setTotalOrder] = useState(0);

  const add = (accumulator, a) => {
    return accumulator + a;
  };
  const getOrderCount = async () => {
    try {
      const { data } = await getOrderCountWRTStatus(token, {
        crmUserId: checkPermission(permissions, "AllResources")
          ? undefined
          : userId,
      });
      const values = Object.values(data);
      if (values.length) {
        setTotalOrder(values.reduce(add, 0));
      }
    } catch (err) {
      console.log(err, "er");
    }
  };
  const getSetUpUserDailyStat = async () => {
    const { userDailyStat } = await getUserCrmDailyStat(
      {
        userId: checkPermission(permissions, "AllResources")
          ? undefined
          : userId,
      },
      token,
    );

    setUserDetail({ ...userDailyStat });
  };

  const onCreatePhone = async () => {
    try {
      if (phoneNumber.trim().length !== 11) {
        return message.error("Please add complete number start from 03");
      }
      if (!taskDetail) {
        return message.error("As you are not assigned any task yet");
      }
      if (!phoneNumber.startsWith("03")) {
        return message.error("Phone number must start from 03");
      }
      const payloadNumber = {
        phoneNumber: "+92" + phoneNumber.trim().slice(1),
      };

      const { customer } = await findCustomerOlderThanThreeDay(
        token,
        payloadNumber,
      );

      if (customer) {
        return message.error("This number is already in use");
      }

      setAddPhoneNumberLoading(true);

      const response = await AddTaskPhoneNumber(
        {
          userId,
          taskId: taskDetail._id,
          phoneNumber: phoneNumber.trim(),
          status: "INITIAL",
        },
        token,
      );

      // this.getAdminOrSubAdminsInCom();
      getSetUp();
      setPhoneNumber("");
      setModelAddNumVisible(false);
      console.log(response);
    } catch (err) {
      console.log(err);
      if (err.response) {
        return message.error(err.response.data.message);
      }
      return message.error(err.message);
    } finally {
      setAddPhoneNumberLoading(false);
    }
  };

  const [openReviewData, setOpenReviewData] = useState(undefined);
  const openReviewScreen = (data) => {
    setOpenReviewData({
      ...data,
    });
    setReviewPicture(true);
  };

  const [isFormLoading, setFormLoading] = useState(false);
  const [uploadedPercentage, setUploadedPercentage] = useState(0);
  const [bannerLinks, setBannerLinks] = useState([]);
  const [images, setImages] = useState([]);

  const uploadThumbnail = async (thumbnail) => {
    setFormLoading(true);

    setUploadedPercentage(0);
    const formData = new FormData();
    formData.append("thumbnail", thumbnail);
    try {
      const response = await uploadThumnail(formData, token, (percentage) =>
        setUploadedPercentage(percentage),
      );
      setBannerLinks([...bannerLinks, response.thumnailUrl]);
    } catch (err) {
      if (err.response) {
        return message.error(err.response.data.message);
      }
      return message.error(err.message);
    } finally {
      setFormLoading(false);
    }
  };

  const thumbnailProps = {
    onRemove: (image) => {
      let imag = images.filter((value) => value.name !== image.name);
      setImages(imag);
    },

    beforeUpload: (image) => {
      uploadThumbnail(image);
      setImages([...images, image]);
      return false;
    },
    fileList: images ? images : [],
    accept: "image/*",
  };

  const submitReview = async () => {
    try {
      if (!bannerLinks.length) {
        return;
      }
      setReviewPicture(false);
      await updatePhoneStatusCrm(
        {
          ...openReviewData,
          contactId: openReviewData._id,
          status: "REVIEWED",
          reviewPictures: bannerLinks,
        },
        token,
      );
      try {
        const dataPayload = {
          category: "Review",
          subCategory: "review",
          createdBy: openReviewData.phoneNumber,
          taskId: openReviewData.taskId,
          referenceOf: checkPermission(permissions, "AllResources")
            ? undefined
            : userId,
        };
        await getCommissionForDownandRev(dataPayload, token);
      } catch (err) {
        console.log(err);
      }
      setBannerLinks([]);
      setImages([]);
      getSetUp();
    } catch (err) {
      if (err.response) {
        return message.error(err.response.data.message);
      }
      return message.error(err.message);
    }
  };

  const [statDetailModal, setStatDetailModal] = useState([]);

  return (
    <div style={{ padding: "10px" }}>
      <StatDetails
        statDetailModal={statDetailModal}
        setStatDetailModal={setStatDetailModal}
        selectedDate={selectedDate}
      />
      <Modal
        visible={modelAddNumVisible}
        title=""
        onCancel={() => setModelAddNumVisible(false)}
        style={{ borderRadius: "50px" }}
        width={"400px"}
        height={"600px"}
        footer={null}
      >
        <Form layout={"vertical"} name="control-hooks">
          <Row justify="center">
            <Col span={24}>
              <Typography.Title className="add_number_title" level={2}>
                Enter Phone Number
              </Typography.Title>
            </Col>
            <Col span={24}>
              <Typography.Title className="add_number" level={4}>
                Add new phone number CRM System
              </Typography.Title>
            </Col>
          </Row>
          <Form.Item required label="" rules={[{ required: true }]}>
            <Input
              value={phoneNumber}
              type="string"
              placeholder="0315000000"
              onChange={(e) => setPhoneNumber(e.target.value)}
              name={"phoneNumber"}
            />
          </Form.Item>

          <Row justify="center">
            <Col span={24}>
              <Button
                className="button_add_number"
                loading={addPhoneNumberLoading}
                onClick={onCreatePhone}
                type={"primary"}
              >
                Add Phone Number
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        visible={reviewPictureModel}
        title="Add review picture"
        onCancel={() => setReviewPicture(false)}
        footer={null}
      >
        <Form layout={"vertical"} name="control-hooks">
          <Form.Item
            required
            label="Mobile Number"
            rules={[{ required: true }]}
          >
            <Col span={24}>
              {/* <Input style={{ marginTop: '10px' }} placeholder="PKR 500" /> */}
              <Dragger {...thumbnailProps}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-hint">Upload Image/Video/GIF</p>
                <p className="ant-upload-hint">.JPG/.PNG/MP3/MP4</p>
              </Dragger>
            </Col>
          </Form.Item>

          <Row justify="center">
            <Col span={24}>
              <Button onClick={submitReview} type={"primary"}>
                Submit Review Picture
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Row gutter={[16, 16]}>
        <Col style={{ marginBottom: "10px" }}>
          <div className="CrmHeading">Welcome to CRM System!</div>
          <div className="crmSystempm">Poultry Baba CRM System</div>
        </Col>
        <Col>
          {loading ? (
            <div
              className="refreshButton1"
            >
              <Spin size="large" />
            </div>
          ) : (
            <div
              className="refreshButton1"
              onClick={handleRefreshClick}
            >
              <div>
                <div className="tearning1">Total Earnings</div>
                <div className="t_amount1">Rs{" "}
                  {(() => {
                    let total = 0;
                    for (let value of monthCommission) {
                      total += value?.sumQuantity;
                    }
                    return total.toFixed(0);
                  })()}
                </div>
              </div>
              <div style={{ marginLeft: "10px" }}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15 0C6.72 0 0 6.72 0 15C0 23.28 6.72 30 15 30C23.28 30 30 23.28 30 15C30 6.72 23.28 0 15 0ZM6.9 13.845C7.155 12.06 7.95 10.455 9.195 9.195C12.195 6.21 16.92 6.045 20.13 8.655V7.23C20.13 6.615 20.64 6.105 21.255 6.105C21.87 6.105 22.38 6.615 22.38 7.23V11.235C22.38 11.85 21.87 12.36 21.255 12.36H17.25C16.635 12.36 16.125 11.85 16.125 11.235C16.125 10.62 16.635 10.11 17.25 10.11H18.375C16.05 8.49 12.84 8.715 10.77 10.785C9.87 11.685 9.3 12.855 9.105 14.16C9.03 14.715 8.55 15.12 7.995 15.12C7.935 15.12 7.89 15.12 7.83 15.105C7.245 15.03 6.81 14.46 6.9 13.845ZM20.805 20.805C19.2 22.41 17.1 23.205 15 23.205C13.17 23.205 11.355 22.56 9.855 21.345V22.755C9.855 23.37 9.345 23.88 8.73 23.88C8.115 23.88 7.605 23.37 7.605 22.755V18.75C7.605 18.135 8.115 17.625 8.73 17.625H12.735C13.35 17.625 13.86 18.135 13.86 18.75C13.86 19.365 13.35 19.875 12.735 19.875H11.61C13.935 21.495 17.145 21.27 19.215 19.2C20.115 18.3 20.685 17.13 20.88 15.825C20.97 15.21 21.525 14.775 22.155 14.865C22.77 14.955 23.19 15.525 23.115 16.14C22.845 17.955 22.05 19.56 20.805 20.805Z"
                    fill="#1DBF73"
                  />
                </svg>
              </div>
            </div>
          )}
        </Col>

        <Col style={{ flexGrow: 1 }}></Col>
        <Col>
          <div>
            <DatePicker
              className="date-picker-crm"
              value={moment(selectedDate)}
              onChange={onChangeDatePicker}
              picker="month"
              style={{ marginBottom: "5px" }}
            />
          </div>
        </Col>

        <Col>
          <div className="d-flex flex-column">
            <div className="phoneBtn" onClick={showModal} type={"primary"}>
              <svg
                style={{ marginRight: "10px" }}
                width="15"
                height="15"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.0002 9.55C19.5902 9.55 19.2502 9.21 19.2502 8.8V4.75H15.2002C14.7902 4.75 14.4502 4.41 14.4502 4C14.4502 3.59 14.7902 3.25 15.2002 3.25H20.0002C20.4102 3.25 20.7502 3.59 20.7502 4V8.8C20.7502 9.21 20.4102 9.55 20.0002 9.55Z"
                  fill="white"
                />
                <path
                  d="M11.05 14.95L9.2 16.8C8.81 17.19 8.19 17.19 7.79 16.81C7.68 16.7 7.57 16.6 7.46 16.49C6.43 15.45 5.5 14.36 4.67 13.22C3.85 12.08 3.19 10.94 2.71 9.81C2.24 8.67 2 7.58 2 6.54C2 5.86 2.12 5.21 2.36 4.61C2.6 4 2.98 3.44 3.51 2.94C4.15 2.31 4.85 2 5.59 2C5.87 2 6.15 2.06 6.4 2.18C6.66 2.3 6.89 2.48 7.07 2.74L9.39 6.01C9.57 6.26 9.7 6.49 9.79 6.71C9.88 6.92 9.93 7.13 9.93 7.32C9.93 7.56 9.86 7.8 9.72 8.03C9.59 8.26 9.4 8.5 9.16 8.74L8.4 9.53C8.29 9.64 8.24 9.77 8.24 9.93C8.24 10.01 8.25 10.08 8.27 10.16C8.3 10.24 8.33 10.3 8.35 10.36C8.53 10.69 8.84 11.12 9.28 11.64C9.73 12.16 10.21 12.69 10.73 13.22C10.83 13.32 10.94 13.42 11.04 13.52C11.44 13.91 11.45 14.55 11.05 14.95Z"
                  fill="white"
                />
                <path
                  d="M21.9696 18.3291C21.9696 18.6091 21.9196 18.8991 21.8196 19.1791C21.7896 19.2591 21.7596 19.3391 21.7196 19.4191C21.5496 19.7791 21.3296 20.1191 21.0396 20.4391C20.5496 20.9791 20.0096 21.3691 19.3996 21.6191C19.3896 21.6191 19.3796 21.6291 19.3696 21.6291C18.7796 21.8691 18.1396 21.9991 17.4496 21.9991C16.4296 21.9991 15.3396 21.7591 14.1896 21.2691C13.0396 20.7791 11.8896 20.1191 10.7496 19.2891C10.3596 18.9991 9.96961 18.7091 9.59961 18.3991L12.8696 15.1291C13.1496 15.3391 13.3996 15.4991 13.6096 15.6091C13.6596 15.6291 13.7196 15.6591 13.7896 15.6891C13.8696 15.7191 13.9496 15.7291 14.0396 15.7291C14.2096 15.7291 14.3396 15.6691 14.4496 15.5591L15.2096 14.8091C15.4596 14.5591 15.6996 14.3691 15.9296 14.2491C16.1596 14.1091 16.3896 14.0391 16.6396 14.0391C16.8296 14.0391 17.0296 14.0791 17.2496 14.1691C17.4696 14.2591 17.6996 14.3891 17.9496 14.5591L21.2596 16.9091C21.5196 17.0891 21.6996 17.2991 21.8096 17.5491C21.9096 17.7991 21.9696 18.0491 21.9696 18.3291Z"
                  fill="white"
                />
              </svg>
              Phone
            </div>
          </div>
        </Col>
      </Row>
      <PhoneNumberFilter
        phoneNumberFilter={phoneNumberFilter}
        showModal={showModal}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />

      <StatDashboard
        setStatDetailModal={setStatDetailModal}
        monthCommission={monthCommission}
      />

      <Row align="center" gutter={[16, 16]} justify="end">
        <Col>
          <Input
            style={{ borderRadius: "50px" }}
            prefix={<SearchOutlined />}
            className="search"
            value={searchByPhoneNumber}
            onChange={(e) => setSearchByPhoneNumber(e.target.value)}
            type="text"
            placeholder="Search"
          />
        </Col>
        <Col>
          <button
            onClick={() => setModelAddNumVisible(true)}
            type={"primary"}
            className="add_number-btn"
            style={{ borderRadius: "50px" }}
          >
            + Add Phone #
          </button>
        </Col>
      </Row>
      <Row>
        <Col lg={24} md={24} sm={24} xs={24}>
          <div className="tab-group">
            <Row
              gutter={[16, 16]}
              style={{
                marginTop: "20px",
                width: "100%",
                display: "flex",
                flexWrap: "nowrap",
                overflowX: "auto",
              }}
            >
              <Col md={18} sm={24}>
                <AddNumber
                  searchByPhoneNumber={searchByPhoneNumber}
                  onUpdateContactStatus={onUpdateContactStatus}
                  phoneList={phoneInCategory.INITIAL}
                  setReviewPicture={setReviewPicture}
                  openReviewScreen={openReviewScreen}
                  showModal={() => setModelAddNumVisible(true)}
                />
              </Col>

              <Col className="div_back" md={6} sm={24}>
                <div style={{ padding: "8px 8px" }}>
                  <div className="order__filter__date">
                    <h1 className="order__heading">Overall Statistics</h1>
                    <DatePicker
                      format={"MM-DD"}
                      value={moment()}
                      bordered={false}
                    />
                  </div>
                  <Divider style={{ margin: "10px 0" }} />

                  {contactCount?.contact?.map((value, key) => {
                    return (
                      <div key={key} className="order__content">
                        <div className="order__inner">
                          <div
                            className={`counting__wrapper counting__wrapper__${key + 1
                              }`}
                          >
                            <span>{((value.count / totalContact) * 100).toFixed(0)}%</span>
                          </div>
                          <div className="order__text">
                            <span>{value.count}</span>
                            <h5>{value._id.status.split("_").join(" ")}</h5>
                          </div>
                        </div>
                        <span
                          onClick={() => {
                            setUserType("");
                            setStatusItem(value._id.status);
                          }}
                          className="order__button"
                        >
                          View list
                        </span>
                      </div>
                    );
                  })}
                </div>
              </Col>

              {/* <CallNumber
                onUpdateContactStatus={onUpdateContactStatus}
                phoneList={phoneInCategory.CALLED_NUMBER}
              />
              <LinkSended
                onUpdateContactStatus={onUpdateContactStatus}
                phoneList={phoneInCategory.MESSAGE_SENT}
              />
              <Download
                onUpdateContactStatus={openReviewScreen}
                phoneList={phoneInCategory.APP_DOWNLOADED}
              />
              <BlockedNumber
                onUpdateContactStatus={console.log}
                phoneList={phoneInCategory.blocked}
              /> */}

              {/* <PostAndProfile
                monthCommission={monthCommission}
                profileDetail={userAndProfile}
              />
              <Reviews
                onUpdateContactStatus={console.log}
                phoneList={phoneInCategory.REVIEWED}
              /> */}
            </Row>

            {/* <TabPane tab="Orders" key="5">
                <MurgiMandiOffer userId={userId} />
              </TabPane> */}
          </div>
        </Col>
      </Row>
    </div>
  );
}

//  <Col>
//               <div className="d-flex flex-column">
//                 <h3 className="t">Post Score</h3>
//                 <Button
//                   style={{ backgroundColor: "lightpink", marginRight: "5px" }}
//                 >
//                   {(() => {
//                     let postQuantity = 0;
//                     for (let com of monthCommission) {
//                       if (
//                         com._id === "Grain Market" ||
//                         com._id === "Poultry Bazar" ||
//                         com._id === "Poultry Market" ||
//                         com._id === "Pets Point" ||
//                         com._id === "FOORI ZRORRAT" ||
//                         com._id === "Meri Zameen" ||
//                         com._id === "Meri Rozi"
//                       ) {
//                         postQuantity += com.quantity;
//                       }
//                     }

//                     return postQuantity ? postQuantity : 0;
//                   })()}
//                 </Button>
//                 {(() => {
//                   let amount = 0;
//                   for (let com of monthCommission) {
//                     if (
//                       com._id === "Grain Market" ||
//                       com._id === "Poultry Bazar" ||
//                       com._id === "Poultry Market" ||
//                       com._id === "Pets Point" ||
//                       com._id === "FOORI ZRORRAT" ||
//                       com._id === "Meri Zameen" ||
//                       com._id === "Meri Rozi"
//                     ) {
//                       amount += com.sumQuantity;
//                     }
//                   }

//                   return (
//                     <span className="p">
//                       {" "}
//                       RS.
//                       {amount ? amount.toFixed(0) : 0}{" "}
//                     </span>
//                   );
//                 })()}
//                 {/* <span className='p'>Rs. 2000</span> */}
//               </div>
//             </Col>
//             <Col>
//               <div className="d-flex flex-column">
//                 <h3 className="t">Review Score</h3>
//                 <Button
//                   style={{ backgroundColor: "lightpink", marginRight: "5px" }}
//                 >
//                   {(() => {
//                     const quantity = monthCommission.find(
//                       (com) => com._id === "Review",
//                     );
//                     return quantity ? quantity.quantity : 0;
//                   })()}
//                 </Button>
//                 {(() => {
//                   let amount = monthCommission.find(
//                     (com) => com._id === "Review",
//                   );
//                   return (
//                     <span className="p">
//                       {" "}
//                       RS.
//                       {amount ? amount.sumQuantity.toFixed(0) : 0}{" "}
//                     </span>
//                   );
//                 })()}
//                 {/* <span className='p'>Rs. 2000</span> */}
//               </div>
//             </Col>

//             <Col>
//               <div className="d-flex flex-column">
//                 <h3 className="t">Murgi Mandi</h3>
//                 <Button
//                   style={{ backgroundColor: "lightpink", marginRight: "5px" }}
//                 >
//                   {(() => {
//                     const quantity = monthCommission.find(
//                       (com) => com._id === "MURGHI MANDI",
//                     );
//                     return quantity ? quantity.quantity : 0;
//                   })()}
//                 </Button>
//                 {(() => {
//                   let amount = monthCommission.find(
//                     (com) => com._id === "MURGHI MANDI",
//                   );
//                   return (
//                     <span className="p">
//                       {" "}
//                       RS.
//                       {amount ? amount.sumQuantity.toFixed(0) : 0}{" "}
//                     </span>
//                   );
//                 })()}

//                 {/* <span className='p'>Rs. 2000</span> */}
//               </div>
//             </Col>

//             <Col>
//               <div className="d-flex flex-column">
//                 <h3 className="t">E commerce</h3>
//                 <Button
//                   style={{ backgroundColor: "lightpink", marginRight: "5px" }}
//                 >
//                   N/A
//                 </Button>
//                 {(() => {
//                   return <span className="p">Rs 0</span>;
//                 })()}
//                 {/* <span className='p'>Rs. 2000</span> */}
//               </div>
//             </Col>

//             <Col>
//               <div className="d-flex flex-column">
//                 <h3 className="t">Fori Zarorat</h3>
//                 <Button
//                   style={{ backgroundColor: "lightpink", marginRight: "5px" }}
//                 >
//                   {(() => {
//                     const quantity = monthCommission.find(
//                       (com) => com._id === "FOORI ZRORRAT",
//                     );
//                     return quantity ? quantity.quantity : 0;
//                   })()}
//                 </Button>
//                 {(() => {
//                   let amount = monthCommission.find(
//                     (com) => com._id === "FOORI ZRORRAT",
//                   );
//                   return (
//                     <span className="p">
//                       {" "}
//                       RS.
//                       {amount ? amount.sumQuantity.toFixed(0) : 0}{" "}
//                     </span>
//                   );
//                 })()}
//                 {/* <span className='p'>Rs. 2000</span> */}
//               </div>
//             </Col>

{
  /* <TabPane tab="Overall Statistics" key="3">
                <UserStat {...props} userDetails={userDetails} />
                <Row>
                  <Col lg={6} md={12} sm={12} xs={24}>
                    <div
                      className="order-box"
                      style={{
                        background: "linear-gradient(45deg, #FC9C39, #FBB438)",
                      }}
                    >
                      <div className="order-text">Total Posts</div>
                      <div className="order-count">
                        {
                          <span className="NumberStyle">
                            {(() => {
                              let total = 0;
                              if (userAndProfile.length) {
                                for (let value of userAndProfile) {
                                  total += value?.TotalCount;
                                }
                                return total;
                              } else {
                                return 0;
                              }
                            })()}
                          </span>
                        }
                      </div>
                    </div>
                  </Col>
                  <Col lg={9} md={12} sm={14} xs={24}>
                    <div className="order-box order-box2">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <div
                          onClick={() => {
                            props.setSelected(
                              "Dashboard/User",
                              "Crm Golden User",
                            );
                          }}
                          style={{
                            flexDirection: "column",
                            flexGrow: 1,
                            paddingTop: "12px",
                            paddingBottom: "12px",
                            marginRight: "5px",
                            background:
                              "linear-gradient(45deg, #FC9C39, #FBB438)",
                          }}
                        >
                          <div className="order-text2 order-text-lg">
                            Golden <br /> Members
                          </div>
                          <div className="order-count2">
                            {userDetails.activeUser + 20}
                          </div>
                        </div>

                        <div
                          onClick={() => {
                            props.setSelected(
                              "Dashboard/User",
                              "Crm Rising User",
                            );
                          }}
                          style={{
                            flexDirection: "column",
                            flexGrow: 1,
                            paddingTop: "12px",
                            paddingBottom: "12px",
                            background:
                              "linear-gradient(0deg, #FC9C39, #FBB438)",
                          }}
                        >
                          <div className="order-text2  order-text-lg">
                            Rising <br />
                            Members
                          </div>

                          <div className="order-count2">
                            {userDetails.activeUser > 25
                              ? userDetails.activeUser - 25
                              : 0}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  {/* <Col lg={9} md={24} sm={24} xs={24}>
                    <div
                      className='order-box ml-sm-0'
                      style={{
                        marginLeft: '20px',
                        background: 'linear-gradient(45deg, #FFAAD5, #F64AEE)',
                      }}
                    >
                      <div className='order-text'>Total Fans</div>
                      <div className='order-count'>
                        {userDetails.activeUser}
                      </div>
                    </div>
                  </Col>

                  */
}

//     <RejectUSer />
//   </Row>
//   {/* <Row>
//         <Col lg={24} md={24} sm={24} xs={24}>
//           <div style={{overflowX: 'scroll'}}>
//             <Table1/>
//           </div>
//         </Col>
//       </Row> */}
// </TabPane>
// <TabPane tab="Murghi mandi Stat" key="4">
//   <MurghiMundiState userId={userId} />
// </TabPane>

// const menu = (
//   <Menu>
//     <Menu.Item key="1">
//       {/* <Row gutter={[12, 12]}>
//         <Col span={16}>Assigned Numbers</Col>
//         <Col span={3}></Col>
//         <Col span={5}>12</Col>
//       </Row> */}
//     </Menu.Item>
//     <Menu.Item key="2">
//       {" "}
//       <Row gutter={[12, 12]}>
//         <Col span={16}>Assigned Download</Col>
//         <Col span={3}></Col>
//         <Col span={5}>{taskDetail ? taskDetail.downloads : "Not assign"}</Col>
//       </Row>
//     </Menu.Item>
//     <Menu.Item key="2">
//       {" "}
//       <Row gutter={[12, 12]}>
//         <Col span={16}>Assigned Review</Col>
//         <Col span={3}></Col>
//         <Col span={5}>{taskDetail ? taskDetail.reviews : "Not assign"}</Col>
//       </Row>
//     </Menu.Item>
//     <Menu.Item key="3">
//       <Row gutter={[12, 12]}>
//         <Col span={16}>Assigned Post's</Col>
//         <Col span={3}></Col>
//         <Col span={5}>{taskDetail ? taskDetail.posts : "Not assign"}</Col>
//       </Row>
//     </Menu.Item>
//   </Menu>
// );
