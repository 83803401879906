import { Button, Col, Form, Input, Row, Select, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { getProductsServiceApi } from "../../../RestServices/ecommerenceService";
import "./InqueryOrder.css";
import InqueryOrderModal from "./InqueryOrderModal";
import axios from "axios";
const { Option } = Select;
const { Title, Text } = Typography;
export default function InqueryOrderDetails({
  createinqueryOrder,
  setCreateInqueryOrder,
  createInqueryApi,
  onFinish,
  loadingButton,
  onClose,
  token,
  productData
}) {

  console.log(productData);
  // console.log(createinqueryOrder, "createinqueryOrder");
  const [inqueryModal, setInqueryModal] = useState(false);

  const [productList, setProductList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const getProduct = async () => {
    const data = await getProductsServiceApi(token, {
      page: 1,
    });

    // setData(data.data);
    console.log(data);
    setProductList(data.data.docs);
  };

  useEffect(() => {
    getProduct();
  }, [token]);

  return (
    <>
      <div>
        <InqueryOrderModal open={inqueryModal} setOpen={setInqueryModal} />
      </div>
      <div className="inqueryOrder__detail">
        <div className="inqueryOrder__detail__info">
          <Title level={4}>Live Location:</Title>
          <Text type="secondary">
            134 Rightward way, Makran Coast, National Highway, Karachi, Pak
          </Text>
        </div>
        <div className="inqueryOrder__detail__info">
          <Title level={4}>Delivery Address:</Title>
          <Text type="secondary">
            134 Rightward way, Makran Coast, National Highway, Karachi, Pak
          </Text>
        </div>
        <div className="inqueryOrder__detail__info">
          <Title level={4}>Business Name:</Title>
          <Text type="secondary">Kahloon Poultry Farm</Text>
        </div>
      </div>
      <div>
        <Row
          justify="start"
          align="top"
          style={{ marginTop: "30px" }}
          className="inqueryOrder__detail__form"
        >
          <Col span={24}>
            <Form layout={"vertical"} onFinish={onFinish}>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label={"Select Product:"}
                    initialValue={createinqueryOrder.productId}
                  >
                    <Select
                      showSearch
                      className="ant-select-custom"
                      placeholder="Product Name"
                      optionFilterProp="children"
                      onChange={(e) => {
                        setCreateInqueryOrder({
                          ...createinqueryOrder,
                          productId: e,
                        });
                        setSelectedProduct(e)
                      }}
                      value={createinqueryOrder.productId}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {productList.map((cat) => (
                        <Option key={cat._id} value={cat._id}>
                          {cat.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Row gutter={10}>
                  <Col span={12}>
                    <Form.Item
                      label={"Original Rate"}
                      initialValue={createinqueryOrder.originPrice}
                    >
                      <Input
                        // value={createinqueryOrder.originPrice === "" ? productList.find((product) => product._id === selectedProduct)?.price : createinqueryOrder.originPrice}
                        value={createinqueryOrder.originPrice}
                        onChange={(e) =>
                          setCreateInqueryOrder({
                            ...createinqueryOrder,
                            originPrice: e.target.value,
                          })
                        }
                        style={{
                          background: "#e9e9e9",
                          borderRadius: "8px",
                          border: "1px solid #e9e9e9",
                          height: "45px",
                          fontSize: "14px",
                        }}
                        name="originPrice"
                        placeholder="18000"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={"Your Offer Rate"}
                      initialValue={createinqueryOrder.offerPrice}
                    >
                      <Input
                        value={createinqueryOrder.offerPrice}
                        onChange={(e) =>
                          setCreateInqueryOrder({
                            ...createinqueryOrder,
                            offerPrice: e.target.value,
                          })
                        }
                        style={{
                          background: "#e9e9e9",
                          borderRadius: "8px",
                          border: "1px solid #e9e9e9",
                          height: "45px",
                          fontSize: "14px",
                        }}
                        name="offerPrice"
                        placeholder="16000"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Col span={24}>
                  <Form.Item
                    label={"Product Source"}
                    initialValue={createinqueryOrder.productSource}
                  >
                    <Input
                      // value={createinqueryOrder.productSource === "" ? productList.find((product) => product._id === selectedProduct)?.made : createinqueryOrder.productSource}
                      value={createinqueryOrder.productSource}
                      onChange={(e) =>
                        setCreateInqueryOrder({
                          ...createinqueryOrder,
                          productSource: e.target.value,
                        })
                      }
                      style={{
                        background: "#e9e9e9",
                        borderRadius: "8px",
                        border: "1px solid #e9e9e9",
                        height: "45px",
                        fontSize: "14px",
                      }}
                      name="productSource"
                      placeholder="Made in Pakistan"
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label={"Delivery Time in Days"}
                    initialValue={createinqueryOrder.deliveryTimeInDays}
                  >
                    <Input
                      value={createinqueryOrder.deliveryTimeInDays}
                      onChange={(e) =>
                        setCreateInqueryOrder({
                          ...createinqueryOrder,
                          deliveryTimeInDays: e.target.value,
                        })
                      }
                      type="number"
                      style={{
                        background: "#e9e9e9",
                        borderRadius: "8px",
                        border: "1px solid #e9e9e9",
                        height: "45px",
                        fontSize: "14px",
                      }}
                      name="deliveryTimeInDays"
                      placeholder="7 Days"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={40} style={{ padding: "30px 0" }}>
                <Col span={10}>
                  <Button
                    onClick={onClose}
                    type="primary"
                    htmlType="submit"
                    style={{
                      width: "140px",
                      height: "50px",
                      backgroundColor: "#E4E4E4",
                      color: "#000000",
                      border: "0",
                      fontSize: "14px",
                      fontWeight: "600",
                      borderRadius: "50px",
                    }}
                  >
                    {`Cancel`}
                  </Button>
                </Col>

                <Col span={10}>
                  <Button
                    style={{
                      width: "140px",
                      height: "50px",
                      backgroundColor: "#FCB539",
                      color: "#000000",
                      border: "0",
                      fontSize: "14px",
                      fontWeight: "600",
                      borderRadius: "50px",
                      cursor: "pointer",
                    }}
                    disabled={Boolean(createinqueryOrder._id)}
                    onClick={() => createInqueryApi()}
                    type="primary"
                    loading={loadingButton}
                    htmlType="submit"
                  >
                    {" "}
                    {"Send Offer"}
                  </Button>
                </Col>
              </Row>

              <Row
                justify="center"
                style={{ marginBottom: "20px", marginTop: "20px" }}
              >
                <Col
                  span={24}
                  style={{ borderBottom: "1.5px dashed #D8D8D8" }}
                ></Col>
              </Row>

              <Row style={{ marginBottom: "20px" }}>
                <Col span={24}>
                  <Text
                    style={{
                      color: "rgba(102, 102, 102, 0.8)",
                      fontSize: "11px",
                    }}
                  >
                    When you confirm your bid, it means you’re committing to buy
                    this item if you’re the winning bidder. It also means you’re
                    read and agreed to the{" "}
                    <span style={{ color: "#000", fontWeight: "600" }}>
                      Terms & Conditions.
                    </span>
                  </Text>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
}
