import { Avatar, Image, Modal, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getPosts } from "../../../RestServices/postingService";
import { formateString } from "../../../shared";

import AddUser from "../../AppUsers/AddUser";
import CustomPagination from "../../Pagination";

// eslint-disable-next-line import/no-anonymous-default-export
export default function TotalPostModal({
  isModalVisible,
  handleOk,
  handleCancel,
  data,
  selectedUserId,
  array,
  mode,
  filters,
}) {
  const tableColumns = [
    {
      key: "autoId",
      title: "Post Id",
      dataIndex: "autoId",
    },
    {
      title: "Profile Image",
      width: 20,
      dataIndex: "profileImage",
      fixed: "left",
      align: "center",
      render: (text, record, index) => (
        <Avatar
          src={
            <Image
              style={{ width: "80px" }}
              src={record?.customerProfile?.profileImage}
            />
          }
        />
      ),
    },

    {
      key: "name",
      title: "Name",
      dataIndex: "name",
      render: (text, record, index) => (
        <p
          onClick={() => {
            showModalUser();
            setSelectedUserId([record?.customerProfile]);
          }}
        >
          {record?.customerProfile?.name}
        </p>
      ),
    },

    {
      key: "phoneNumber",
      title: "Phone",
      dataIndex: "phoneNumber",
      render: (text, record, index) => (
        <a style={{ color: "blue" }}>{record?.customerProfile?.phoneNumber}</a>
      ),
    },
    {
      key: "customerCity",
      title: "Customer City",
      dataIndex: "citycusomter",
      render: (text, record, index) => <p>{record?.customerProfile?.city}</p>,
    },
    {
      key: "city",
      title: "Post City",
      dataIndex: "city",
    },

    {
      key: "category",
      title: "Category",
      dataIndex: "Category",
      render: (text, record, index) => <p>{record.postDetails?.subType}</p>,
    },
    {
      key: "postSold",
      title: "Sold",
      dataIndex: "postSold",
      render: (text, record, index) => (
        <p>{record?.postSold ? "Sold" : "Not yet"}</p>
      ),
    },
    {
      key: "Demand ",
      title: "Demand",
      dataIndex: "demand",
      render: (text, record, index) => (
        <p>
          {record.postDetails?.demand ||
            record.postDetails?.demandPerKg ||
            record.postDetails?.demandPerBird}
        </p>
      ),
    },

    {
      key: "Total(unit) ",
      title: "Total (unit)",
      dataIndex: "demand",
      render: (text, record, index) => (
        <p>
          {record.postDetails?.numberOfBirds ||
            record.postDetails?.totalWeightInKg ||
            record.postDetails?.quantity}
        </p>
      ),
    },

    {
      key: "createdAt",
      title: "Post Creation",
      dataIndex: "createdAt",
      render: (text, record, index) => (
        <p>{moment(text).format(formateString)}</p>
      ),
    },
  ];
  const [posts, setPosts] = useState([]);
  const { token } = useSelector((state) => state.Authentication.user);

  const [page, setPage] = useState(1);
  useEffect(() => {
    (async () => {
      try {
        const { posts } = await getPosts(token, {
          pageNo: page,
          status: mode,
          type: "MURGHI MANDI",
          ...filters,
        });

        // const allCustomers = customers.docs.map((customer) => ({
        //   name: customer.name,
        //   type: customer.profileDetails.type,
        //   status: customer.status,
        //   key: customer._id,
        //   updatedAt: moment(customer.lastProfileFetched).format(formateString),
        //   createdAt: moment(customer.createdAt).format(formateString),

        //   phoneNumber: customer.phoneNumber,
        //   category: Array.from(
        //     new Set(
        //       data?.stat?.find(
        //         (value) => value.customerId == customer._id,
        //       ).subType,
        //     ),
        //   ).join('\n'),
        //   paymentInTake:
        //     data?.payment?.find((value) => value.customerId == customer._id)
        //       ?.total || 0,

        //   profileImage: customer.profileImage,
        //   city: customer.city,
        // }));
        setPosts(posts);
      } catch (err) {
        console.log(err);
      }
    })();
  }, [mode, page, filters]);

  const [isModalVisibleUser, setIsModalVisibleUser] = useState(false);

  const showModalUser = () => {
    setIsModalVisibleUser(true);
  };

  const handleOkUser = () => {
    setIsModalVisibleUser(false);
  };

  const handleCancelUser = () => {
    setIsModalVisibleUser(false);
  };
  const [selectUser, setSelectedUserId] = useState([]);

  return (
    <div>
      <AddUser
        isOpen={isModalVisibleUser}
        closeModal={() => handleOkUser(false)}
        submitForm={() => handleCancelUser(false)}
        isFormLoading={false}
        selectedUser={selectUser}
      />
      <Modal
        width={1200}
        height={1000}
        footer={
          <CustomPagination
            current={page}
            total={posts?.totalDocs}
            pageSize={20}
            onPageChange={(pageNum) => pageNum !== page && setPage(pageNum)}
          />
        }
        title="Total Posts"
        style={{ overflow: "scroll" }}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Table
          dataSource={posts?.docs}
          pagination={false}
          columns={tableColumns}
          // onSelect={this.onS/elect}
          // rowKey={(record) => record._id}
        />
      </Modal>
    </div>
  );
}
