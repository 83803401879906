/* eslint-disable array-callback-return */
import { AntDesignOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Form,
  Image,
  Input,
  message,
  Row,
  Select,
  Upload,
} from "antd";
import Table from "../../../utils/Table";
import React, { useEffect, useState } from "react";
import useCategoryAndSubCategories from "../../../hook/useCategoryAndSubCategoryies";
import {
  createCategory,
  getCategories,
  uploadThumbnail,
} from "../../../RestServices/ecommerenceService";
import { Sorter } from "../../../utils/sorter";
import CustomPagination from "../../Pagination";
import { SideDrawer } from "../../Utils/SideDrawer";
import AsideCategory from "../AsideCategory";

const { Option } = Select;

const columns = [
  {
    title: "Id",
    dataIndex: "_id",
    render: (_id) => `${_id.slice(-8)}`,
    sorter: {
      compare: Sorter.DEFAULT,
      multiple: 3,
    },
  },
  {
    title: "Image",
    dataIndex: "icon",
    render: (icon) => (
      <Image
        style={{ width: "50px", height: "50px", borderRadius: "50%" }}
        width={50}
        height={50}
        src={icon}
        alt={icon}
      />
    ),
  },
  {
    title: "Category Name",
    dataIndex: "value",
    sorter: {
      compare: Sorter.DEFAULT,
      multiple: 2,
    },
  },
  {
    title: "Parent Category",
    dataIndex: "parentValue",
    sorter: {
      compare: Sorter.DEFAULT,
      multiple: 1,
    },
  },
];

export default function Category({ token, userId, startDate, endDate }) {
  const [data, setData] = useState({});
  const [dataCategory, setDataCategory] = useState([]);
  const [CategoryName, setCategoryName] = useState("");
  const [productType, setProductType] = useState("");
  const [customOrderNum, setCustomOrderNum] = useState('');
  const [parentValue, setSubCategoryName] = useState(undefined);
  const [innerCategoryValue, setInnerCategoryName] = useState(undefined);
  const [url, setUrl] = useState("");
  const [createCategoryModal, setCreateCategoryModal] = useState(false);

  const [category, setCategory] = useState(null);
  const [subCategory, setSubCategory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  const whenCreateCategory = async () => {
    if (!CategoryName) {
      message.warn("Please enter the name of category");
    }
    try {
      console.log(CategoryName, url, parentValue, productType, customOrderNum);
      await createCategory(
        {
          value: CategoryName,
          image: url,
          parentValue: innerCategoryValue ?? parentValue,
          productType: productType ?? productType,
          customOrderNum: customOrderNum ?? customOrderNum
        },
        token,
      );
      if (!parentValue) {
        getParentCategoryRequest();
      }
      message.success("Category created successfully.");
      setCategoryName("");
      setUrl("");
      getCategoriesRequest();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getCategoriesRequest();
  }, [token, category, subCategory]);

  const getCategoriesRequest = async () => {
    const data = await getCategories(token, {
      page: page,
      parentValue: subCategory ? subCategory : category,
    });

    setData(data.data);
    console.log(data.data);
    setDataCategory(data.data);
  };

  const [thumbnails, setThumbnails] = useState([]);

  const thumbnailProps = {
    onRemove: () => {
      // setArticleImage(null);
      setThumbnails(null);
    },
    beforeUpload: async (image) => {
      const url = await uploadThumbnail(image);
      setUrl(url);
      setThumbnails([thumbnails, ...image]);
      return false;
    },
    fileList: thumbnails,
    accept: "image/*",
  };

  const { categories, getParentCategoryRequest, subCategories } =
    useCategoryAndSubCategories({
      parentValue: category,
    });

  const {
    categories: categoriesOption,
    getParentCategoryRequest: getParentCategoryRequestOption,
    subCategories: subCategoriesOption,
  } = useCategoryAndSubCategories({
    parentValue: parentValue,
  });

  console.log("asdf", subCategoriesOption, "subCategoriesOption");

  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
      >
        <Row justify="end" gutter={[16, 16]}>
          <Col>
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Category"
              optionFilterProp="children"
              onChange={setCategory}
              value={category}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option key={"23423"} value={null}>
                {"All Category"}
              </Option>
              {categories?.map((cate) => (
                <Option key={cate.value} value={cate.value}>
                  {cate.value}
                </Option>
              ))}
            </Select>
          </Col>

          <Col>
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="sub Category"
              optionFilterProp="children"
              onChange={setSubCategory}
              value={subCategory}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option key={"23423"} value={null}>
                {"Sub category"}
              </Option>
              {subCategories.map((cate) => (
                <Option key={cate.value} value={cate.value}>
                  {cate.value}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        <SideDrawer
          setOpenNow={setCreateCategoryModal}
          openNow={createCategoryModal}
          textButton={"Create Category"}
        >
          <Form layout={"vertical"}>
            <Col span={24} style={{ textAlign: "center" }}>
              <Avatar
                size={{
                  xs: 50,
                  sm: 60,
                  md: 70,
                  lg: 80,
                  xl: 90,
                  xxl: 100,
                }}
                icon={
                  !createCategory.logo ? (
                    <AntDesignOutlined />
                  ) : (
                    <Image src={createCategory.logo} />
                  )
                }
              />
            </Col>
            <Col
              span={22}
              style={{
                marginBottom: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Upload {...thumbnailProps}>
                <Button
                  block
                  size="large"
                  icon={<UploadOutlined />}
                  style={{
                    marginTop: "18px",
                    marginLeft: "10px",
                    marginBottom: "20px",
                  }}
                >
                  image Upload
                </Button>
              </Upload>
            </Col>
            <div className="input__wrapper">
              <Form.Item labelAlign required requiredMark label="Category Name">
                <Input
                  name="category"
                  value={CategoryName}
                  onChange={(e) => setCategoryName(e.target.value)}
                  required
                />
              </Form.Item>

              <Form.Item labelAlign label="Parent Category">
                <Select
                  showSearch
                  placeholder="Parent Category"
                  optionFilterProp="children"
                  onChange={setSubCategoryName}
                >
                  {categoriesOption
                    ?.filter((value) => !value.parentValue)
                    ?.map((value) => {
                      return (
                        <Option key={value._id} value={value.value}>
                          {value.value}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>

              <Form.Item labelAlign label="Child Category">
                <Select
                  showSearch
                  placeholder="Child Category"
                  optionFilterProp="children"
                  onChange={setInnerCategoryName}
                >
                  {subCategoriesOption?.map((value) => {
                    return (
                      <Option key={value._id} value={value.value}>
                        {value.value}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <Form.Item labelAlign label="Product Type">
                <Select
                  showSearch
                  placeholder="Select product type"
                  onChange={(value) => setProductType(value)}
                  options={[
                    {
                      value: "KG",
                      label: "KG",
                    },
                    {
                      value: "LITER",
                      label: "LITER",
                    },
                  ]}
                />
              </Form.Item>

              <Form.Item labelAlign label="Custom Order">
                <Input
                  type="number"
                  name="customOrderNum"
                  value={customOrderNum}
                  onChange={(e) => setCustomOrderNum(e.target.value)}
                />
              </Form.Item>
              <Button onClick={whenCreateCategory} type="primary" style={{marginTop: "20px"}}>
                Create Category
              </Button>
            </div>
          </Form>
        </SideDrawer>

        <Row justify="end">
          {/* <Select
            showSearch
            style={{ width: 200, marginRight: "20px" }}
            placeholder="Select Filter"
            optionFilterProp="children"
            onChange={setCategory}
            value={category}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {orderEnum.map((value) => (
              <Option key={value} value={value}>
                {value}
              </Option>
            ))}
          </Select> */}
        </Row>

        <Row gutter={20}>
          <Col xs={24} sm={24} lg={24} xl={24} style={{ marginBottom: "20px" }}>
            <Table
              columns={columns}
              pagination={false}
              dataSource={dataCategory}
              loading={loading}
              // onChange={handleTableChange}
              style={{ overflowX: "scroll" }}
            />
            {/* <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                marginTop: "20px",
              }}
            >
              <CustomPagination
                current={data.page}
                total={data.totalDocs}
                onPageChange={(p) => setPage(p)}
              />
            </div> */}
          </Col>
          {/* <Col
            xs={24}
            sm={24}
            lg={6}
            xl={6}
            style={{
              padding: 0,
              background: "#fff",
              boxShadow: "0px 12px 23px rgba(62, 73, 84, 0.04)",
              marginBottom: "20px",
            }}
          >
            <AsideCategory />
          </Col> */}
        </Row>
      </div>
    </div>
  );
}
