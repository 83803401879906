import React, { Component } from "react";
import { Form, Input, Button } from "antd";
import { authService, emailPattern } from "../../shared";
import { connect } from "react-redux";
import "./styles.css";
import { signinActionCreater } from "../../Redux/ActionCreaters/authentication";
import { UserAddOutlined, UserOutlined } from "@ant-design/icons";
import { Lock } from "@material-ui/icons";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

class Signin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: authService.startsWith("https://api.poultrybaba.com/auth-service")
        ? "ali@gmail.com"
        : window.origin.startsWith("http://localhost")
        ? "ceo@poultrybaba.com"
        : "",
      emailIsValid: true,
      emailHelp: "",
      emailValidateStatus: "",

      password: authService.startsWith(
        "https://api.poultrybaba.com/auth-service",
      )
        ? ""
        : window.origin.startsWith("http://localhost")
        ? "~!@#poultry"
        : "",
      passwordIsValid: true,
      passwordHelp: "",
      passwordValidateStatus: "",
    };
  }
  onChangeValidator = (name, value) => {
    switch (name) {
      case "password":
        if (value.trim() === "") {
          this.setState({
            passwordValidateStatus: "error",
            passwordHelp: "Enter your password!",
            passwordIsValid: false,
          });
        } else {
          this.setState({
            passwordValidateStatus: "success",
            passwordHelp: "",
            passwordIsValid: true,
          });
        }
        break;
      case "email":
        if (!emailPattern.test(value)) {
          this.setState({
            emailValidateStatus: "error",
            emailHelp: "Enter a valid Email address!",
            emailIsValid: false,
          });
        } else {
          this.setState({
            emailValidateStatus: "success",
            emailHelp: "",
            emailIsValid: true,
          });
        }
        break;
      default:
        return;
    }
  };

  onChangeHandler = (evt) => {
    const { name, value } = evt.target;
    this.setState({ [name]: value }, () => this.onChangeValidator(name, value));
  };

  onSubmitHandler = (evt) => {
    evt.preventDefault();
    let isFormValid = true;
    if (!this.state.emailIsValid) {
      isFormValid = false;
      this.setState({
        emailValidateStatus: "error",
        emailHelp: "Enter your Email !",
      });
    }

    if (!this.state.passwordIsValid) {
      isFormValid = false;
      this.setState({
        passwordIsValid: false,
        passwordHelp: "Enter your password !",
        passwordValidateStatus: "error",
      });
    }

    if (isFormValid) {
      const { email, password } = this.state;
      this.props.dispatch(signinActionCreater({ email, password, props: this.props }));
    } else {
      console.log("Form Validation Error");
    }
  };

  render() {
    return (
      <div className="login-container">
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src="/image/logo.png" className="logo" alt="Poultrybaba" />
        </div>
        <div style={{ paddingLeft: "12px", paddingRight: "12px" }}>
        <Form colon={false} layout="vertical" onSubmit={this.onSubmitHandler}>
          <Form.Item
            validateStatus={this.state.emailValidateStatus}
            help={this.state.emailHelp}
            hasFeedback
          >
            <Input
              size="large"
              type="email"
              name="email"
              value={this.state.email}
              onChange={this.onChangeHandler}
              prefix={<UserOutlined type="user" className="input-icon" />}
              autoComplete="true"
              placeholder="Enter your Email"
            />
          </Form.Item>
          <Form.Item
            validateStatus={this.state.passwordValidateStatus}
            help={this.state.passwordHelp}
            hasFeedback
          >
            <Input.Password
              size="large"
              value={this.state.password}
              onChange={this.onChangeHandler}
              // type="password"
              name="password"
              autoComplete="true"
              prefix={<Lock className="input-icon" />}
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
              placeholder="Enter your password"
            />
          </Form.Item>
          <Form.Item wrapperCol={{ span: 24 }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                size="large"
                onClick={this.onSubmitHandler}
                loading={this.props.Auth.isLoading}
                // disabled={this.props.Auth.isLoading}
                style={{
                  backgroundColor: "black",
                  borderRadius: "25px",
                  width: "50%",
                  border: "none",
                }}
                htmlType="submit"
                type="primary"
              >
                Sign In
              </Button>
            </div>
          </Form.Item>
        </Form>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (store) => ({
  Auth: store.Authentication,
});
export default connect(mapStateToProps)(Signin);
