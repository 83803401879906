import { Avatar, Image, Modal, Table } from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { getCustomers } from "../../../RestServices/authService";
import { formateString } from "../../../shared";

const tableColumns = [
  {
    title: "Sr.",
    width: 20,
    dataIndex: "index",
    fixed: "left",
    align: "center",
    render: (text, record, index) => index + 1,
  },
  {
    title: "Image",
    width: 20,
    dataIndex: "profileImage",
    fixed: "left",
    align: "center",
    render: (text, record, index) => (
      <Avatar src={<Image style={{ width: "80px" }} src={text} />} />
    ),
  },
  {
    key: "name",
    title: "Name",
    dataIndex: "name",
  },

  {
    key: "phoneNumber",
    title: "Phone",
    dataIndex: "phoneNumber",
    render: (text, record, index) => (
      <a style={{ color: "blue" }} href={`tel:${text}`}>
        {text}
      </a>
    ),
  },
  {
    key: "city",
    title: "City",
    dataIndex: "city",
  },

  {
    key: "type",
    title: "Rule",
    dataIndex: "type",
  },
  {
    key: "paymentInTake",
    title: "Business Given",
    dataIndex: "paymentInTake",
  },

  {
    key: "status",
    title: "Status",
    // title: "Category",
    dataIndex: "status",
  },
  {
    key: "category",
    title: "category",
    dataIndex: "category",
  },
  {
    key: "createdAt",
    title: "date of Creation",
    dataIndex: "createdAt",
  },

  {
    key: "updatedAt",
    title: "Last Seen",
    dataIndex: "updatedAt",
  },
];

// eslint-disable-next-line import/no-anonymous-default-export
export default function UserDetails({
  isModalVisible,
  handleOk,
  handleCancel,
  data,
  selectedUserId,
  array,
}) {
  const [customers, setCustomers] = useState([]);
  const { token } = useSelector((state) => state.Authentication.user);
  useEffect(() => {
    (async () => {
      try {
        let userIds = [];
        if (array) {
          if (!selectedUserId.length) {
            setCustomers([]);
            return;
          }
          userIds = selectedUserId;
        } else {
          userIds = data.stat.map((item) => item.customerId);
        }

        if (!userIds.length) {
          return setCustomers([]);
        }
        const { customers } = await getCustomers(token, { userIds });

        const allCustomers = customers.docs.map((customer) => ({
          name: customer.name,
          type: customer.profileDetails.type,
          status: customer.status,
          key: customer._id,
          updatedAt: moment(customer.lastProfileFetched).format(formateString),
          createdAt: moment(customer.createdAt).format(formateString),

          phoneNumber: customer.phoneNumber,
          category: Array.from(
            new Set(
              data?.stat?.find(
                (value) => value.customerId == customer._id,
              ).subType,
            ),
          ).join("\n"),
          paymentInTake:
            data?.payment?.find((value) => value.customerId == customer._id)
              ?.total || 0,

          profileImage: customer?.profileImage,
          city: customer.city,
        }));
        setCustomers(allCustomers);
      } catch (err) {
        console.log(err);
      }
    })();
  }, [isModalVisible]);

  return (
    <div>
      <Modal
        width={1200}
        height={1000}
        title="User details"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Table
          dataSource={customers}
          pagination={false}
          columns={tableColumns}

          // onSelect={this.onS/elect}
          // rowKey={(record) => record._id}
        />
      </Modal>
    </div>
  );
}
