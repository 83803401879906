import {
  AutoComplete,
  Avatar,
  Button,
  Col,
  Form,
  Image,
  Input,
  Row,
  Select,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "../company.css";
import { AntDesignOutlined } from "@ant-design/icons";
import { uploadThumbnail } from "../../../../RestServices/ecommerenceService";

const { Option } = Select;
export default function CompanyDetailForm({
  createCompany,
  setCreateCompany,
  onFinish,
  setStep,
  categories,
}) {
  const { allcities } = useSelector((state) => state.Cities);

  const [cities, setcities] = useState([]);
  useEffect(() => {
    const filterCities = allcities.map((city) => city.en);
    setcities(filterCities);
  }, [allcities]);

  const { token } = useSelector((state) => state.Authentication.user);

  const [thumbnails, setThumbnails] = useState([]);

  const thumbnailProps = {
    onRemove: () => {
      // setArticleImage(null);
      setThumbnails(null);
    },
    beforeUpload: async (image) => {
      const url = await uploadThumbnail(image, token);
      setCreateCompany({
        ...createCompany,
        logo: url,
      });
      setThumbnails([image]);
      return false;
    },
    fileList: thumbnails,
    accept: "image/*",
  };

  const categoryOption = categories?.map(category => ({ label: category.value, value: category.value }));

  return (
    <div className="ant-company-form">
      <Row justify="start" align="top">
        {/* <Col span={4} style={{ textAlign: "right" }}></Col> */}

        <Col span={22} offset={1}>
          <Form layout={"vertical"} onFinish={onFinish}>
            <Row gutter={[10, 10]}>
              <Col span={24}>
                <Avatar
                  size={{
                    xs: 50,
                    sm: 60,
                    md: 70,
                    lg: 80,
                    xl: 90,
                    xxl: 100,
                  }}
                  icon={
                    !createCompany.logo ? (
                      <AntDesignOutlined />
                    ) : (
                      <Image src={createCompany.logo} />
                    )
                  }
                />
              </Col>
              <Col span={24}>
                <Upload {...thumbnailProps}>
                  <Button
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "#FCB539",
                      borderRadius: "6px",
                      border: "none",
                      marginTop: "20px",
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "17px",
                    }}
                    block
                    size="large"
                    icon={
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 31 33"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M29.2938 15.4507L15.9171 28.8273C14.2784 30.4661 12.0558 31.3867 9.73824 31.3867C7.42072 31.3867 5.19811 30.4661 3.55937 28.8273C1.92063 27.1886 1 24.966 1 22.6485C1 20.3309 1.92063 18.1083 3.55937 16.4696L16.936 3.09297C18.0285 2.00047 19.5102 1.38672 21.0553 1.38672C22.6003 1.38672 24.082 2.00047 25.1745 3.09297C26.267 4.18546 26.8808 5.6672 26.8808 7.21221C26.8808 8.75723 26.267 10.239 25.1745 11.3315L11.7833 24.7081C11.2371 25.2543 10.4962 25.5612 9.72369 25.5612C8.95118 25.5612 8.21031 25.2543 7.66406 24.7081C7.11782 24.1619 6.81094 23.421 6.81094 22.6485C6.81094 21.876 7.11782 21.1351 7.66406 20.5889L20.0218 8.24566"
                          stroke="#4A0600"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    }
                  >
                    <span style={{ paddingLeft: "5px" }}>Upload Image </span>
                  </Button>
                </Upload>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={"Company Name"}
                  required
                  requiredMark
                  initialValue={createCompany.name}
                >
                  <Input
                    value={createCompany.name}
                    onChange={(e) =>
                      setCreateCompany({
                        ...createCompany,
                        name: e.target.value,
                      })
                    }
                    name="name"
                    // style={{ width: "300px", float: "left" }}
                    placeholder="Store name"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={"Company Address"}
                  required
                  requiredMark
                  initialValue={createCompany.address}
                >
                  <Input
                    value={createCompany.address}
                    onChange={(e) =>
                      setCreateCompany({
                        ...createCompany,
                        address: e.target.value,
                      })
                    }
                    name="address"
                    placeholder="Street Address"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                {" "}
                <Form.Item
                  label={"City"}
                  requiredMark
                  required
                  initialValue={createCompany.city}
                >
                  <AutoComplete
                    style={{ background: "white", color: "black" }}
                    dataSource={cities}
                    value={createCompany.city}
                    onChange={(e) =>
                      setCreateCompany({
                        ...createCompany,
                        city: e,
                      })
                    }
                    placeholder="City"
                    filterOption={(inputValue, option) =>
                      option.props.children
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                  />
                </Form.Item>
              </Col>
            </Row>

            <Col span={24}>
              <Form.Item
                label={"Description"}
                required
                requiredMark
                initialValue={createCompany.description}
              >
                <Input.TextArea
                  onChange={(e) => {
                    setCreateCompany({
                      ...createCompany,
                      description: e.target.value,
                    });
                  }}
                  value={createCompany.description}
                  // style={{ width: "300px", float: "left" }}
                  placeholder="Description"
                />
              </Form.Item>
            </Col>

            {createCompany?.email ? (
              <Col span={24}>
                <Form.Item
                  label={"Email"}
                  required
                  requiredMark
                  initialValue={createCompany.email}
                >
                  <Input
                    onChange={(e) => {
                      setCreateCompany({
                        ...createCompany,
                        email: e.target.value,
                      });
                    }}
                    value={createCompany.email}
                    // style={{ width: "300px", float: "left" }}
                    placeholder="Password"
                  />
                </Form.Item>
              </Col>
            ) : (
              <></>
            )}

            {createCompany?.password ? (
              <Col span={24}>
                <Form.Item
                  label={"Password"}
                  required
                  requiredMark
                  initialValue={createCompany.password}
                >
                  <Input
                    onChange={(e) => {
                      setCreateCompany({
                        ...createCompany,
                        password: e.target.value,
                      });
                    }}
                    value={createCompany.password}
                    // style={{ width: "300px", float: "left" }}
                    placeholder="Password"
                  />
                </Form.Item>
              </Col>
            ) : (
              <></>
            )}

            <Col span={24}>
              <Form.Item
                label={"Category"}
                requiredMark
                required
                initialValue={createCompany.category}
              >
                <Select
                  style={{ background: "white", color: "black" }}
                  value={createCompany.category}
                  onChange={(e) =>
                    setCreateCompany({
                      ...createCompany,
                      category: e,
                    })
                  }
                  placeholder="Select a category"
                  options={categoryOption}
                />
              </Form.Item>
            </Col>

            <Form.Item>
              <Button
                disabled={
                  !createCompany.name ||
                    !createCompany.address ||
                    !createCompany.city ||
                    !createCompany.description ||
                    !createCompany.category
                    ? true
                    : false
                }
                onClick={() => setStep(2)}
                type="primary"
                htmlType="submit"
                style={{
                  background: "#FCB539",
                  borderRadius: "6px",
                  border: "none",
                  marginTop: "20px",
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: "700",
                  fontSize: "16px",
                  color: "#000",
                  height: "35px",
                  padding: "0 25px",
                  width: "100%",
                  // opacity: 0.5,
                }}
              >
                {" "}
                Next
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
}
