import { Col, Divider, Row, Select, Menu, Dropdown, Button } from "antd";
import React, { useEffect, useState } from "react";
import { getOrdersServiceApi } from "../../../RestServices/ecommerenceService";
import { baseUrlOfCommerce, orderEnum } from "../../../shared";
import { Sorter } from "../../../utils/sorter";
import Table from "../../../utils/Table";
import AddUser from "../../AppUsers/AddUser";
import CustomPagination from "../../Pagination";
import "./order.css";
import OrderProductDetail from "./OrderProductDetail";
import axios from "axios";
import { HiDotsVertical } from "react-icons/hi";

const { Option } = Select;

export default function OrderList({
  token,
  permissions,
  userId,
  startDate,
  stat,
  filterType,
  endDate,
  selectedOrderId,
  selectedNotifyId,
  getOrdersNotifications,
}) {

  const [orderList, setOrdersList] = useState([]);
  const [orders, setOrders] = useState({});
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const [customerDetails, setCustomerDetails] = useState(undefined);

  const [userListModal, setUserListModal] = useState(false);
  const [orderModal, setOrderModal] = useState(false);
  const [ordersDataModal, setOrderDataModal] = useState({});

  const [orderStatus, setOrderStatus] = useState(null);

  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };

  const getOrders = async () => {
    const data = await getOrdersServiceApi(token, {
      page: page,
      orderStatus,
    });

    setOrders(data.data);
    setOrdersList(data.data.docs);
  }

  const updateOrderList = async () => {
    const res = await axios.patch(`${baseUrlOfCommerce}/api/v1/orders/updateOrderNotificationStatus/${selectedNotifyId}`, {}, {
      headers: {
        Authorization: "bearer " + token,
        "api-key": "poultrybaba@1234Sumandri",
      },
    });
    console.log(res);
  }

  const getOrdersById = async () => {
    const uri = `/api/v1/orders/getOrderById`;
    const { data } = await axios.get(baseUrlOfCommerce + uri, {
      params: {
        orderId: selectedOrderId
      },
      headers: {
        Authorization: "bearer " + token,
        "api-key": "poultrybaba@1234Sumandri",
      },
    });
    console.log(data?.data);
    if (selectedOrderId) {
      setOrderDataModal(data?.data);
      setOrderModal(true);
      updateOrderList();
      getOrdersNotifications();
    }
  }

  useEffect(() => {
    getOrders();
  }, [token, page, orderStatus]);

  useEffect(() => {
    getOrdersById();
  }, [selectedOrderId]);

  useEffect(() => {
    setOrderStatus(filterType);
  }, [filterType]);

  const menuOrder = (rowRecordOrder) => (
    <Menu>
      <Menu.Item
        onClick={() => {
          setOrderDataModal(rowRecordOrder);
          setOrderModal(true);
        }}
      >
        View
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: "",
      render: (record) => (
        <Dropdown overlay={() => menuOrder(record)}>
          <Button style={{ border: "0", padding: "0" }}>
            <HiDotsVertical size={18} />
          </Button>
        </Dropdown>
      ),
    },
    {
      title: "Order Number",
      dataIndex: "orderNumber",
      sorter: {
        compare: Sorter.DEFAULT,
        multiple: 8,
      },
    },
    {
      title: "Date/Time Order",
      dataIndex: "createdAt",
      render: (createdAt) =>
        `${new Date(createdAt).toLocaleDateString()} ${new Date(
          createdAt,
        ).toLocaleTimeString()}`,
      sorter: {
        compare: Sorter.DEFAULT,
        multiple: 7,
      },
    },
    {
      title: "Payment Method",
      dataIndex: "paymentMethod",
      sorter: {
        compare: Sorter.DEFAULT,
        multiple: 6,
      },
    },
    {
      title: "Total Amount",
      dataIndex: "actualAmount",
      sorter: {
        compare: Sorter.DEFAULT,
        multiple: 5,
      },
    },
    {
      title: "Discount",
      dataIndex: "discountedAmount",
      sorter: {
        compare: Sorter.DEFAULT,
        multiple: 4,
      },
    },
    {
      title: "Payable",
      dataIndex: "amountToBePaid",
      sorter: {
        compare: Sorter.DEFAULT,
        multiple: 3,
      },
    },
    {
      title: "Booky Name",
      dataIndex: "shipmentInfo",
      render: (shipmentInfo) => {
        return (
          <span>
            {shipmentInfo.name}{" "}
            <a
              style={{ color: "black", textDecoration: "underline" }}
              href={`tel:${shipmentInfo.phoneNumber}`}
            >
              {" "}
              {shipmentInfo.phoneNumber}{" "}
            </a>
          </span>
        );
      },
      sorter: {
        compare: Sorter.DEFAULT,
        multiple: 2,
      },
    },
    {
      title: "Status",
      dataIndex: "orderStatus",
      sorter: {
        compare: Sorter.DEFAULT,
        multiple: 1,
      },
    },
  ];


  return (
    <div>
      <div>
        <OrderProductDetail
          open={orderModal}
          order={ordersDataModal}
          setOpen={setOrderModal}
          token={token}
        />
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
      >
        <AddUser
          selectedUser={customerDetails}
          submitForm={() => setUserListModal(false)}
          isFormLoading={false}
          isOpen={userListModal}
          closeModal={() => setUserListModal(false)}
          inputHandler={console.log}
        />
        <Row justify="end" gutter={[16, 16]}>
          <Col style={{ marginBottom: "20px" }}>
            <Select
              showSearch
              style={{ width: 200, marginRight: "20px" }}
              placeholder="Select Filter"
              optionFilterProp="children"
              onChange={setOrderStatus}
              value={orderStatus}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {orderEnum.map((value) => (
                <Option key={value} value={value}>
                  {value}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col xs={24} sm={24} lg={18} xl={18} style={{ marginBottom: "20px" }}>
            <Table
              columns={columns}
              pagination={false}
              dataSource={orderList}
              loading={loading}
              style={{ overflowX: "scroll" }}
            />

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                marginTop: "20px",
              }}
            >
              <CustomPagination
                current={orders.page}
                total={orders?.totalDocs}
                onPageChange={(p) => setPage(p)}
              />
            </div>
          </Col>

          <Col
            xs={24}
            sm={24}
            lg={6}
            xl={6}
            style={{
              padding: 0,
              background: "#fff",
              boxShadow: "0px 12px 23px rgba(62, 73, 84, 0.04)",
              marginBottom: "20px",
            }}
          >
            <div style={{ padding: "25px" }}>
              <div className="order__filter__date">
                <h1 className="order__heading">Overall Statistics</h1>
                {/* <DatePicker
                  onChange={onChange}
                  style={{ width: "80px" }}
                  placeholder="Date"
                /> */}
              </div>
              <Divider style={{ margin: "10px 0" }} />

              {stat?.order &&
                Object.keys(stat?.order).map((item, key) => (
                  <div key={key} className="order__content">
                    <div className="order__inner">
                      <div
                        className={`counting__wrapper counting__wrapper__${key + 1
                          }`}
                      >
                        <span>{key}</span>
                      </div>
                      <div className="order__text">
                        <span>{stat?.order[item]}</span>
                        <h5>{item}</h5>
                      </div>
                    </div>
                    <span
                      onClick={() => setOrderStatus(item)}
                      className="order__button"
                    >
                      View
                    </span>
                  </div>
                ))}
              {/* {statistics_data.map((order, index) => (
                
              ))} */}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

//  <Col key={item} md={12} sm={12} xs={12} lg={6}>
//    <div
//      className="item_mur"
//      onClick={() => {
//        // setSelectedModal({
//        //   stat: stat?.buyerStat,
//        //   payment: stat?.buyingPaymentDetail,
//        // });
//        // setIsModalVisible(true);
//      }}
//    >
//      <div className="item_inner_dev">
//        <span className="item_overall">Overall</span>
//        <span className="item_top_seller">{item}</span>
//      </div>
//      <div className="item_inner_dev">
//        <span className="item_top_seller">{stat?.order[item]}</span>
//        <button className="item_button">View More</button>
//      </div>
//    </div>
//  </Col>;
