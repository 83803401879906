import { AntDesignOutlined } from "@ant-design/icons";
import {
  AutoComplete,
  Avatar,
  Button,
  Col,
  Form,
  Image,
  Input,
  Select,
  Tabs,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getCompaniesServiceApi,
  uploadThumbnail,
} from "../../../RestServices/ecommerenceService";
import "./dealer.css";

const { Option } = Select;

export default function AddDealer({
  createDealer,
  setCreateDealer,
  onFinish,
  text,
  createDealerApi,
  loadingButton,
  setActiveTabDealer,
  activeTabDealer,
  setOpenNow,
}) {
  const [companies, setCompanies] = useState(undefined);

  const getCompanies = async () => {
    const data = await getCompaniesServiceApi(
      {
        page: 1,
        limit: 100,
      },
      token,
    );

    // console.log(data, "Asdf");

    setCompanies(data.data);
  };

  useEffect(() => {
    getCompanies();
  }, []);

  const { token } = useSelector((state) => state.Authentication.user);

  const { provinces, allcities } = useSelector((state) => state.Cities);

  // const [allDivision, setAllDivision] = useState([]);
  // const [allCities, setAllCities] = useState([]);

  // useEffect(() => {
  //   setAllDivision(cities.map((val) => val.division));
  //   // setAllCities(allcities.map((val) => val.value));
  // }, [allcities, cities, createDealer.province]);

  const [cities, setcities] = useState([]);
  useEffect(() => {
    const filterCities = allcities.map((city) => city.en);
    setcities(filterCities);
  }, [allcities]);

  const [thumbnails, setThumbnails] = useState([]);
  const thumbnailProps = {
    onRemove: () => {
      // setArticleImage(null);
      setThumbnails(null);
    },
    beforeUpload: async (image) => {
      const url = await uploadThumbnail(image, token);
      setCreateDealer({
        ...createDealer,
        logo: url,
      });
      setThumbnails([image]);
      return false;
    },
    fileList: thumbnails,
    accept: "image/*",
  };

  // const whenChangeDivision = (value) => {
  //   setCreateDealer({
  //     ...createDealer,
  //     division: value,
  //   });
  //   const divisionFind = cities && cities.find((val) => val.division === value);
  //   if (divisionFind) {
  //     setAllCities(
  //       divisionFind &&
  //         divisionFind.cities &&
  //         divisionFind.cities.map((item) => item.value),
  //     );
  //   }
  // };

  const handleTabChangeDealer = (activeKey) => {
    setActiveTabDealer(activeKey);
  };

  return (
    <div>
      <div className="dealer-tabs">
        <Form layout={"vertical"} onFinish={onFinish}>
          <Tabs activeKey={activeTabDealer} onChange={handleTabChangeDealer}>
            <Tabs.TabPane tab="Dealer Info" key="1">
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Col span={24}>
                    <Avatar
                      size={{
                        xs: 50,
                        sm: 60,
                        md: 70,
                        lg: 80,
                        xl: 90,
                        xxl: 100,
                      }}
                      icon={
                        !createDealer.logo ? (
                          <AntDesignOutlined />
                        ) : (
                          <Image src={createDealer.logo} />
                        )
                      }
                    />
                  </Col>
                  <Col span={24}>
                    <Upload {...thumbnailProps}>
                      <Button
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          background: "#FCB539",
                          borderRadius: "6px",
                          border: "none",
                          marginTop: "20px",
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: "700",
                          fontSize: "17px",
                        }}
                        block
                        size="large"
                        icon={
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 31 33"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M29.2938 15.4507L15.9171 28.8273C14.2784 30.4661 12.0558 31.3867 9.73824 31.3867C7.42072 31.3867 5.19811 30.4661 3.55937 28.8273C1.92063 27.1886 1 24.966 1 22.6485C1 20.3309 1.92063 18.1083 3.55937 16.4696L16.936 3.09297C18.0285 2.00047 19.5102 1.38672 21.0553 1.38672C22.6003 1.38672 24.082 2.00047 25.1745 3.09297C26.267 4.18546 26.8808 5.6672 26.8808 7.21221C26.8808 8.75723 26.267 10.239 25.1745 11.3315L11.7833 24.7081C11.2371 25.2543 10.4962 25.5612 9.72369 25.5612C8.95118 25.5612 8.21031 25.2543 7.66406 24.7081C7.11782 24.1619 6.81094 23.421 6.81094 22.6485C6.81094 21.876 7.11782 21.1351 7.66406 20.5889L20.0218 8.24566"
                              stroke="#4A0600"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        }
                      >
                        <span style={{ paddingLeft: "5px" }}>
                          Upload Image{" "}
                        </span>
                      </Button>
                    </Upload>
                  </Col>
                </div>
                <div style={{ marginTop: "20px" }}>
                  <Col span={24}>
                    <Form.Item
                      label={"Dealer Name"}
                      required
                      requiredMark
                      initialValue={createDealer.name}
                    >
                      <Input
                        value={createDealer.name}
                        onChange={(e) =>
                          setCreateDealer({
                            ...createDealer,
                            name: e.target.value,
                          })
                        }
                        name="name"
                        // style={{ width: "300px", float: "left" }}
                        placeholder="Name"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label={"Company"}
                      required
                      requiredMark
                      initialValue={createDealer?.companyId?.name}
                    >
                      <Select
                        placeholder="Select a store"
                        value={createDealer?.companyId?.name}
                        onChange={(e) => {
                          const companySelected = companies?.docs?.find(
                            (item) => item._id === e,
                          );
                          setCreateDealer({
                            ...createDealer,
                            companyId: companySelected._id,
                            companyTypeCategory: companySelected?.category,
                          });
                        }}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {companies?.docs?.map((company) => {
                          return (
                            <Option
                              title={company.name}
                              value={String(company._id)}
                            >
                              {company.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label={"Company Type Category"}
                      required
                      requiredMark
                      initialValue={createDealer.companyTypeCategory}
                    >
                      <Input
                        value={createDealer.companyTypeCategory}
                        onChange={(e) =>
                          setCreateDealer({
                            ...createDealer,
                            companyTypeCategory: e.target.value,
                          })
                        }
                        disabled
                        name="companyTypeCategory"
                        // style={{ width: "300px", float: "left" }}
                        placeholder="Company Type Category"
                      />
                    </Form.Item>
                  </Col>
                  {/* <Col span={24}>
                    <Form.Item label="Province" required>
                      <AutoComplete
                        // style={{ width: 200 }}
                        style={{ background: "white", color: "black" }}
                        dataSource={provinces}
                        value={createDealer.province}
                        onChange={(e) =>
                          setCreateDealer({
                            ...createDealer,
                            province: e,
                          })
                        }
                        placeholder="Province"
                        filterOption={(inputValue, option) =>
                          option.props.children
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1
                        }
                      />
                    </Form.Item>
                  </Col> */}
                  {/* <Col span={24}>
                    <Form.Item label="Division" required>
                      <AutoComplete
                        // style={{ width: 200 }}
                        style={{ background: "white", color: "black" }}
                        dataSource={allDivision}
                        value={createDealer.division}
                        onChange={(e) => whenChangeDivision(e)}
                        placeholder="Division"
                        filterOption={(inputValue, option) =>
                          option.props.children
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1
                        }
                      />
                    </Form.Item> 
                  </Col>*/}
                  <Col span={24}>
                    <Form.Item label="City" required>
                      <AutoComplete
                        style={{ background: "white", color: "black" }}
                        dataSource={cities}
                        value={createDealer.city}
                        onChange={(e) =>
                          setCreateDealer({
                            ...createDealer,
                            city: e,
                          })
                        }
                        placeholder="City"
                        filterOption={(inputValue, option) =>
                          option.props.children
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label={"Description"}
                      required
                      requiredMark
                      initialValue={createDealer.description}
                    >
                      <Input.TextArea
                        onChange={(e) => {
                          setCreateDealer({
                            ...createDealer,
                            description: e.target.value,
                          });
                        }}
                        value={createDealer.description}
                        // style={{ width: "300px", float: "left" }}
                        placeholder="Description"
                      />
                    </Form.Item>
                  </Col>
                  <div
                    style={{
                      marginTop: "20px",
                      borderTop: "1px solid #E4E4E4",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      disabled={
                        !createDealer.name ||
                        !createDealer.city ||
                        !createDealer.description
                          ? true
                          : false
                      }
                      type="primary"
                      loading={loadingButton}
                      htmlType="submit"
                      style={{
                        background: "#FCB539",
                        borderRadius: "6px",
                        border: "none",
                        marginTop: "20px",
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: "700",
                        fontSize: "17px",
                        color: "#000",
                        height: "45px",
                        padding: "0 25px",
                        marginLeft: "10px",
                      }}
                      onClick={() => {
                        handleTabChangeDealer("2");
                        setThumbnails([]);
                      }}
                    >
                      Continue
                    </Button>
                  </div>
                </div>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Personal Info" key="2" disabled="true">
              <Col span={24}>
                <Form.Item
                  label={"Mobile Number"}
                  // required
                  // requiredMark
                  initialValue={createDealer.phoneNumber}
                >
                  <Input
                    type="text"
                    value={createDealer.phoneNumber}
                    defaultValue={createDealer.phoneNumber}
                    onChange={(e) =>
                      setCreateDealer({
                        ...createDealer,
                        phoneNumber: e.target.value,
                      })
                    }
                    placeholder="Mobile Number"
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label={"Whatsapp Number"}
                  // required
                  // requiredMark
                  initialValue={createDealer.whatsappNumber}
                >
                  <Input
                    type="text"
                    value={createDealer.whatsappNumber}
                    defaultValue={createDealer.whatsappNumber}
                    onChange={(e) =>
                      setCreateDealer({
                        ...createDealer,
                        whatsappNumber: e.target.value,
                      })
                    }
                    placeholder="Whatsapp Number"
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label={"Id Card Number"}
                  // required
                  // requiredMark
                  initialValue={createDealer.idCardNumber}
                >
                  <Input
                    type="text"
                    value={createDealer.idCardNumber}
                    defaultValue={createDealer.idCardNumber}
                    onChange={(e) =>
                      setCreateDealer({
                        ...createDealer,
                        idCardNumber: e.target.value,
                      })
                    }
                    placeholder="Id Card Number"
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label={"Business Address"}
                  // required
                  // requiredMark
                  initialValue={createDealer.businessAddress}
                >
                  <Input.TextArea
                    type="text"
                    value={createDealer.businessAddress}
                    defaultValue={createDealer.businessAddress}
                    onChange={(e) =>
                      setCreateDealer({
                        ...createDealer,
                        businessAddress: e.target.value,
                      })
                    }
                    placeholder="Business Address"
                  />
                </Form.Item>
              </Col>

              <div
                style={{
                  marginTop: "20px",
                  borderTop: "1px solid #E4E4E4",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Button
                  type="primary"
                  style={{
                    background: "#E4E4E4",
                    borderRadius: "6px",
                    border: "none",
                    marginTop: "20px",
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: "700",
                    fontSize: "17px",
                    color: "#000",
                    height: "45px",
                    padding: "0 25px",
                  }}
                  onClick={() => handleTabChangeDealer("1")}
                >
                  Back
                </Button>
                <Button
                  // disabled={
                  //   !createDealer.accountHolderName ||
                  //   !createDealer.IBAN ||
                  //   !createDealer.accountNumber
                  //     ? true
                  //     : false
                  // }
                  onClick={() => {
                    createDealerApi();
                    setThumbnails([]);
                  }}
                  type="primary"
                  loading={loadingButton}
                  htmlType="submit"
                  style={{
                    background: "#FCB539",
                    borderRadius: "6px",
                    border: "none",
                    marginTop: "20px",
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: "700",
                    fontSize: "17px",
                    color: "#000",
                    height: "45px",
                    padding: "0 25px",
                    marginLeft: "20px",
                  }}
                >
                  {" "}
                  {createDealer.hasOwnProperty("_id")
                    ? "Update Dealer"
                    : "Create Dealer"}
                </Button>
              </div>
            </Tabs.TabPane>
          </Tabs>
        </Form>
      </div>
    </div>
  );
}
