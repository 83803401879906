import { Card, Col, Divider, Image, Modal, Row } from "antd";
import moment from "moment";
import React from "react";
import StarDisplay from "../../Utils/StarDisplay";

export default function ProductDetailModal({ product, open, setOpen }) {
  return (
    <Modal
      title="Product Details"
      centered
      visible={open}
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      width={"80%"}
    >
      <Row>
        <Col span={8} style={{ borderRight: "1px solid #e4e4e4" }}>
          <Row justify="center">
            <Col span={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Image
                  style={{
                    borderRadius: "12px",
                    width: "100px",
                    height: "100px",
                    border: "3px solid #ffa200",
                  }}
                  src={product?.pictures?.length ? product?.pictures[0] : ""}
                  alt={product?.name}
                />
              </div>
            </Col>
          </Row>
          <div className="modal-styles">
            <Row justify="center" style={{ textAlign: "center" }}>
              <Col span={24}>
                <Card title={product?.name} bordered={false}>
                  {product?.description}
                </Card>
              </Col>
            </Row>
          </div>
          <div className="modal-styles modal-info">
            <Row justify="center" style={{ textAlign: "center" }}>
              <Col span={24}>
                <Card title="Additional Info" bordered={false}>
                  <Row style={{ marginBottom: "8px" }}>
                    <Col span={24}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                        }}
                      >
                        <span className="ai-heading">Rating: </span>
                        <span>
                          <StarDisplay rating={product?.rating} />
                        </span>
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: "8px" }} gutter={10}>
                    <Col span={24}>
                      <div>
                        <span className="ai-heading">Availability: </span>
                        <span style={{ textTransform: "capitalize" }}>
                          {product?.availability ? "Yes" : "No"}
                        </span>
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: "8px" }} gutter={10}>
                    <Col span={24}>
                      <div>
                        <span className="ai-heading">PaymentMethods: </span>
                        <span style={{ textTransform: "capitalize" }}>
                          {product?.availablePaymentMethods ? (
                            <>
                              {product?.availablePaymentMethods.map((pm) => pm)}
                            </>
                          ) : (
                            <>N/A</>
                          )}
                        </span>
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: "8px" }} gutter={10}>
                    <Col span={24}>
                      <div>
                        <span className="ai-heading">Category: </span>
                        <span style={{ textTransform: "capitalize" }}>
                          {product?.category}
                        </span>
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: "8px" }} gutter={10}>
                    <Col span={24}>
                      <div>
                        <span className="ai-heading">Company Type: </span>
                        <span style={{ textTransform: "capitalize" }}>
                          {product?.companyTypeCategory}
                        </span>
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: "8px" }}>
                    <Col span={24}>
                      <div>
                        <span className="ai-heading">Sub Category: </span>
                        <span style={{ textTransform: "capitalize" }}>
                          {product?.subCategory}
                        </span>
                      </div>
                    </Col>
                  </Row>

                  <Row style={{ marginBottom: "8px" }} gutter={10}>
                    <Col span={12}>
                      <div>
                        <span className="ai-heading">Quantity: </span>
                        <span style={{ textTransform: "capitalize" }}>
                          {product?.quantity}
                        </span>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div>
                        <span className="ai-heading">Price: </span>
                        <span style={{ textTransform: "capitalize" }}>
                          {product?.price}
                        </span>
                      </div>
                    </Col>
                  </Row>

                  <Row style={{ marginBottom: "8px" }} gutter={10}>
                    <Col span={12}>
                      <div>
                        <span className="ai-heading">City: </span>
                        <span style={{ textTransform: "capitalize" }}>
                          {product?.city}
                        </span>
                      </div>
                    </Col>
                  </Row>

                  <Row style={{ marginBottom: "8px" }} gutter={10}>
                    <Col span={24}>
                      <div>
                        <span className="ai-heading">Expiry Date: </span>
                        <span style={{ textTransform: "capitalize" }}>
                          {moment(product?.expiryDate).format("D MMMM YYYY")}
                        </span>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <div>
                        <span className="ai-heading">Manufacture Date: </span>
                        <span style={{ textTransform: "capitalize" }}>
                          {moment(product?.manufactureDate).format(
                            "D MMMM YYYY",
                          )}
                        </span>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={24}>
                      <div>
                        <span className="ai-heading">Made: </span>
                        <span style={{ textTransform: "capitalize" }}>
                          {product?.made}
                        </span>
                      </div>
                    </Col>
                    <Col span={24}>
                      <div>
                        <span className="ai-heading">Composition: </span>
                        <span style={{ textTransform: "capitalize" }}>
                          {product?.mixtureContain}
                        </span>
                      </div>
                    </Col>
                    <Col span={24}>
                      <div>
                        {/* specialRemarks: "", extraDescription: "", */}
                        {/* mixtureContain: "", forms: "", */}
                        <span className="ai-heading">Form: </span>
                        <span style={{ textTransform: "capitalize" }}>
                          {product?.forms}
                        </span>
                      </div>
                    </Col>

                    <Col span={24}>
                      <div>
                        {/* specialRemarks: "", extraDescription: "", */}
                        {/* mixtureContain: "", forms: "", */}
                        <span className="ai-heading">
                          Size
                          {product.forms
                            ? `${
                                product.forms === "Liquid"
                                  ? " in ML"
                                  : "in grams"
                              }`
                            : ""}{" "}
                          :
                        </span>
                        <span style={{ textTransform: "capitalize" }}>
                          {product?.size ?? "N/A"}
                        </span>
                      </div>
                    </Col>
                    <Col span={24}>
                      <div>
                        <span className="ai-heading">Special Remarks: </span>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: product?.specialRemarks,
                          }}
                        ></span>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </div>
        </Col>
        <Col
          span={8}
          style={{ borderRight: "1px solid #e4e4e4", paddingLeft: "15px" }}
        >
          <div className="modal-styles modal-info">
            <Row justify="center" style={{ textAlign: "center" }}>
              <Col span={24}>
                <Card title="Dealer Details" bordered={false}>
                  {product?.dealer ? (
                    product?.dealer.map((deal) => {
                      return (
                        <>
                          <Row style={{ marginBottom: "8px" }} gutter={10}>
                            <Col span={24}>
                              <div>
                                <span className="ai-heading">Name: </span>
                                <span style={{ textTransform: "capitalize" }}>
                                  {deal?.name}
                                </span>
                              </div>
                            </Col>
                            <Col span={24}>
                              <div>
                                <span className="ai-heading">
                                  Phone Number:{" "}
                                </span>
                                <a
                                  href={`tel:${deal?.phoneNumber}`}
                                  style={{
                                    textTransform: "capitalize",
                                    color: "blue",
                                    textDecoration: "underline",
                                  }}
                                >
                                  {deal?.phoneNumber}
                                </a>
                              </div>
                            </Col>
                            <Col span={24}>
                              <div>
                                <span className="ai-heading">Whatsapp: </span>
                                <a
                                  href={`tel:${deal?.whatsappNumber}`}
                                  style={{
                                    textTransform: "capitalize",
                                    color: "blue",
                                    textDecoration: "underline",
                                  }}
                                >
                                  {deal?.whatsappNumber}
                                </a>
                              </div>
                            </Col>
                            <Col span={24}>
                              <div>
                                <span className="ai-heading">City: </span>
                                <span style={{ textTransform: "capitalize" }}>
                                  {deal?.city}
                                </span>
                              </div>
                            </Col>
                            <Col span={24}>
                              <div>
                                <span className="ai-heading">CNIC: </span>
                                <span style={{ textTransform: "capitalize" }}>
                                  {deal?.idCardNumber}
                                </span>
                              </div>
                            </Col>
                          </Row>
                          <Divider />
                        </>
                      );
                    })
                  ) : (
                    <>No Record Found</>
                  )}
                </Card>
              </Col>
            </Row>
          </div>
        </Col>
        <Col span={8} style={{ paddingLeft: "15px" }}>
          <div className="modal-styles modal-info">
            <Row justify="center" style={{ textAlign: "center" }}>
              <Col span={24}>
                <Card title="Company Details" bordered={false}>
                  {product?.companyId ? (
                    <Row style={{ marginBottom: "8px" }} gutter={10}>
                      <Col span={24}>
                        <div>
                          <span className="ai-heading">Name: </span>
                          <span style={{ textTransform: "capitalize" }}>
                            {product?.companyId?.name}
                          </span>
                        </div>
                      </Col>

                      <Col span={24}>
                        <div>
                          <span className="ai-heading">Category: </span>
                          <span style={{ textTransform: "capitalize" }}>
                            {product?.companyId?.category}
                          </span>
                        </div>
                      </Col>
                      <Col span={24}>
                        <div>
                          <span className="ai-heading">City: </span>
                          <span style={{ textTransform: "capitalize" }}>
                            {product?.companyId?.city}
                          </span>
                        </div>
                      </Col>
                      <Col span={24}>
                        <div>
                          <span className="ai-heading">Address: </span>
                          <span style={{ textTransform: "capitalize" }}>
                            {product?.companyId?.address}
                          </span>
                        </div>
                      </Col>
                      <Divider />
                    </Row>
                  ) : (
                    <>No Record Found</>
                  )}
                </Card>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Modal>
  );
}
