import { message as AntAlert, message } from "antd";
import axios from "axios";
import { baseUrlOfCommerce } from "../shared";
import { uploadThumnail } from "./videosService";

export const getOrderCountWRTStatus = async (token, { crmUserId }) => {
  let uri = `/api/v1/orders/getOrderCountWRTStatus`;
  const { data } = await axios.get(baseUrlOfCommerce + uri, {
    params: {
      crmUserId,
    },
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  const response = ErrorCatcher(data);
  if (response) {
    return response;
  } else {
    return new Error("Api error");
  }
};

export const getStatRequest = async (token, payload) => {
  let uri = `/api/v1/common/stat`;
  const { data } = await axios.get(baseUrlOfCommerce + uri, {
    params: {
      ...payload,
    },
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  // console.log(data);
  // const response = ErrorCatcher(data);
  if (data) {
    return data;
  } else {
    return new Error("Api error");
  }
};

export const getInterestedCompany = async (token, payload) => {
  let uri = `/api/v1/companies/interestInCompany`;
  const { data } = await axios.get(baseUrlOfCommerce + uri, {
    params: {
      ...payload,
    },
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  // const response = ErrorCatcher(data);
  if (data) {
    return data;
  } else {
    return new Error("Api error");
  }
};

export const ErrorCatcher = (response) => {
  const { success, message } = response;
  if (success) {
    return response;
  }
  AntAlert.error(Object.values(message).join(". "));
  return false;
};

export const createCompanyServiceApi = async (
  { email, password, ...payload },
  token,
) => {
  const uri = "/api/v1/companies/createCompany";

  const { data } = await axios.post(baseUrlOfCommerce + uri, payload, {
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  const response = data;
  if (response) {
    return response;
  } else {
    throw new Error("Api error");
  }
};

export const editCompanyServiceApi = async (
  { _id, updatedAt, createdAt, id, ...payload },
  token,
) => {
  const uri = `/api/v1/companies/editCompany/${_id}`;

  const { data } = await axios.patch(baseUrlOfCommerce + uri, payload, {
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  const response = ErrorCatcher(data);
  if (response) {
    return response;
  } else {
    throw new Error("Api error");
  }
};

export const getCompaniesServiceApi = async (
  { page, limit, endDate, startDate, category },
  token,
) => {
  let uri = `/api/v1/companies/getCompanies`;
  const { data } = await axios.get(baseUrlOfCommerce + uri, {
    params: {
      page,
      category,
      limit,
    },

    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  const response = ErrorCatcher(data);
  if (response) {
    return response;
  } else {
    throw new Error("Api error");
  }
};

export const createDealerServiceApi = async (payload, token) => {
  const uri = "/api/v1/dealers/createDealer";

  const { data } = await axios.post(baseUrlOfCommerce + uri, payload, {
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  const response = ErrorCatcher(data);
  // debugger;
  if (response) {
    return response;
  } else {
    throw new Error("Api error");
  }
};

export const editDealerServiceApi = async (
  { _id, id, createdAt, updatedAt, ...payload },
  token,
) => {
  const uri = `/api/v1/dealers/editDealer/${_id}`;

  const { data } = await axios.patch(baseUrlOfCommerce + uri, payload, {
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  const response = ErrorCatcher(data);
  if (response) {
    return response;
  } else {
    throw new Error("Api error");
  }
};

export const getDealersServiceApi = async (token, option) => {
  let uri = `/api/v1/dealers/getDealers`;
  const { data } = await axios.get(baseUrlOfCommerce + uri, {
    params: {
      ...option,
    },

    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  const response = ErrorCatcher(data);
  if (response) {
    return response;
  } else {
    throw new Error("Api error");
  }
};

export const createInqueryOrdersServiceApi = async (payload, token) => {
  const uri = `${baseUrlOfCommerce}/api/v1/enquiries/createEnquiries`;

  const { data } = await axios.post(uri, payload, {
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  const response = ErrorCatcher(data);
  // debugger;
  if (response) {
    return response;
  } else {
    throw new Error("Api error");
  }
};

export const createOfferOnEnquiry = async (payload, token) => {
  const uri = `${baseUrlOfCommerce}/api/v1/offerOnEnquiry/createOffer`;

  const { data } = await axios.post(uri, payload, {
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  const response = ErrorCatcher(data);
  // debugger;
  if (response) {
    return response;
  } else {
    throw new Error("Api error");
  }
};

export const findMyOfferOnEnquire = async (payload, token) => {
  const uri = `${baseUrlOfCommerce}/api/v1/offerOnEnquiry/findOffers`;

  const { data } = await axios.post(uri, payload, {
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  const response = ErrorCatcher(data);
  if (response) {
    return response;
  } else {
    throw new Error("Api error");
  }
};

export const editInqueryOrdersServiceApi = async (
  { _id, id, createdAt, updatedAt, ...payload },
  token,
) => {
  const uri = `${baseUrlOfCommerce}/api/v1/enquiries/editEnquiries${_id}`;

  const { data } = await axios.patch(uri, payload, {
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  const response = ErrorCatcher(data);
  if (response) {
    return response;
  } else {
    throw new Error("Api error");
  }
};

export const getInqueryOrdersServiceApi = async (token, { page }) => {
  let uri = `${baseUrlOfCommerce}/api/v1/enquiries/getAllEnquires`;
  const { data } = await axios.get(uri, {
    params: {
      page,
    },
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  const response = ErrorCatcher(data);
  if (response) {
    return response;
  } else {
    return new Error("Api error");
  }
};

export const getOrdersServiceApi = async (
  token,
  { page, orderStatus, crmUserId },
) => {
  let uri = `/api/v1/orders/getAllOrders`;
  const { data } = await axios.get(baseUrlOfCommerce + uri, {
    params: {
      page,
      orderStatus,
    },
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  const response = ErrorCatcher(data);
  if (response) {
    return response;
  } else {
    return new Error("Api error");
  }
};

export const updateOrdersServiceApi = async (
  { orderId, orderStatus, paymentImage },
  token,
) => {
  let uri = `/api/v1/orders/updateOrderStatus`;
  const { data } = await axios.post(
    baseUrlOfCommerce + uri,
    {
      orderId,
      orderStatus,
      paymentImage,
    },
    {
      headers: {
        Authorization: "bearer " + token,
        "api-key": "poultrybaba@1234Sumandri",
      },
    },
  );
  const response = ErrorCatcher(data);
  if (response) {
    return response;
  } else {
    return new Error("Api error");
  }
};

export const createProductServiceApi = async (payload, token) => {
  const uri = "/api/v1/products/createProduct";

  const { data } = await axios.post(baseUrlOfCommerce + uri, payload, {
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  const response = ErrorCatcher(data);
  if (response) {
    return response;
  } else {
    throw new Error("Api error");
  }
};

export const updateProductServiceApi = async (
  { _id, id, createdAt, updatedAt, ...payload },
  token,
) => {
  let uri = `/api/v1/products/updateProduct/${_id}`;
  const { data } = await axios.patch(baseUrlOfCommerce + uri, payload, {
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  const response = ErrorCatcher(data);
  if (response) {
    return response;
  } else {
    return new Error("Api error");
  }
};

export const getProductsServiceApi = async ({ page, companyId, category }) => {
  let uri = `/api/v1/products/getProducts`;
  const { data } = await axios.get(baseUrlOfCommerce + uri, {
    params: {
      page,
      companyTypeCategory: category,
      companyId,
    },
    headers: {
      // Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  const response = ErrorCatcher(data);
  if (response) {
    return response;
  } else {
    return new Error("Api error");
  }
};

export const getProductsServiceApiWithCompany = async (
  token,
  { page, company, category },
) => {
  let uri = `/api/v1/products/getProducts?companyId=${company}`;
  const { data } = await axios.get(baseUrlOfCommerce + uri, {
    params: {
      page,
      category,
    },
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  const response = ErrorCatcher(data);
  if (response) {
    return response;
  } else {
    throw new Error("Api error");
  }
};

export const createCategory = async (payload, token) => {
  const uri = "/api/v1/categories/createCategory";

  const { data } = await axios.post(baseUrlOfCommerce + uri, payload, {
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  const response = ErrorCatcher(data);
  if (response) {
    return response;
  } else {
    throw new Error("Api error");
  }
};
export const getCategories = async (token, option) => {
  let uri = `/api/v1/categories/getCategories`;
  const { data } = await axios.get(baseUrlOfCommerce + uri, {
    params: {
      ...option,
    },

    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  const response = ErrorCatcher(data);
  if (response) {
    return response;
  } else {
    return new Error("Api error");
  }
};

export const deleteCompanyRequest = async (token, option) => {
  let uri = `/api/v1/companies/deleteCompanyRequest`;
  const { data } = await axios.post(
    baseUrlOfCommerce + uri,
    {},
    {
      params: {
        ...option,
      },

      headers: {
        Authorization: "bearer " + token,
        "api-key": "poultrybaba@1234Sumandri",
      },
    },
  );
  const response = ErrorCatcher(data);
  if (response) {
    return response;
  } else {
    return new Error("Api error");
  }
};

export const uploadThumbnail = async (thumbnail, token) => {
  // this.setState({ isFormLoading: true });
  // const { thumbnail } = this.state;
  const formData = new FormData();
  formData.append("thumbnail", thumbnail);
  try {
    const response = await uploadThumnail(formData, token);
    return response.thumnailUrl;
  } catch (err) {
    if (err.response) {
      return message.error(err.response.data.message);
    }
    return message.error(err.message);
  }
};
