import { Popover } from "antd";
import React, { useState } from "react";

import { Avatar } from "@material-ui/core";
import { Table } from "antd";
import moment from "moment";
import { phoneStatuses, ReasonOfBlocked } from "../../../shared";
import AddUser from "../../AppUsers/AddUser";

export default function AddNumber({
  phoneList,
  setReviewPicture,
  onUpdateContactStatus,
  openReviewScreen,
  searchByPhoneNumber,
}) {
  const content = (record) => (
    <div className="content">
      {[phoneStatuses.APP_DOWNLOADED, phoneStatuses.REVIEWED].includes(
        record.status,
      ) ? (
        <p
          onClick={() => {
            setUser(record.userProfile);
            setUserListModal(true);
          }}
        >
          View Details
        </p>
      ) : (
        <></>
      )}
      {/* <p>Order History</p> */}

      {record.status === phoneStatuses.APP_DOWNLOADED ? (
        <>
          <p
            onClick={() => {
              setReviewPicture(true);
              openReviewScreen(record);
            }}
          >
            Review
          </p>
        </>
      ) : (
        <></>
      )}
      {[
        phoneStatuses.APP_DOWNLOADED,
        phoneStatuses.REVIEWED,
        phoneStatuses.FORMER,
        phoneStatuses.TRADER,
      ].includes(record.status) ? (
        <>
          <p
            onClick={() => {
              onUpdateContactStatus({
                _id: record._id,
                taskId: record.taskId,
                status: record.status,

                userType: phoneStatuses.FORMER,
              });
            }}
          >
            Former
          </p>

          <p
            onClick={() => {
              onUpdateContactStatus({
                _id: record._id,
                status: record.status,

                taskId: record.taskId,
                userType: phoneStatuses.TRADER,
              });
            }}
          >
            Trader
          </p>
        </>
      ) : (
        <></>
      )}
      {[
        phoneStatuses.APP_DOWNLOADED,
        phoneStatuses.REVIEWED,
        phoneStatuses.FORMER,
        phoneStatuses.TRADER,
      ].includes(record.status) ? (
        <></>
      ) : (
        <div>
          <p
            onClick={() => {
              onUpdateContactStatus({
                _id: record._id,
                taskId: record.taskId,
                status: phoneStatuses.blocked,
                blocked: ReasonOfBlocked[0],
              });
            }}
          >
            Ios User
          </p>
          <p
            onClick={() => {
              onUpdateContactStatus({
                _id: record._id,
                taskId: record.taskId,
                status: phoneStatuses.blocked,
                blocked: ReasonOfBlocked[2],
              });
            }}
          >
            N/A
          </p>
          <p
            onClick={() => {
              onUpdateContactStatus({
                _id: record._id,
                taskId: record.taskId,
                status: phoneStatuses.blocked,
                blocked: ReasonOfBlocked[1],
              });
            }}
          >
            Key Pad User
          </p>
        </div>
      )}
    </div>
  );

  const columns = [
    {
      title: "Sr",
      dataIndex: "sr",
      key: "Sr",
      render: (_text, _record, index) => <p>{index + 1}</p>,
    },
    {
      title: "name",
      dataIndex: "name",
      key: "name",
      render: (_text, record) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <Avatar
            src={
              "https://poultrybaba-space.sgp1.digitaloceanspaces.com/PoulltryBaba-Admin/thumbnail/1664137755529-3135768.png"
            }
            size={20}
          />
          <p>{record?.userProfile?.name || "N/A"}</p>{" "}
        </div>
      ),
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (_text, record) => (
        <p>{record.userProfile?.profileDetails.address || "N/A"}</p>
      ),
    },

    {
      title: "Phone",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (text) => <p>{text || "N/A"}</p>,
    },

    {
      title: "Task Month",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => (
        <p>{(text && moment(new Date(text)).format("MMM-YYYY")) || "N/A"}</p>
      ),
    },

    {
      title: "block reason",
      dataIndex: "blockedReason",
      key: "block reason",
      render: (text, record) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <p style={{ marginTop: "10px" }}>{text || "N/A"}</p>
        </div>
      ),
    },
    {
      title: "User Type",
      dataIndex: "userType",
      key: "userType",
      render: (text) => <p>{text || "N/A"}</p>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <p style={{ marginTop: "10px" }}>{text || "N/A"}</p>
          <Popover content={content(record)} title="Action" trigger="hover">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11Z"
                stroke="#3E4954"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18Z"
                stroke="#3E4954"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4Z"
                stroke="#3E4954"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </Popover>
        </div>
      ),
    },
  ];

  const [userListModal, setUserListModal] = useState(false);
  const [user, setUser] = useState(false);

  return (
    <div
      className="div_back"
      style={{
        width: "100%",
        overflow: "auto",
      }}
    >
      <AddUser
        selectedUser={user}
        submitForm={() => setUserListModal(false)}
        isFormLoading={false}
        isOpen={userListModal}
        closeModal={() => setUserListModal(false)}
        inputHandler={console.log}
      />
      <Table
        columns={columns}
        dataSource={
          searchByPhoneNumber
            ? phoneList?.filter((value) =>
                value.phoneNumber.includes(searchByPhoneNumber),
              )
            : phoneList
        }
      />
    </div>
  );
}

{
  /* <Row
        style={{
          borderBottom: "1px black solid",
          borderRadius: "5px",
        }}
      >
        <Col style={{ padding: "10px" }} span={12}></Col>
        <Col
          style={{
            backgroundColor: MAIN_BACKGROUND_COLOR,
            color: "black",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          span={6}
        >
          Total: {phoneList?.length}
        </Col>
        <Col
          style={{
            backgroundColor: MAIN_BACKGROUND_COLOR,
            color: "black",
            display: "flex",
            alignItems: "center",
          }}
          span={6}
        >
          <Button
            onClick={() => {
              // eslint-disable-next-line array-callback-return
              phoneList?.map((value, index) => {
                onUpdateContactStatus({
                  _id: value._id,
                  taskId: value.taskId,
                  status: phoneStatuses.CALLED_NUMBER,
                });
              });
            }}
          >
            Check All
          </Button>
        </Col>
      </Row>
      <Row>
        <Col style={{ padding: "10px" }} span={24}>
          {/* <Button
            type={"primary"}
            style={{ borderRadius: "50px", marginRight: "10px" }}
          >
            FaceBook {"  "} 50
          </Button>
          <Button type={"primary"} style={{ borderRadius: "50px" }}>
            WhatsApp {"  "} 100
          </Button> */
}
//   </Col>
// </Row>
// <Row
//   gutter={[12, 12]}
//   justify="center"
//   align="top"
//   style={{ height: "100vh", color: "black", padding: "20px" }}
// >
//   <Col span={24}>
//     {phoneList?.map((value, index) => {
//       return (
//         <Row
//           key={value.phoneNumber}
//           gutter={[12, 12]}
//           justify="center"
//           align="middle"
//           style={{ color: "black", marginTop: "10px" }}
//         >
//           <Col span={2}>{index + 1}</Col>
//           <Col span={9}>{value.phoneNumber}</Col>
//           <Col span={7}>
//             {value.createdAt && new Date(value.createdAt).toDateString()}
//           </Col>

//           <Col span={6}>
//             <Button
//               onClick={() =>
//                 onUpdateContactStatus({
//                   _id: value._id,
//                   taskId: value.taskId,
//                   status: phoneStatuses.CALLED_NUMBER,
//                 })
//               }
//               type={"primary"}
//               style={{ borderRadius: "50px", width: "100%" }}
//             >
//               Ok
//             </Button>
//           </Col>
//         </Row>
//       );
//     })}
//   </Col>
// </Row> */}
