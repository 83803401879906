import {
  Button,
  Col,
  Divider,
  Dropdown,
  Input,
  Menu,
  Popconfirm,
  Row,
  Select,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  getCategories,
  getCompaniesServiceApi,
} from "../../../RestServices/ecommerenceService";
import Table from "../../../utils/Table";
import AddUser from "../../AppUsers/AddUser";
import CustomPagination from "../../Pagination";
import { SideDrawer } from "../../Utils/SideDrawer";

import axios from "axios";
import { HiDotsVertical } from "react-icons/hi";
import { baseUrlOfCommerce } from "../../../shared";
import { Sorter } from "../../../utils/sorter";
import CompanyDetailsModal from "./CompanyDetailsModal";
import FormComponent from "./addCompany/addFormCompany";
import "./company.css";

const { Option } = Select;

export default function Company({
  token,
  permissions,
  userId,
  stat,
  startDate,
  endDate,
}) {
  const [password, setPassword] = useState("");
  const confirm = async (rowRecordCompany) => {
    try {
      const uri = `${baseUrlOfCommerce}/api/v1/companies/getCompanies/${rowRecordCompany._id}`;

      await axios.patch(
        uri,
        {},
        {
          headers: {
            Authorization: "bearer " + token,
            "api-key": "poultrybaba@1234Sumandri",
          },
        },
      );
      getCompanies();
      message.success("Company Deleted Successfully!");
    } catch (error) {
      console.log(error);
    }
  };
  const cancel = (e) => {};
  const menuCompany = (rowRecordCompany) => (
    <Menu>
      <Menu.Item
        onClick={() => {
          setDataCompanyModal(rowRecordCompany);
          setCompanyModal(true);
        }}
      >
        View
      </Menu.Item>
      <Menu.Item>
        <Popconfirm
          title={
            <>
              Please give authorization pass key
              <div>
                <Input
                  defaultValue={""}
                  autoComplete={false}
                  type="password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  value={password}
                ></Input>
              </div>
            </>
          }
          onConfirm={() => {
            if (password !== "1345") {
              message.info("Password is incorrect");
              setPassword("");

              return;
            }
            setPassword("");
            setOpenNow(true);
            setEditScreenData({ ...rowRecordCompany });
          }}
          onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >
          Edit
        </Popconfirm>
        {/* Edit */}
      </Menu.Item>
      <Menu.Item>
        <Popconfirm
          title={
            <>
              Are you sure to delete this company?
              <div>
                <Input
                  defaultValue={""}
                  autoComplete={false}
                  type="password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  value={password}
                ></Input>
              </div>
            </>
          }
          onConfirm={() => {
            if (password !== "1345") {
              message.info("Password is incorrect");
              setPassword("");

              return;
            }
            setPassword("");
            confirm(rowRecordCompany);
          }}
          onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >
          Delete
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: "",
      render: (record) => (
        <Dropdown overlay={() => menuCompany(record)}>
          <Button style={{ border: "0", padding: "0" }}>
            <HiDotsVertical size={18} />
          </Button>
        </Dropdown>
      ),
    },
    {
      title: "Company Title",
      dataIndex: "name",
      render: (name) => `${name ? name : "N/A"}`,
      sorter: {
        compare: Sorter.DEFAULT,
        multiple: 7,
      },
    },
    {
      title: "Representative",
      dataIndex: "companyRepresentative",
      render: (companyRepresentative) =>
        `${
          companyRepresentative.length ? companyRepresentative[0].name : "N/A"
        }`,
      sorter: {
        compare: Sorter.DEFAULT,
        multiple: 6,
      },
    },
    {
      title: "Representative Contact",
      dataIndex: "companyRepresentative",
      render: (companyRepresentative) =>
        `${
          companyRepresentative.length
            ? companyRepresentative[0].phoneNumbers
            : "N/A"
        }`,
      sorter: {
        compare: Sorter.DEFAULT,
        multiple: 5,
      },
    },
    {
      title: "City",
      dataIndex: "city",
      sorter: {
        compare: Sorter.DEFAULT,
        multiple: 4,
      },
    },
    {
      title: "Category",
      dataIndex: "category",
      sorter: {
        compare: Sorter.DEFAULT,
        multiple: 3,
      },
    },
    {
      title: "Address",
      dataIndex: "address",
      sorter: {
        compare: Sorter.DEFAULT,
        multiple: 2,
      },
    },
    {
      title: "Date/ Time",
      dataIndex: "createdAt",
      render: (createdAt) => `${new Date(createdAt).toLocaleDateString()}`,
      sorter: {
        compare: Sorter.DEFAULT,
        multiple: 1,
      },
    },
  ];

  const [companies, setRegisterCompany] = useState({});
  const [dataCompany, setDataCompany] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  const [customerDetails, setCustomerDetails] = useState(undefined);

  const [userListModal, setUserListModal] = useState(false);
  const [category, setCategory] = useState(null);
  const [companyModal, setCompanyModal] = useState(false);
  const [step, setStep] = useState(1);
  const [openNow, setOpenNow] = useState(false);

  const getCompanies = async () => {
    const data = await getCompaniesServiceApi(
      {
        page: page,
        endDate,
        startDate,
        category,
      },
      token,
    );
    getCategoriesRequest();
    setRegisterCompany(data.data);
    setDataCompany(data.data.docs);
  };
  useEffect(() => {
    getCompanies();
  }, [token, page, category, openNow]);

  const [categories, setCategories] = useState(null);

  const getCategoriesRequest = async () => {
    const data = await getCategories(token, {});

    setCategories(data.data);
  };

  const [editScreenData, setEditScreenData] = useState(undefined);
  const [dataCompanyModal, setDataCompanyModal] = useState([]);

  return (
    <div>
      <div>
        <CompanyDetailsModal
          open={companyModal}
          company={dataCompanyModal}
          setOpen={setCompanyModal}
        />
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
      >
        <AddUser
          selectedUser={customerDetails}
          submitForm={() => setUserListModal(false)}
          isFormLoading={false}
          isOpen={userListModal}
          closeModal={() => setUserListModal(false)}
          inputHandler={console.log}
        />
        <SideDrawer
          openNow={openNow}
          setOpenNow={setOpenNow}
          clearState={(data) => {
            setEditScreenData(data);
            setStep(1);
          }}
          textButton={"Make Company"}
        >
          <FormComponent
            step={step}
            setStep={setStep}
            setOpenNow={setOpenNow}
            categories={categories}
            editScreenData={editScreenData}
            token={token}
            createAdminOrSubAdmin={console.log}
          />
        </SideDrawer>
        <Row justify="end" gutter={[16, 16]}>
          <Col style={{ margin: 10 }}>
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Category"
              optionFilterProp="children"
              onChange={setCategory}
              value={category}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option key={"23423"} value={null}>
                {"All Company"}
              </Option>
              {categories?.map((cate) => (
                <Option key={cate.value} value={cate.value}>
                  {cate.value}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col xs={24} sm={24} lg={18} xl={18} style={{ marginBottom: "20px" }}>
            <Table
              columns={columns}
              pagination={false}
              dataSource={dataCompany}
              loading={loading}
              style={{ overflowX: "scroll" }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                marginTop: "20px",
              }}
            >
              <CustomPagination
                pageSize={companies?.limit}
                current={companies.page}
                total={companies?.totalDocs}
                onPageChange={(p) => setPage(p)}
              />
            </div>
          </Col>
          <Col
            xs={24}
            sm={24}
            lg={6}
            xl={6}
            style={{
              padding: 0,
              background: "#fff",
              boxShadow: "0px 12px 23px rgba(62, 73, 84, 0.04)",
              marginBottom: "20px",
            }}
          >
            <div style={{ padding: "25px" }}>
              <div className="order__filter__date">
                <h1 className="order__heading">Overall Statistics</h1>
                {/* <DatePicker
                  onChange={onChange}
                  style={{ width: "80px" }}
                  placeholder="Date"
                /> */}
              </div>
              <Divider style={{ margin: "10px 0" }} />
              {stat?.companyStat &&
                Object.keys(stat?.companyStat).map((item, key) => (
                  <div key={key} className="order__content__company">
                    <Row style={{ marginBottom: "20px" }}>
                      <Col span={6}>
                        <div
                          className={`counting__wrapper counting__wrapper__${
                            key + 1
                          }`}
                        >
                          <span>{key + 1}</span>
                        </div>
                      </Col>
                      <Col span={14}>
                        <div className="order__text">
                          <span>{`${stat?.companyStat[item]}`}</span>
                          <h5>{item.replace("null", "other")}</h5>
                        </div>
                      </Col>
                      <Col span={4}>
                        <div className="order__button">
                          <div onClick={() => setCategory(item)}>View</div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                ))}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
