import { Modal, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCommissionRequest } from "../../../RestServices/postingService";
import { checkPermission } from "../../../shared";

export default function StatDetails({
  statDetailModal,
  setStatDetailModal,
  selectedDate,
}) {
  const [stat, setStat] = useState([]);
  const {
    token,
    _id: userId,
    profileDetails: { permissions },
  } = useSelector((state) => state.Authentication.user);

  useEffect(() => {
    if (Boolean(statDetailModal.length)) {
      getCommissions();
    }
  }, [statDetailModal]);

  const getCommissions = async () => {
    try {
      const response = await getCommissionRequest(
        {
          referenceOf: checkPermission(permissions, "AllResources")
            ? undefined
            : userId,
          category: statDetailModal,
          month: selectedDate,
        },
        token,
      );
      setStat(response.commission);
    } catch (err) {}
  };

  const columns = [
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Downloaded user",
      dataIndex: "createdBy",
      key: "createdBy",
    },
    {
      title: "Reference of",
      dataIndex: "referenceOf",
      key: "referenceOf",
    },
    {
      title: "Create At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => <>{new Date(createdAt).toDateString() || "N/A"}</>,
    },
    {
      title: "Amount Gain",
      dataIndex: "amountGain",
      key: "amountGain",
    },
    {
      title: "Post",
      dataIndex: "postId",
      render: (userId) => <>{userId || "N/A"}</>,
    },
  ];

  console.log(stat);

  return (
    <Modal
      visible={Boolean(statDetailModal.length)}
      title="Details"
      onCancel={() => setStatDetailModal([])}
      footer={null}
      style={{ overflow: "scroll" }}
      width={"1000px"}
    >
      <Table dataSource={stat} columns={columns} />;
    </Modal>
  );
}
