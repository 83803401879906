import { AntDesignOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  Radio,
  Select,
  Space,
  Tabs,
  Upload,
  message,
  Switch,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getCategories,
  getCompaniesServiceApi,
  getDealersServiceApi,
  uploadThumbnail,
} from "../../../../RestServices/ecommerenceService";
import ReactQuill from "react-quill";

const { Option } = Select;
export default function ProductDetailForm({
  createProduct,
  setCreateProduct,
  onFinish,
  createProductApi,
  loadingButton,
  activeTab,
  setActiveTab,
  setStep,
  setOpenNow,
}) {
  const commissionAmount =
    (createProduct.price * createProduct.poultryBabaCommission) / 100;
  const discountAmount = (createProduct.price * createProduct.discount) / 100;
  const extraDiscountAmount =
    (createProduct.price * createProduct.discountWithCard) / 100;
  const totalPrice = createProduct.price - discountAmount - extraDiscountAmount;

  const { token } = useSelector((state) => state.Authentication.user);

  const [thumbnails, setThumbnails] = useState([]);
  const [defaultThumbnails, setDefaultThumbnail] = useState([]);

  const handleUploadThumbnails = (file) => {
    let fileList = [...file.fileList];
    fileList = fileList.slice(0, 4); // Limit to maximum 4 files
    fileList = fileList.filter((file) => {
      if (file.type === "image/jpeg" || file.type === "image/png") {
        return true;
      }
      return false;
    });
    setThumbnails(fileList);
  };

  const handleUploadThumbnailsDefault = (file) => {
    let fileList = [...file.fileList];
    fileList = fileList.slice(0, 1); // Limit to maximum 4 files
    fileList = fileList.filter((file) => {
      if (file.type === "image/jpeg" || file.type === "image/png") {
        return true;
      }
      return false;
    });
    setDefaultThumbnail(fileList);
  };

  function disabledOldDate(current) {
    // Can not select days before today and today
    return current && current < moment().endOf("day");
  }

  function disabledFutureDate(current) {
    // Can not select days before today and today
    return current && current > moment().endOf("day");
  }

  const handleBeforeUploadThumbnails = async (image) => {
    if (image.type !== "image/jpeg" && image.type !== "image/png") {
      message.error("Only upload jpeg/png images.");
      return false;
    }
    const Uploadthumbnails = await uploadThumbnail(image, token);
    setCreateProduct({
      ...createProduct,
      pictures: [...createProduct.pictures, Uploadthumbnails],
    });
    setThumbnails([Uploadthumbnails, ...image]);
    return true;
  };

  const handleBeforeUploadThumbnailsDefault = async (image) => {
    if (image.type !== "image/jpeg" && image.type !== "image/png") {
      message.error("Only upload jpeg/png images.");
      return false;
    }
    const Uploadthumbnails = await uploadThumbnail(image, token);
    setCreateProduct({
      ...createProduct,
      defaultImage: [...createProduct.defaultImage, Uploadthumbnails],
    });
    setDefaultThumbnail([Uploadthumbnails, ...image]);
    return true;
  };

  useEffect(() => {
    getDealers();
    getCategoriesRequest();
  }, []);

  const [dealers, setDealers] = useState(null);

  const getDealers = async () => {
    const data = await getDealersServiceApi(token, {
      page: 1,
      limit: 100,
    });

    setDealers(data.data);
  };

  const handleTabChange = (activeKey) => {
    setActiveTab(activeKey);
  };

  const [categories, setCategories] = useState(null);

  const getCategoriesRequest = async (params) => {
    const data = await getCategories(token, params);

    setCategories(data.data);
  };

  const [subCategories, setSubCategory] = useState(null);

  const getSubCategoriesRequest = async (params) => {
    const data = await getCategories(token, params);

    setSubCategory(data.data);
  };

  useEffect(() => {
    if (!createProduct.category) {
      return;
    }
    getSubCategoriesRequest({
      parentValue: createProduct.category,
    });
  }, [createProduct.category]);

  const [registerCompany, setRegisterCompany] = useState(null);

  useEffect(() => {
    (async () => {
      const data = await getCompaniesServiceApi(
        {
          page: 1,
          limit: 100,
        },
        token,
      );
      setRegisterCompany(data.data);
    })();
  }, []);
  // console.log(createProduct, dealers, "createProduct");
  // console.log(registerCompany, "registerCompany");
  // console.log(createProduct, "createProduct");

  // console.log(categories, subCategories);
  // "city" is required. "division" is required. "province" is required. "district" is required. "dealer" is required
  const { email } = useSelector((state) => state.Authentication.user);
  return (
    <div className="product-tabs">
      <Form layout={"vertical"} onFinish={onFinish}>
        <Tabs activeKey={activeTab} onChange={handleTabChange}>
          <Tabs.TabPane tab="Product Info" key="1">
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Col span={24}>
                  <Avatar
                    size={{
                      xs: 50,
                      sm: 60,
                      md: 70,
                      lg: 80,
                      xl: 90,
                      xxl: 100,
                    }}
                    icon={
                      !createProduct.pictures ? (
                        <AntDesignOutlined />
                      ) : (
                        <Image src={createProduct.pictures} />
                      )
                    }
                  />
                </Col>
                <Col span={24}>
                  <Upload
                    fileList={thumbnails}
                    beforeUpload={handleBeforeUploadThumbnails}
                    onChange={handleUploadThumbnails}
                    maxCount={4}
                    onRemove={() => {
                      // setArticleImage(null);
                      setThumbnails(null);
                    }}
                    accept="image/*"
                    multiple
                  >
                    <Button
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "#FCB539",
                        borderRadius: "6px",
                        border: "none",
                        marginTop: "20px",
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: "700",
                        fontSize: "17px",
                        width: "220px",
                      }}
                      block
                      size="large"
                      icon={
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 31 33"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M29.2938 15.4507L15.9171 28.8273C14.2784 30.4661 12.0558 31.3867 9.73824 31.3867C7.42072 31.3867 5.19811 30.4661 3.55937 28.8273C1.92063 27.1886 1 24.966 1 22.6485C1 20.3309 1.92063 18.1083 3.55937 16.4696L16.936 3.09297C18.0285 2.00047 19.5102 1.38672 21.0553 1.38672C22.6003 1.38672 24.082 2.00047 25.1745 3.09297C26.267 4.18546 26.8808 5.6672 26.8808 7.21221C26.8808 8.75723 26.267 10.239 25.1745 11.3315L11.7833 24.7081C11.2371 25.2543 10.4962 25.5612 9.72369 25.5612C8.95118 25.5612 8.21031 25.2543 7.66406 24.7081C7.11782 24.1619 6.81094 23.421 6.81094 22.6485C6.81094 21.876 7.11782 21.1351 7.66406 20.5889L20.0218 8.24566"
                            stroke="#4A0600"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      }
                    >
                      <span style={{ paddingLeft: "5px" }}>Upload Image</span>
                    </Button>
                  </Upload>
                  <span
                    style={{
                      fontSize: "13px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "8px",
                    }}
                  >
                    (Max: 4, Min: 1)
                  </span>
                </Col>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Col span={24}>
                  <Avatar
                    size={{
                      xs: 50,
                      sm: 60,
                      md: 70,
                      lg: 80,
                      xl: 90,
                      xxl: 100,
                    }}
                    icon={
                      !createProduct.defaultImage ? (
                        <AntDesignOutlined />
                      ) : (
                        <Image src={createProduct.defaultImage} />
                      )
                    }
                  />
                </Col>
                <Col span={24}>
                  <Upload
                    fileList={defaultThumbnails}
                    beforeUpload={handleBeforeUploadThumbnailsDefault}
                    onChange={handleUploadThumbnailsDefault}
                    maxCount={1}
                    onRemove={() => {
                      // setArticleImage(null);
                      setDefaultThumbnail(null);
                    }}
                    accept="image/*"
                    multiple
                  >
                    <Button
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "#FCB539",
                        borderRadius: "6px",
                        border: "none",
                        marginTop: "20px",
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: "700",
                        fontSize: "17px",
                        width: "220px",
                      }}
                      block
                      size="large"
                      icon={
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 31 33"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M29.2938 15.4507L15.9171 28.8273C14.2784 30.4661 12.0558 31.3867 9.73824 31.3867C7.42072 31.3867 5.19811 30.4661 3.55937 28.8273C1.92063 27.1886 1 24.966 1 22.6485C1 20.3309 1.92063 18.1083 3.55937 16.4696L16.936 3.09297C18.0285 2.00047 19.5102 1.38672 21.0553 1.38672C22.6003 1.38672 24.082 2.00047 25.1745 3.09297C26.267 4.18546 26.8808 5.6672 26.8808 7.21221C26.8808 8.75723 26.267 10.239 25.1745 11.3315L11.7833 24.7081C11.2371 25.2543 10.4962 25.5612 9.72369 25.5612C8.95118 25.5612 8.21031 25.2543 7.66406 24.7081C7.11782 24.1619 6.81094 23.421 6.81094 22.6485C6.81094 21.876 7.11782 21.1351 7.66406 20.5889L20.0218 8.24566"
                            stroke="#4A0600"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      }
                    >
                      <span style={{ paddingLeft: "5px" }}>Default Image</span>
                    </Button>
                  </Upload>
                  <span
                    style={{
                      fontSize: "13px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "8px",
                    }}
                  >
                    Default image
                  </span>
                </Col>
              </div>
              <div style={{ marginTop: "20px" }}>
                <Col span={24}>
                  <Form.Item
                    label={"Product Name"}
                    required
                    requiredMark
                    initialValue={createProduct.name}
                  >
                    <Input
                      value={createProduct.name}
                      onChange={(e) =>
                        setCreateProduct({
                          ...createProduct,
                          name: e.target.value,
                        })
                      }
                      name="name"
                      placeholder="Product Name"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    required
                    requiredMark
                    label={"Company"}
                    initialValue={createProduct.companyId}
                  >
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      value={createProduct?.companyId}
                      placeholder="Select a store"
                      onChange={(e) => {
                        const companySelected = registerCompany?.docs?.find(
                          (item) => item._id === e,
                        );
                        setCreateProduct({
                          ...createProduct,
                          companyId: e,

                          companyTypeCategory: companySelected?.category,
                        });
                      }}
                    >
                      {registerCompany?.docs?.map(({ _id, name }) => (
                        <Option key={_id} value={_id}>
                          {name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label={"Category"}
                    required
                    requiredMark
                    initialValue={createProduct.category}
                  >
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      value={createProduct.category}
                      defaultValue={createProduct.category}
                      placeholder="Select a Category"
                      optionFilterProp="children"
                      onChange={(e) =>
                        setCreateProduct({
                          ...createProduct,
                          category: e,
                        })
                      }
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {categories?.map((category) => {
                        return (
                          <Option value={category.value} key={category._id}>
                            {category.value}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label={"Company Type Category"}
                    required
                    requiredMark
                    initialValue={createProduct.companyTypeCategory}
                  >
                    <Select
                      showSearch
                      disabled
                      style={{ width: "100%" }}
                      value={createProduct.companyTypeCategory}
                      defaultValue={createProduct.companyTypeCategory}
                      placeholder="Select Company Type Category"
                      optionFilterProp="children"
                      onChange={(e) =>
                        setCreateProduct({
                          ...createProduct,
                          companyTypeCategory: e,
                        })
                      }
                    ></Select>
                  </Form.Item>
                </Col>
                {/* {createProduct?._id && ( */}
                <Col span={24}>
                  <Form.Item
                    label={"Select One"}
                    required
                    requiredMark
                    initialValue={createProduct.productCategory}
                  >
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      value={createProduct.productCategory}
                      defaultValue={createProduct.productCategory}
                      placeholder="Select Product Category"
                      optionFilterProp="children"
                      onChange={(e) =>
                        setCreateProduct({
                          ...createProduct,
                          productCategory: e,
                        })
                      }
                      options={[
                        {
                          value: "Mega Deals",
                          label: "Mega Deals",
                        },

                        {
                          value: "New Arrival",
                          label: "New Arrival",
                        },
                        {
                          value: "Clearance Sale",
                          label: "Clearance Sale",
                        },
                      ]}
                    ></Select>
                  </Form.Item>
                </Col>
                {/* )} */}

                <Col span={24}>
                  <Form.Item
                    label={"Sub Category"}
                    required
                    requiredMark
                    initialValue={createProduct.subCategory}
                  >
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Select a sub Category"
                      optionFilterProp="children"
                      value={createProduct.subCategory}
                      defaultValue={createProduct.subCategory}
                      onChange={(e) =>
                        setCreateProduct({
                          ...createProduct,
                          subCategory: e,
                        })
                      }
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {subCategories?.map((category) => {
                        return (
                          <Option value={category.value}>
                            {category.value}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label={"MRP"}
                    required
                    requiredMark
                    initialValue={createProduct.price}
                  >
                    <Input
                      type="number"
                      value={createProduct.price}
                      defaultValue={createProduct.price}
                      onChange={(e) =>
                        setCreateProduct({
                          ...createProduct,
                          price: parseInt(e.target.value),
                        })
                      }
                      placeholder="Price"
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label={`PB Commission (%)  |   ${commissionAmount} Rs. `}
                    required
                    requiredMark
                    initialValue={createProduct.poultryBabaCommission}
                  >
                    <Input
                      value={createProduct.poultryBabaCommission}
                      defaultValue={createProduct.poultryBabaCommission}
                      type="number"
                      onChange={(e) =>
                        setCreateProduct({
                          ...createProduct,
                          poultryBabaCommission: parseInt(e.target.value),
                        })
                      }
                      placeholder="Poultry Baba Commission (%)"
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label={`Customer Discount (%)  |   ${discountAmount} Rs. `}
                    required
                    requiredMark
                    initialValue={createProduct.discount}
                  >
                    <Input
                      type="number"
                      value={createProduct.discount}
                      defaultValue={createProduct.discount}
                      onChange={(e) =>
                        setCreateProduct({
                          ...createProduct,
                          discount: parseInt(e.target.value),
                        })
                      }
                      placeholder="Discount (%)"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label={`Special Discount with Cards (%)  |   ${extraDiscountAmount} Rs. `}
                    required
                    requiredMark
                    initialValue={createProduct.discountWithCard}
                  >
                    <Input
                      type="number"
                      value={createProduct.discountWithCard}
                      defaultValue={createProduct.discountWithCard}
                      onChange={(e) =>
                        setCreateProduct({
                          ...createProduct,
                          discountWithCard: parseInt(e.target.value),
                        })
                      }
                      placeholder="Extra Discount with Cards (%)"
                    />
                  </Form.Item>
                </Col>

                <Col className="consumer_price_section" span={24}>
                  <Form.Item
                    label={"Price to show"}
                    required
                    requiredMark
                    initialValue={createProduct.consumerValue}
                  >
                    <Input
                      type="number"
                      value={
                        createProduct.consumerValue
                          ? createProduct.consumerValue
                          : totalPrice
                      }
                      disabled
                      onChange={() => {
                        setCreateProduct({
                          ...createProduct,
                          consumerValue: totalPrice,
                        });
                      }}
                      placeholder="Consumer Value"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label={"Quantity"}
                    required
                    requiredMark
                    initialValue={createProduct.quantity}
                  >
                    <Input
                      value={createProduct.quantity}
                      defaultValue={createProduct.quantity}
                      onChange={(e) =>
                        setCreateProduct({
                          ...createProduct,
                          quantity: e.target.value,
                        })
                      }
                      type="number"
                      placeholder="quantity"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label={"Payment Option"}
                    required
                    requiredMark
                    initialValue={createProduct.availablePaymentMethods}
                  >
                    <Checkbox.Group
                      options={["ONLINE", "CASH_ON_DELIVERY"].map((option) => ({
                        label: option.split("_").join(" "),
                        value: option,
                      }))}
                      value={createProduct.availablePaymentMethods}
                      onChange={(e) => {
                        setCreateProduct({
                          ...createProduct,
                          availablePaymentMethods: e,
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label={"Expiry Date"}
                    required
                    requiredMark
                    initialValue={createProduct?.expiryDate ?? new Date()}
                  >
                    <DatePicker
                      disabledDate={disabledOldDate}
                      value={moment(
                        createProduct?.expiryDate
                          ? new Date(createProduct?.expiryDate)
                          : new Date(),
                        "DD/MM/YYYY",
                      )}
                      defaultValue={moment(
                        createProduct?.expiryDate
                          ? new Date(createProduct?.expiryDate)
                          : new Date(),
                        "DD/MM/YYYY",
                      )}
                      onChange={(date, dateString) => {
                        setCreateProduct({
                          ...createProduct,
                          expiryDate: dateString,
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label={"Manufacture Date"}
                    required
                    requiredMark
                    initialValue={createProduct.manufactureDate}
                  >
                    <DatePicker
                      disabledDate={disabledFutureDate}
                      value={moment(
                        createProduct?.manufactureDate
                          ? new Date(createProduct?.manufactureDate)
                          : new Date(),
                        "DD/MM/YYYY",
                      )}
                      defaultValue={moment(
                        createProduct?.manufactureDate
                          ? new Date(createProduct?.manufactureDate)
                          : new Date(),
                        "DD/MM/YYYY",
                      )}
                      onChange={(date, dateString) => {
                        setCreateProduct({
                          ...createProduct,
                          manufactureDate: dateString,
                        });
                      }}
                    />
                  </Form.Item>
                  {/* specialRemarks: "", ExtraDescription: "", mixtureContain: "",
                  forms: "", */}
                  <Col span={24}>
                    <Form.Item
                      label={"Made "}
                      required
                      requiredMark
                      initialValue={createProduct.made}
                    >
                      <Input
                        defaultValue={createProduct.made}
                        value={createProduct.made}
                        onChange={(e) => {
                          setCreateProduct({
                            ...createProduct,
                            made: e.target.value,
                          });
                        }}
                        // style={{ width: "300px", float: "left" }}
                        placeholder="Made"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label={"Forms "}
                      required
                      requiredMark
                      initialValue={createProduct.forms}
                    >
                      <Radio.Group
                        onChange={(e) => {
                          console.log(e);
                          setCreateProduct({
                            ...createProduct,
                            forms: e.target.value,
                          });
                        }}
                        value={createProduct?.forms}
                      >
                        <Space direction="horizontal">
                          <Radio value={"Liquid"}>Liquid</Radio>
                          <Radio value={"Powder"}>Powder</Radio>
                        </Space>
                      </Radio.Group>

                      {/* <Input
                        defaultValue={createProduct.forms}
                        value={createProduct.forms}
                        onChange={(e) => {
                          setCreateProduct({
                            ...createProduct,
                            forms: e.target.value,
                          });
                        }}
                        // style={{ width: "300px", float: "left" }}
                        placeholder="Min Three Forms"
                      /> */}
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label={`Size in ${
                        createProduct.forms === "Powder" ? "grams" : "ml"
                      }`}
                      required
                      requiredMark
                      initialValue={createProduct?.size}
                    >
                      <Input
                        defaultValue={createProduct?.size}
                        value={createProduct?.size}
                        onChange={(e) => {
                          setCreateProduct({
                            ...createProduct,
                            size: e.target.value,
                          });
                        }}
                        // style={{ width: "300px", float: "left" }}
                        placeholder={`Size in ${
                          createProduct.forms === "Powder" ? "grams" : "ml"
                        }`}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label={"Product composition"}
                      required
                      requiredMark
                      initialValue={createProduct.mixtureContain}
                    >
                      <Input
                        defaultValue={createProduct.mixtureContain}
                        value={createProduct.mixtureContain}
                        onChange={(e) => {
                          setCreateProduct({
                            ...createProduct,
                            mixtureContain: e.target.value,
                          });
                        }}
                        // style={{ width: "300px", float: "left" }}
                        placeholder="Feed Grain"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      label={"Product Description"}
                      required
                      requiredMark
                      initialValue={createProduct.extraDescription}
                    >
                      <Input.TextArea
                        rows={3}
                        defaultValue={createProduct.extraDescription}
                        value={createProduct.extraDescription}
                        onChange={(e) => {
                          setCreateProduct({
                            ...createProduct,
                            extraDescription: e.target.value,
                          });
                        }}
                        // style={{ width: "300px", float: "left" }}
                        placeholder="Extra Description"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label={"Product benefits"}
                      required
                      requiredMark
                      initialValue={createProduct.specialRemarks}
                    >
                      <ReactQuill
                        value={createProduct.specialRemarks}
                        onChange={(specialRemarks) =>
                          setCreateProduct({
                            ...createProduct,
                            specialRemarks,
                          })
                        }
                      />
                    </Form.Item>

                    {/* <Form.Item
                      label={"Product benefits"}
                      required
                      requiredMark
                      initialValue={createProduct.specialRemarks}
                    >
                      <Input.TextArea
                        rows={4}
                        defaultValue={createProduct.specialRemarks}
                        value={createProduct.specialRemarks}
                        onChange={(e) => {
                          setCreateProduct({
                            ...createProduct,
                            specialRemarks: e.target.value,
                          });
                        }}
                        // style={{ width: "300px", float: "left" }}
                        placeholder="Product benefits"
                      />
                    </Form.Item> */}
                  </Col>
                </Col>
                <div
                  style={{
                    marginTop: "20px",
                    borderTop: "1px solid #E4E4E4",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  {/* <Button
                    type="primary"
                    style={{
                      background: "#E4E4E4",
                      borderRadius: "6px",
                      border: "none",
                      marginTop: "20px",
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "17px",
                      color: "#000",
                      height: "45px",
                      padding: "0 25px"
                    }}
                  >
                    Cancel
                  </Button> */}
                  <Button
                    disabled={
                      !createProduct.name ||
                      !createProduct.price ||
                      !createProduct.category ||
                      !createProduct.companyId
                        ? true
                        : false
                    }
                    type="primary"
                    loading={loadingButton}
                    htmlType="submit"
                    style={{
                      background: "#FCB539",
                      borderRadius: "6px",
                      border: "none",
                      marginTop: "20px",
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "17px",
                      color: "#000",
                      height: "45px",
                      padding: "0 25px",
                      marginLeft: "10px",
                    }}
                    onClick={() => handleTabChange("2")}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Dealer Info" key="2" disabled="true">
            <Col span={24}>
              <Form.Item
                label={"Select Dealers"}
                required
                requiredMark
                initialValue={createProduct.dealerName}
              >
                <Select
                  showSearch
                  size="large"
                  style={{ width: "100%" }}
                  mode="multiple"
                  value={createProduct?.dealer}
                  placeholder="Select a Dealer"
                  optionFilterProp="children"
                  onChange={(e) => {
                    console.log(e);
                    if (!e) {
                      return;
                    }

                    let cityList = [];
                    for (let i of e) {
                      cityList.push(
                        dealers.docs.find((dealer) => dealer._id === i).city,
                      );
                    }

                    setCreateProduct({
                      ...createProduct,
                      dealer: [...e],
                      city: cityList,
                    });
                  }}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  options={(() => {
                    let options = [];
                    if (dealers?.docs?.length)
                      for (let dealer of dealers?.docs) {
                        if (
                          dealer.companyId?._id === createProduct?.companyId
                        ) {
                          options.push({
                            label: `${dealer.name} ${dealer.city}`,
                            value: dealer._id,
                          });
                        }
                      }

                    return options;
                  })()}
                />
              </Form.Item>
            </Col>
            {["Naseem@pb.com", "ceo@poultrybaba.com"].includes(email) ? (
              <Col span={24}>
                <Form.Item
                  label={"Is price Settled"}
                  required
                  requiredMark
                  initialValue={createProduct.priceSettle}
                >
                  <Switch
                    value={createProduct.priceSettle}
                    onChange={(priceSettle) => {
                      setCreateProduct({
                        ...createProduct,
                        priceSettle,
                      });
                    }}
                  />
                </Form.Item>
              </Col>
            ) : (
              <></>
            )}
            {/* <Col span={24}>
              <Form.Item
                label={"City"}
                required
                requiredMark
                initialValue={createProduct.city}
              >
                <Input value={createProduct.city} disabled placeholder="City" />
              </Form.Item>
            </Col> */}

            <div
              style={{
                marginTop: "20px",
                borderTop: "1px solid #E4E4E4",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Button
                type="primary"
                style={{
                  background: "#E4E4E4",
                  borderRadius: "6px",
                  border: "none",
                  marginTop: "20px",
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: "700",
                  fontSize: "17px",
                  color: "#000",
                  height: "45px",
                  padding: "0 25px",
                }}
                onClick={() => handleTabChange("1")}
              >
                Back
              </Button>
              <Button
                disabled={createProduct.dealerName ? true : false}
                onClick={() => {
                  createProductApi();
                  setOpenNow(false);
                }}
                type="primary"
                loading={loadingButton}
                htmlType="submit"
                style={{
                  background: "#FCB539",
                  borderRadius: "6px",
                  border: "none",
                  marginTop: "20px",
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: "700",
                  fontSize: "17px",
                  color: "#000",
                  height: "45px",
                  padding: "0 25px",
                  marginLeft: "20px",
                }}
              >
                {createProduct?._id ? "Product Update" : "Create Product"}
              </Button>
            </div>
          </Tabs.TabPane>
        </Tabs>
      </Form>
    </div>
  );
}
