import {
  Layout,
  Menu,
  message,
  notification as AntNotification,
} from "antd";
import { getToken } from "firebase/messaging";
import React from "react";
import { connect } from "react-redux";
import { messaging } from "../../firebase";
import { getAllCities } from "../../Redux/ActionCreaters/citiesRequets";
import { customerDeviceToken } from "../../RestServices/authService";
import {
  RemoveColonFromPermission,
  TopPermission,
  UserPermisstion,
} from "../../shared";
import AudioSound from "./short.mp3";
import { fontColor, MAIN_BACKGROUND_COLOR } from "../../styleConstants";
import ECommerce from "../commence/ECommerce";
import CrmComponent from "../Crm/CrmComponent";
import MurghiMandi from "../MurghiMandiCom/MurghiMandi";

import HeaderContent from "./HeaderContent";
import "./styles.css";
import { PermissionsGateUser, PermissionsGateAdmin } from "../PermissionsGate";
import { Component } from "react";

const styles = {
  layout: {
    minHeight: "100vh",
    overflow: "hidden",
  },
  header: {
    backgroundColor: MAIN_BACKGROUND_COLOR,
    padding: 0,
  },
  sider: {
    backgroundColor: MAIN_BACKGROUND_COLOR,
  },
  menu: {
    backgroundColor: MAIN_BACKGROUND_COLOR,
    color: fontColor,
    fontWeight: "bold",
    overflowX: "hidden",
    overflowY: "auto",
  },
  content: {
    color: fontColor,
    margin: "10px",
    overflow: "auto",
  },
  logo: {
    height: "200px",
    width: "190px",
  },
  menuToggler: {
    color: fontColor,
    fontSize: "30px",
    marginLeft: "5px",
    marginTop: "15px",
  },
};
class Dashboard extends Component {
  state = {
    collapsed: false,
    input: null,
    selectedKey: "Crm_System",
    slectedSubkey: "",
    noficicationCount: 0,
    data: [],
  };

  toggleSider = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };
  inputEvent = (e) => {
    this.setState({ input: e.target.value });
    console.log(e.target.value);
  };
  handleSideNavToggle = () => {
    if (window.innerWidth < 700) {
      this.setState({ collapsed: true });
    } else if (window.innerWidth > 700) {
      this.setState({ collapsed: false });
    }
  };

  requestPermission = async () => {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      try {
        const webFcmToken = await getToken(messaging, {
          vapidKey:
            "BGSt71aFZldTEpdOFAKHweSjEdMQ--tZrDW4j7dFHdAu_ROiL7wJ26uGg04VMk8mJhZ7CS3DPh5Q-zrqyUwUa0Y",
        });
        console.log(webFcmToken, "asdf");

        await customerDeviceToken(this.props.Auth.user.token, {
          webFcmToken,
        });
        this.getMessages();
      } catch (err) {
        console.log(err);
      }
    } else if (permission === "denied") {
      // alert("User denied for notification");
    }
  };

  getMessages = () => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("message", (event) => {
        document.getElementById("short_play").setAttribute("muted", false);
        document.getElementById("short_play").play();
        console.log("asdfs");
        const { notification } = event.data;
        AntNotification.open({
          message: notification?.title,
          description: notification?.body,
          rtl: true,
        });
        // toast(
        //   <div className={styles.noti_text}>
        //     <h5>{notification?.title}</h5>
        //     <h6>{notification?.body}</h6>
        //   </div>,
        //   {
        //     autoClose: false,
        //     icon: ({ theme, type }) => (
        //       <Image
        //         src={NotifyIcon}
        //         width={50}
        //         height={50}
        //         alt="notify icon"
        //         className={styles.noti_image}
        //       />
        //     ),
        //   },
        // );
      });
    }
  };

  // Authentication.user.profileDetails.permissions
  componentDidMount() {
    this.requestPermission();
    this.handleSideNavToggle();
    window.addEventListener("resize", this.handleSideNavToggle);
    if (
      this.props.Auth.user.profileDetails &&
      this.props.Auth.user.profileDetails.permissions &&
      this.props.Auth.user.profileDetails.permissions[0] !==
      UserPermisstion[0].value
    ) {
      let permissions = this.props.Auth.user.profileDetails.permissions;
      let firstPermission = permissions[0];
      if (
        permissions.includes(TopPermission[0].value) ||
        permissions.includes(TopPermission[1].value)
      ) {
        firstPermission = TopPermission[1].value;
        this.setState({
          selectedKey: firstPermission.split(":")[0],
          allPermission: this.props.Auth.user.profileDetails.permissions,
        });
      }
    } else if (
      this.props.Auth.user.profileDetails &&
      this.props.Auth.user.profileDetails.permissions &&
      this.props.Auth.user.profileDetails.permissions[0] ===
      UserPermisstion[0].value
    ) {
      this.setState({
        allPermission: this.props.Auth.user.profileDetails.permissions,
      });
    }
    if (!this.props.cites) {
      this.props.getCities(this.props.Auth.user.token);
    }
    this.profRouteRenderer();
  }
  profRouteRenderer = () => {
    const { selectedKey } = this.state;

    if (selectedKey === "Crm_System")
      return (
        <CrmComponent
          setSelected={(selectedKey, slectedSubkey, data) => {
            this.setState({ selectedKey, slectedSubkey, data });
          }}
        />
      );

    if (selectedKey === "Murghi Mandi Details")
      return (
        <MurghiMandi
          setSelected={(selectedKey, slectedSubkey, data) => {
            this.setState({ selectedKey, slectedSubkey, data });
          }}
        />
      );

    if (selectedKey === "E-commerce") {
      const { permissions } = this.props.Auth.user.profileDetails;

      const PermissionsRomovingColor = RemoveColonFromPermission(permissions);

      return (
        <ECommerce
          eUser={PermissionsRomovingColor.includes("e-commerce")}
          setSelected={(selectedKey, slectedSubkey, data) => {
            this.setState({ selectedKey, slectedSubkey, data });
          }}
        />
      );
    }
    // if (selectedKey === "All Order Details")
    //   return (
    //     <CrmComponent
    //       setSelected={(selectedKey, slectedSubkey, data) => {
    //         this.setState({ selectedKey, slectedSubkey, data });
    //       }}
    //     />
    //   );
  };
  changeProfRoute = (key) => {
    this.setState({ selectedKey: key });
  };
  render() {
    const { Sider, Header } = Layout;
    const { permissions } = this.props.Auth.user.profileDetails;
    if (!permissions) {
      window.location.href = "/signin";
      message.error("You have not any permission");
    }
    const PermissionsRomovingColor = RemoveColonFromPermission(permissions);
    return (
      <Layout style={{ overflow: "hidden", height: "100vh" }}>
        <Sider
          trigger={null}
          style={{
            ...styles.sider,

            display: this.state.collapsed ? "none" : "block",
          }}
        >
          <div style={styles.logo}>
            <img src="/image/logo.png" style={styles.logo} alt="Poultry Baba" />
          </div>
          <PermissionsGateAdmin>
            <Menu
              style={styles.menu}
              mode="inline"
              onSelect={({ key }) => this.changeProfRoute(key)}
              selectedKeys={[this.state.selectedKey.split("/")[0]]}
            // defaultSelectedKeys={[this.state.selectedKey]}
            >
              <Menu.Item
                disabled={
                  PermissionsRomovingColor.includes("Crm_System") ||
                    PermissionsRomovingColor.includes("AllResources")
                    ? false
                    : true
                }
                key="Crm_System"
              >
                <span> CRM System</span>
              </Menu.Item>

              <Menu.Item
                disabled={
                  PermissionsRomovingColor.includes("Crm_System") ||
                    PermissionsRomovingColor.includes("AllResources")
                    ? false
                    : true
                }
                key="Murghi Mandi Details"
              >
                <span> Murghi Mandi </span>
                {/* <Badge count={0} overflowCount={10}>
                  <span> Murghi Mandi </span>
                </Badge> */}
              </Menu.Item>

              <Menu.Item
                disabled={
                  PermissionsRomovingColor.includes("Crm_System") ||
                    PermissionsRomovingColor.includes("AllResources") ||
                    PermissionsRomovingColor.includes("e-commerce")
                    ? false
                    : true
                }
                key="E-commerce"
              >
                <span> E-Commerce</span>
                {/* <Badge count={0} overflowCount={10}>
                  <span> E-Commerce</span>
                </Badge> */}
              </Menu.Item>
            </Menu>
          </PermissionsGateAdmin>

          <PermissionsGateUser>
            <Menu
              style={styles.menu}
              mode="inline"
              onSelect={({ key }) => this.changeProfRoute(key)}
              selectedKeys={[this.state.selectedKey.split("/")[0]]}
            // defaultSelectedKeys={[this.state.selectedKey]}
            >
              <Menu.Item
                disabled={
                  PermissionsRomovingColor.includes("Crm_System") ||
                    PermissionsRomovingColor.includes("AllResources")
                    ? false
                    : true
                }
                key="E-commerce"
              >
                <span> E-Commerce </span>
                {/* <Badge count={0} overflowCount={20}>
                  <span> E-Commerce </span>
                </Badge> */}
              </Menu.Item>

              {/* <Menu.Item
              disabled={
                PermissionsRomovingColor.includes("Crm_System") ? false : true
              }
              key="All Order Details"
            >
              <span>All Order Details</span>
            </Menu.Item> */}
            </Menu>
          </PermissionsGateUser>
        </Sider>
        <Layout className="site-layout">
          <Header className="site-layout-background" style={styles.header}>
            <HeaderContent
              toggleSider={this.toggleSider}
              collapsed={this.state.collapsed}
              name={this.state.input}
              onChange={this.inputEvent}
              onNotification={(noficicationCount) =>
                this.setState({ noficicationCount })
              }
            />
          </Header>

          <div style={styles.content}>{this.profRouteRenderer()}</div>
          {/* </Content> */}
          <audio
            id={"short_play"}
            // style={{ display: "none" }}
            src={AudioSound}
            autoPlay={false}
            muted={false}
          />
        </Layout>
      </Layout>
    )
  }
}

const mapStateToProps = (store) => ({
  Auth: store.Authentication,
  cities: store.Cities.cities,
  isMobileView: store.Setting.isMobileView,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getCities: (token) => {
      dispatch(getAllCities(token));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
