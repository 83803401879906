import { authService } from "../shared";
import axios from "axios";
import { message as messageAlert } from "antd";

export const getAdminOrSubAdmins = async (token) => {
  const uri = "/admin/api/v1/customers/getAdminOrSubAdmins";
  const resp = await axios.get(authService + uri, {
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp.data;
};

export const getCustomers = async (token, data) => {
  let uri = `/admin/api/v1/customers/getAllCustomers?limit=20`;

  const resp = await axios.get(authService + uri, {
    params: { ...data },
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp.data;
};

export const customerDeviceToken = async (token, { webFcmToken }) => {
  let uri = `/api/v1/customerProfile/updateDeviceToken`;

  const resp = await axios.post(
    authService + uri,
    { webFcmToken: webFcmToken },
    {
      headers: {
        Authorization: "bearer " + token,
        "api-key": "poultrybaba@1234Sumandri",
      },
    },
  );
  return resp.data;
};

// const customerDeviceToken = async () => {
//   try {
//     await authAxios
//       .post("/api/v1/customerProfile/updateDeviceToken", {})
//       .then((res) => console.log(res));
//   } catch (err) {
//     throw err;
//   }
// };

export const findCustomerWithPhoneNumbers = async (token, payload) => {
  let uri = `/admin/api/v1/customers/findCustomerWithPhoneNumbers`;
  const resp = await axios.post(authService + uri, payload, {
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp.data;
};

export const changeCompApi = async (token, payload) => {
  let uri = `/admin/api/v1/contact/changeContactOwner`;
  const resp = await axios.post(authService + uri, payload, {
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp.data;
};

export const findCustomerWithPhone = async (token, payload) => {
  let uri = `/admin/api/v1/customers/findCustomersWithPhone`;
  const resp = await axios.post(authService + uri, payload, {
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp.data;
};

export const findCustomerOlderThanThreeDay = async (token, payload) => {
  let uri = `/admin/api/v1/customers/findCustomerOlderThanThreeDay`;
  const resp = await axios.post(authService + uri, payload, {
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp.data;
};

export const checkUserExitAgainstNumber = async (token, payload) => {
  let uri = `/admin/api/v1/subAdminTasks/checkUserExitAgainstNumber`;
  const resp = await axios.post(authService + uri, payload, {
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp.data;
};

export const AddCity = async (payload, token) => {
  const uri = "/admin/api/v1/cities/addCities";
  const resp = await axios.post(authService + uri, payload, {
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp.data;
};
export const getAllAvailableCities = async (token) => {
  const uri = "/admin/api/v1/cities/getAllAvailableCities";
  const resp = await axios.get(
    authService + uri,

    {
      headers: {
        Authorization: "bearer " + token,
        "api-key": "poultrybaba@1234Sumandri",
      },
    },
  );
  return resp.data;
};
export const activeCityForCityRequest = async (id, data, token) => {
  const uri = `/admin/api/v1/cities/activeForProduct/${id}`;
  const resp = await axios.patch(authService + uri, data, {
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp;
};

export const deActiveCityForCityRequest = async (id, data, token) => {
  const uri = `/admin/api/v1/cities/inActiveForProduct/${id}`;
  const resp = await axios.patch(authService + uri, data, {
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp;
};
export const createAdminOrSubAdmin = async (
  { name, email, password, phoneNumber, city, type, permissions },
  token,
) => {
  const uri = "/admin/api/v1/admin/createAdminOrSubAdmin";
  const resp = await axios.post(
    authService + uri,
    { name, email, password, phoneNumber, city, type, permissions },
    {
      headers: {
        Authorization: "bearer " + token,
        "api-key": "poultrybaba@1234Sumandri",
      },
    },
  );
  return resp.data;
};
export const deleteUserByUserId = async ({ userId }, token) => {
  const uri = "/admin/api/v1/customers/deleteUserById?userId=" + userId;
  const resp = await axios.delete(authService + uri, {
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp.data;
};

export const feedBackGet = async ({ pageNo, feedbackResponsed }, token) => {
  const uri =
    "/admin/api/v1/pbFeedback/getFeedbacks?pageNo=" +
    pageNo +
    "&feedbackResponsed=" +
    feedbackResponsed;

  const resp = await axios.get(authService + uri, {
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp.data;
};

export const feedBackEdit = async ({ feedbackId, attributes }, token) => {
  const uri = "/admin/api/v1/pbFeedback/updateFeedback";
  const resp = await axios.post(
    authService + uri,
    { feedbackId, attributes },
    {
      headers: {
        Authorization: "bearer " + token,
        "api-key": "poultrybaba@1234Sumandri",
      },
    },
  );
  return resp.data;
};

export const getListOfMandiGurus = async (
  requestStatus = "PENDING",
  pageNo,
  token,
) => {
  const uri = "/admin/api/v1/mandigurus/getMandiGurusWithPagination";
  const resp = await axios.get(authService + uri, {
    params: {
      requestStatus,
      pageNo,
    },

    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp.data;
};

export const updateMandiGuruRequestStatus = async (
  { customerId, requestStatus },
  token,
) => {
  const uri = "/admin/api/v1/mandigurus/updateMandiGuruRequestStatus";
  const resp = await axios.post(
    authService + uri,
    {
      customerId,
      requestStatus,
    },
    {
      headers: {
        Authorization: "bearer " + token,
        "api-key": "poultrybaba@1234Sumandri",
      },
    },
  );
  return resp.data;
};

export const getUserCrmUser = async (params, token) => {
  const uri = `/admin/api/v1/stat/userCrmDailyUser`;
  const resp = await axios.get(authService + uri, {
    params: {
      ...params,
    },
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp.data;
};

export const getUserCrmDailyStat = async (params, token) => {
  const uri = `/admin/api/v1/stat/userCrmDailyStat`;
  const resp = await axios.get(authService + uri, {
    params: {
      ...params,
    },
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp.data;
};

export const getContactWithTask = async (params, token) => {
  const uri = "/admin/api/v1/subAdminTasks/getContacts";
  const resp = await axios.post(
    authService + uri,
    {},
    {
      params: {
        ...params,
      },
      headers: {
        Authorization: "bearer " + token,
        "api-key": "poultrybaba@1234Sumandri",
      },
    },
  );
  return resp.data;
};

export const AddTaskPhoneNumber = async (payload, token) => {
  const uri = "/admin/api/v1/subAdminTasks/createContacts";
  const resp = await axios.post(authService + uri, payload, {
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp.data;
};

export const quantityOfContact = async (payload, token) => {
  const uri = "/admin/api/v1/subAdminTasks/quantityOfContact";
  const resp = await axios.get(authService + uri, {
    params: {
      ...payload,
    },
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp.data;
};

export const updatePhoneStatusCrm = async (payload, token) => {
  const uri = "/admin/api/v1/subAdminTasks/updateContactStatus";
  const resp = await axios.post(authService + uri, payload, {
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp.data;
};

export const createTask = async (payload, token) => {
  const uri = "/admin/api/v1/subAdminTasks/createTask";
  const resp = await axios.post(authService + uri, payload, {
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp.data;
};

export const getTaskUserIdAndMonth = async ({ userId, month }, token) => {
  const uri = "/admin/api/v1/subAdminTasks/getTasks";
  const resp = await axios.post(
    authService + uri,
    {},
    {
      params: {
        userId,
        month,
      },
      headers: {
        Authorization: "bearer " + token,
        "api-key": "poultrybaba@1234Sumandri",
      },
    },
  );
  return resp.data;
};

export const getAdminAlerts = async ({ page }, token) => {
  const uri = "/admin/api/v1/adminAlert/getAdminAlert";
  const resp = await axios.get(
    authService + uri,

    {
      params: {
        page,
      },
      headers: {
        Authorization: "bearer " + token,
        "api-key": "poultrybaba@1234Sumandri",
      },
    },
  );
  return resp.data;
};

export const createNotification = async (data, token, setLoading) => {
  let { version, message, title, appLink } = data;
  try {
    setLoading(true);
    const uri = "/admin/api/v1/customers/broadcastNotification";
    const resp = await axios.post(
      authService + uri,
      { notificationType: version, message, title, appLink },
      {
        headers: {
          Authorization: "bearer " + token,
          "api-key": "poultrybaba@1234Sumandri",
        },
      },
    );
    setLoading(false);
    messageAlert.success("Notification is successfully sended");
    return resp.data;
  } catch (error) {
    messageAlert.error("We can't process your resquest please try again later");
    setLoading(false);
  }
};

export const createNotificationAdminAlert = async (
  data,
  token,
  setLoading,
  setAddNotification,
) => {
  let { message, title, sentTime } = data;
  try {
    setLoading(true);
    const uri = "/admin/api/v1/adminAlert/createNotification";
    const resp = await axios.post(
      authService + uri,
      { message, title, sentTime, active: true },
      {
        headers: {
          Authorization: "bearer " + token,
          "api-key": "poultrybaba@1234Sumandri",
        },
      },
    );
    setLoading(false);
    setAddNotification(false);
    messageAlert.success("Notification is successfully sended");
    return resp.data;
  } catch (error) {
    debugger;
    console.log(error);
    messageAlert.error("We can't process your resquest please try again later");
    setLoading(false);
  }
};

export const updateNotificationAdminAlert = async (
  data,
  token,
  setLoading,
  setAddNotification,
) => {
  try {
    setLoading(true);
    const uri = "/admin/api/v1/adminAlert/updateNotification";
    const resp = await axios.post(
      authService + uri,
      { ...data },
      {
        headers: {
          Authorization: "bearer " + token,
          "api-key": "poultrybaba@1234Sumandri",
        },
      },
    );
    setLoading(false);
    setAddNotification(false);
    messageAlert.success("Notification is edited successfully");
    return resp.data;
  } catch (error) {
    debugger;
    console.log(error);
    messageAlert.error("We can't process your resquest please try again later");
    setLoading(false);
  }
};

export const getNotificationsAdminAlert = async (payload, token) => {
  const uri = "/admin/api/v1/adminAlert/getNotfications";
  const resp = await axios.get(authService + uri, {
    params: {
      ...payload,
    },
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp.data;
};

// [16:34, 11/20/2021] Amir Gamica: {
//   path: '/admin/api/v1/adminAlert/createNotification',
//   methods: [ 'POST' ]
// },
// {
//   path: '/admin/api/v1/adminAlert/updateNotification',
//   methods: [ 'POST' ]
// },
// {
//   path: '/admin/api/v1/adminAlert/getNotfications',
//   methods: [ 'GET' ]
// },
// [16:36, 11/20/2021] Amir Gamica: body:{    message:String
//   title:String,
//   active:boolean,
//   sentTime: timestamp}
// [16:36, 11/20/2021] Amir Gamica: ye create Notification ki body hai
// [16:37, 11/20/2021] Amir Gamica: or update ki body me {notificationId:ObjectId, message:String
//   title:String,
//   active:boolean,
//   sentTime: timestamp}
