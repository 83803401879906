import {
  Button,
  Col,
  Divider,
  Dropdown,
  Input,
  Menu,
  Popconfirm,
  Row,
  Select,
  message,
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { HiDotsVertical } from "react-icons/hi";
import { getProductsServiceApi } from "../../../RestServices/ecommerenceService";
import useCategoryAndSubCategories from "../../../hook/useCategoryAndSubCategoryies";
import { baseUrlOfCommerce } from "../../../shared";
import Table from "../../../utils/Table";
import { Sorter } from "../../../utils/sorter";
import CustomPagination from "../../Pagination";
import { SideDrawer } from "../../Utils/SideDrawer";
import FormProductComponent from "./addProuct/addFormProduct";
import "./product.css";
import ProductDetailModal from "./productDetailModal";

const { Option } = Select;

export default function Product({
  token,
  userId,
  companyId = undefined,
  stat,
  text = "",
  filterType,
  startDate,
  endDate,
}) {
  const [data, setData] = useState({});
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [dataProductModal, setDataProductModal] = useState([]);
  const [productModal, setProductModal] = useState(false);
  const [password, setPassword] = useState("");

  const confirm = async (rowRecordProduct) => {
    try {
      const uri = `${baseUrlOfCommerce}/api/v1/products/getProducts/${rowRecordProduct._id}`;

      await axios.patch(
        uri,
        {},
        {
          headers: {
            Authorization: "bearer " + token,
            "api-key": "poultrybaba@1234Sumandri",
          },
        },
      );
      getProduct();
      message.success("Product Deleted Successfully!");
    } catch (error) {
      console.log(error);
    }
  };

  const cancel = (e) => {};

  const menuProduct = (rowRecord) => (
    <Menu>
      <Menu.Item
        onClick={() => {
          setDataProductModal(rowRecord);
          setProductModal(true);
        }}
      >
        View
      </Menu.Item>
      <Menu.Item
      // onClick={() => {
      //   setActiveTab("1");
      //   const { ordered, ...rest } = rowRecord;
      //   setProductDetails({
      //     ...rest,
      //     dealer: rest.dealer?.map((dealer) => dealer._id),
      //     companyId: rowRecord?.companyId?._id,
      //     dealerId: rowRecord?.dealerId?._id,
      //   });
      //   setOpenNow(true);
      // }}
      >
        {/* Edit */}
        <Popconfirm
          style={{ width: "100%" }}
          arrowContent={true}
          title={
            <>
              Please give authorization pass key
              <div>
                <Input
                  defaultValue={""}
                  autoComplete={false}
                  type="password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  value={password}
                ></Input>
              </div>
            </>
          }
          onConfirm={() => {
            if (password !== "1345") {
              message.info("Password is incorrect");
              setPassword("");

              return;
            }
            setPassword("");
            setActiveTab("1");
            const { ordered, ...rest } = rowRecord;
            debugger;
            setProductDetails({
              ...rest,
              dealer: rest.dealer?.map((dealer) => dealer._id),
              companyId: rowRecord?.companyId?._id,
              dealerId: rowRecord?.dealerId?._id,
            });
            setTimeout(() => {
              setOpenNow(true);
            }, 100);
          }}
          // title="Are you sure to delete this product?"
          // onConfirm={() => confirm(rowRecord)}
          onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >
          <span
            style={{
              width: "100%",
              display: "inline-block",
            }}
          >
            Edit
          </span>
        </Popconfirm>
      </Menu.Item>
      <Menu.Item>
        <Popconfirm
          title={
            <>
              Are you sure to delete this Product?
              <div>
                <Input
                  defaultValue={""}
                  autoComplete={false}
                  type="password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  value={password}
                ></Input>
              </div>
            </>
          }
          onConfirm={() => {
            if (password !== "1345") {
              message.info("Password is incorrect");
              setPassword("");

              return;
            }
            setPassword("");
            confirm(rowRecord);
          }}
          // title="Are you sure to delete this product?"
          // onConfirm={() => confirm(rowRecord)}
          onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >
          <span
            style={{
              width: "100%",
              display: "inline-block",
            }}
          >
            Delete
          </span>
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: "",
      render: (_text, record) => (
        <Dropdown overlay={() => menuProduct(record)}>
          <Button style={{ border: "0", padding: "0" }}>
            <HiDotsVertical size={18} />
          </Button>
        </Dropdown>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      sorter: {
        compare: Sorter.DEFAULT,
        multiple: 9,
      },
    },
    {
      title: "Dealer",
      dataIndex: "dealer",
      render: (dealer) => `${dealer.length ? dealer[0]?.name : "N/A"}`,
      sorter: {
        compare: Sorter.DEFAULT,
        multiple: 8,
      },
    },
    {
      title: "Category",
      dataIndex: "category",
      sorter: {
        compare: Sorter.DEFAULT,
        multiple: 7,
      },
    },
    {
      title: "Size",
      dataIndex: "size",
      render: (size, details) =>
        size ? `${size} ${details.forms === "Liquid" ? "ML" : "grams"}` : "N/A",

      sorter: {
        compare: Sorter.DEFAULT,
        multiple: 7,
      },
    },

    // {
    //   title: "Form",
    //   dataIndex: "forms",
    //   sorter: {
    //     compare: Sorter.DEFAULT,
    //     multiple: 7,
    //   },
    // },

    {
      title: "Sub Category",
      dataIndex: "subCategory",
      sorter: {
        compare: Sorter.DEFAULT,
        multiple: 6,
      },
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      sorter: {
        compare: Sorter.DEFAULT,
        multiple: 5,
      },
    },
    {
      title: "Ordered",
      dataIndex: "ordered",
      sorter: {
        compare: Sorter.DEFAULT,
        multiple: 4,
      },
    },

    {
      title: "City",
      dataIndex: "city",
      sorter: {
        compare: Sorter.DEFAULT,
        multiple: 1,
      },
    },
  ];

  const [category, setCategory] = useState(null);
  const [subCategory, setSubCategory] = useState(null);

  const { categories, subCategories } = useCategoryAndSubCategories({
    parentValue: category,
  });

  const getProduct = async () => {
    const data = await getProductsServiceApi({
      page: page,
      category,
      companyId,
      subCategory,
    });

    setData(data.data);
    setDataList(data.data.docs);
  };

  const [openNow, setOpenNow] = useState(false);
  useEffect(() => {
    getProduct();
  }, [token, page, category, companyId, subCategory, openNow]);

  useEffect(() => {
    setCategory(filterType);
  }, [filterType]);

  const [productDetails, setProductDetails] = useState({});
  const [activeTab, setActiveTab] = useState("1");

  return (
    <div>
      <div>
        <ProductDetailModal
          open={productModal}
          product={dataProductModal}
          setOpen={setProductModal}
        />
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          marginTop: "10px",
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
        className="product_header"
      >
        <Row justify="end" gutter={[16, 16]}>
          <Col>
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Category"
              optionFilterProp="children"
              onChange={setCategory}
              value={category}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option key={"23423"} value={null}>
                {"All Product"}
              </Option>
              {categories.map((cate) => (
                <Option key={cate.value} value={cate.value?.toLowerCase()}>
                  {cate.value}
                </Option>
              ))}
            </Select>
          </Col>
          <Col>
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Sub Category"
              optionFilterProp="children"
              onChange={setSubCategory}
              value={subCategory}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {subCategory ? (
                <Option key={"234233"} value={null}>
                  {"De Select"}
                </Option>
              ) : (
                <></>
              )}
              {subCategories.map((cate) => (
                <Option key={cate.value} value={cate.value}>
                  {cate.value}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        <div className="create__product">
          <SideDrawer
            text={text}
            openNow={openNow}
            setOpenNow={(result) => {
              setActiveTab("1");
              setProductDetails({});
              setOpenNow(result);
            }}
            clearState={() => setProductDetails({})}
            textButton={"Add Product"}
          >
            {openNow ? (
              <FormProductComponent
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                setOpenNow={setOpenNow}
                getProduct={getProduct}
                productDetails={productDetails}
              />
            ) : (
              <></>
            )}
          </SideDrawer>
        </div>

        <Row gutter={companyId ? 24 : 20}>
          <Col
            xs={24}
            sm={24}
            lg={companyId ? 24 : 18}
            xl={companyId ? 24 : 18}
            style={{ marginBottom: "20px" }}
          >
            <Table
              columns={columns}
              pagination={false}
              dataSource={dataList}
              loading={loading}
              style={{ overflowX: "scroll" }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                marginTop: "20px",
              }}
            >
              <CustomPagination
                current={data.page}
                total={data?.totalDocs}
                pageSize={data?.limit}
                onPageChange={(p) => setPage(p)}
              />
            </div>
          </Col>
          {!companyId ? (
            <Col
              xs={24}
              sm={24}
              lg={6}
              xl={6}
              style={{
                padding: 0,
                background: "#fff",
                boxShadow: "0px 12px 23px rgba(62, 73, 84, 0.04)",
                marginBottom: "20px",
              }}
            >
              <div style={{ padding: "25px 15px" }}>
                <div className="order__filter__date">
                  <h1 className="order__heading">Overall Statistics</h1>
                  {/* <DatePicker
                  onChange={onChange}
                  style={{ width: "80px" }}
                  placeholder="Date"
                /> */}
                </div>
                <Divider style={{ margin: "10px 0" }} />

                {stat?.product &&
                  Object.keys(stat?.product).map((item, key) => (
                    <div key={key} className="order__content__company">
                      <Row style={{ marginBottom: "20px" }}>
                        <Col span={6}>
                          <div
                            className={`counting__wrapper counting__wrapper__${
                              key + 1
                            }`}
                          >
                            <span>{key + 1}</span>
                          </div>
                        </Col>
                        <Col span={14}>
                          <div className="order__text">
                            <span>{stat?.product[item]}</span>
                            <h5>{item}</h5>
                          </div>
                        </Col>
                        <Col span={4}>
                          <div className="order__button">
                            <div onClick={() => setCategory(item)}>View</div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ))}
              </div>
            </Col>
          ) : (
            <></>
          )}
        </Row>
      </div>
    </div>
  );
}
