import { Card, Col, Divider, Image, Modal, Row, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import "./company.css";
import StarDisplay from "../../Utils/StarDisplay";
import Product from "../Product/Product";
import { getDealersServiceApi } from "../../../RestServices/ecommerenceService";
import { useSelector } from "react-redux";
import DealerList from "../Dealer/Dealer";

const CompanyDetailsModal = ({ company, open, setOpen }) => {
  const [dealerRepresentative, setDealerRepresentative] = useState("1");

  const handleTabChangeDealer = (activeKey) => {
    setDealerRepresentative(activeKey);
  };

  const { token } = useSelector((state) => state.Authentication.user);

  const [dataDelears, setDataDealer] = useState([]);

  const getDealers = async () => {
    const data = await getDealersServiceApi(token, {
      page: 1,
      companyId: company._id,
    });

    setDataDealer(data.data.docs);
  };

  useEffect(() => {
    if (company) {
      getDealers();
    }
  }, [token, company]);

  return (
    <Modal
      className="ant-modal-dealer"
      title="Company Details"
      centered
      visible={open}
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      width={"76%"}
    >
      <Row>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={11}
          xl={11}
          className="border-right-lg"
        >
          <Row justify="center">
            <Col span={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Image
                  style={{
                    borderRadius: "50%",
                    width: "150px",
                    height: "150px",
                    border: "5px solid #ffa200",
                  }}
                  src={company?.logo}
                  alt={company?.name}
                />
              </div>
            </Col>
          </Row>
          <div className="modal-styles">
            <Row justify="center" style={{ textAlign: "center" }}>
              <Col span={24}>
                <Card title={company?.name} bordered={false}>
                  {company?.description}
                </Card>
              </Col>
            </Row>
          </div>
          <div className="modal-styles modal-info">
            <Row justify="center" style={{ textAlign: "center" }}>
              <Col span={24}>
                <Card title="Additional Info" bordered={false}>
                  <Row style={{ marginBottom: "8px" }} gutter={10}>
                    <Col span={12}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                        }}
                      >
                        <span className="ai-heading">Rating: </span>
                        <span>
                          <StarDisplay rating={company?.rating} />
                        </span>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div>
                        <span className="ai-heading">Category: </span>
                        <span style={{ textTransform: "capitalize" }}>
                          {company?.category}
                        </span>
                      </div>
                    </Col>
                  </Row>

                  <Row style={{ marginBottom: "8px" }} gutter={10}>
                    <Col span={12}>
                      <div>
                        <span className="ai-heading">City: </span>
                        <span style={{ textTransform: "capitalize" }}>
                          {company?.city}
                        </span>
                      </div>
                    </Col>
                  </Row>

                  <Row style={{ marginBottom: "8px" }} gutter={10}>
                    <Col span={12}>
                      <div>
                        <span className="ai-heading">Certified: </span>
                        <span style={{ textTransform: "capitalize" }}>
                          {company?.certified ? "Yes" : "No"}
                        </span>
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: "8px" }}>
                    <Col span={24}>
                      <div>
                        <span className="ai-heading">Company Address: </span>
                        <span style={{ textTransform: "capitalize" }}>
                          {company?.address}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </div>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={13}
          xl={13}
          style={{ paddingLeft: "10px" }}
        >
          <div className="modal-styles">
            <Row justify="center" style={{ textAlign: "center" }}>
              <Col span={24}>
                <Tabs
                  activeKey={dealerRepresentative}
                  onChange={handleTabChangeDealer}
                >
                  <Tabs.TabPane tab="Representative" key="1">
                    <Card
                      title="Company Representative Details"
                      bordered={false}
                    >
                      {company ? (
                        <>
                          {company?.companyRepresentative?.map((crd) => (
                            <>
                              <Row
                                style={{
                                  marginBottom: "8px",
                                  marginTop: "5px",
                                }}
                              >
                                <Col span={12}>
                                  <div>
                                    <span className="ai-heading ai-heading-sm">
                                      Name:{" "}
                                    </span>
                                    <span
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {crd?.name}
                                    </span>
                                  </div>
                                </Col>
                                <Col span={12}>
                                  <div>
                                    <span className="ai-heading ai-heading-sm">
                                      Phone:{" "}
                                    </span>
                                    <span
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {crd?.phoneNumbers[0]}
                                    </span>
                                  </div>
                                </Col>
                              </Row>

                              <Row style={{ marginBottom: "8px" }}>
                                <Col span={12}>
                                  <div>
                                    <span className="ai-heading ai-heading-sm">
                                      Whatsapp:{" "}
                                    </span>
                                    <span
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {crd?.whatsappNumber}
                                    </span>
                                  </div>
                                </Col>
                                <Col span={12}>
                                  <div>
                                    <span className="ai-heading ai-heading-sm">
                                      Designation:{" "}
                                    </span>
                                    <span
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {crd?.designation}
                                    </span>
                                  </div>
                                </Col>
                              </Row>
                              {crd?.bankDetail && (
                                <>
                                  {crd?.bankDetail?.map((cbd) => (
                                    <Row>
                                      <Col span={12}>
                                        <div>
                                          <span className="ai-heading ai-heading-sm">
                                            Bank Name:{" "}
                                          </span>
                                          <span
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {cbd?.bankName}
                                          </span>
                                        </div>
                                      </Col>
                                      <Col span={12}>
                                        <div>
                                          <span className="ai-heading ai-heading-sm">
                                            Account #:{" "}
                                          </span>
                                          <span
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {cbd?.accountNo}
                                          </span>
                                        </div>
                                      </Col>
                                    </Row>
                                  ))}
                                </>
                              )}
                              <Divider />
                            </>
                          ))}
                        </>
                      ) : (
                        <>No Record Found</>
                      )}
                    </Card>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Dealer" key="2">
                    <Card title="Company Dealers Details" bordered={false}>
                      {company ? (
                        <>
                          {dataDelears?.map((crd, index) => (
                            <div key={index}>
                              <Row
                                style={{
                                  marginBottom: "8px",
                                  marginTop: "5px",
                                }}
                              >
                                <Col span={12}>
                                  <div>
                                    <span className="ai-heading ai-heading-sm">
                                      SR. Number:{" "}
                                    </span>
                                    <span
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {index + 1}
                                    </span>
                                  </div>
                                </Col>
                                <Col span={12}>
                                  <div>
                                    <span className="ai-heading ai-heading-sm">
                                      Name:{" "}
                                    </span>
                                    <span
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {crd?.name}
                                    </span>
                                  </div>
                                </Col>
                                <Col span={12}>
                                  <div>
                                    <span className="ai-heading ai-heading-sm">
                                      Phone:{" "}
                                    </span>
                                    <span
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {crd?.phoneNumber}
                                    </span>
                                  </div>
                                </Col>
                                <Col span={12}>
                                  <div>
                                    <span className="ai-heading ai-heading-sm">
                                      Whatsapp:{" "}
                                    </span>
                                    <span
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {crd?.whatsappNumber}
                                    </span>
                                  </div>
                                </Col>
                              </Row>

                              <Row style={{ marginBottom: "8px" }}>
                                <Col span={12}>
                                  <div>
                                    <span className="ai-heading ai-heading-sm">
                                      Id Card Number:{" "}
                                    </span>
                                    <span
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {crd?.idCardNumber}
                                    </span>
                                  </div>
                                </Col>
                                <Col span={12}>
                                  <div>
                                    <span className="ai-heading ai-heading-sm">
                                      City:{" "}
                                    </span>
                                    <span
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {crd?.city}
                                    </span>
                                  </div>
                                </Col>
                                <Col span={28}>
                                  <div>
                                    <span className="ai-heading ai-heading-sm">
                                      Address:{" "}
                                    </span>
                                    <span
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {crd?.businessAddress}
                                    </span>
                                  </div>
                                </Col>
                              </Row>

                              <Divider />
                            </div>
                          ))}
                        </>
                      ) : (
                        <>No Record Found</>
                      )}
                    </Card>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Invoices" key="3">
                    <Card title="Product Invoice" bordered={false}>
                      <>No Record Found</>
                    </Card>
                  </Tabs.TabPane>
                </Tabs>
                {/*  */}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <DealerList
            companyId={company._id}
            hideTable={true}
            activeKey={3}
            text="1"
            token={token}
          />
          <Product text="2" companyId={company._id} />
        </Col>
      </Row>
    </Modal>
  );
};

export default CompanyDetailsModal;
