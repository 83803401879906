import {
  Button,
  Col,
  Dropdown,
  Image,
  Input,
  Menu,
  Popconfirm,
  Row,
  Select,
  message,
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { HiDotsVertical } from "react-icons/hi";
import {
  createDealerServiceApi,
  editDealerServiceApi,
  getDealersServiceApi,
} from "../../../RestServices/ecommerenceService";
import useCategoryAndSubCategories from "../../../hook/useCategoryAndSubCategoryies";
import { baseUrlOfCommerce } from "../../../shared";
import Table from "../../../utils/Table";
import { Sorter } from "../../../utils/sorter";
import CustomPagination from "../../Pagination";
import { SideDrawer } from "../../Utils/SideDrawer";
import AddDealer from "./AddDealer";
import DealerDetailModal from "./DealerDetailModal";

const { Option } = Select;

const initialState = {
  logo: undefined,
  description: "",
  name: "",
  city: "",
  phoneNumber: "",
  whatsappNumber: "",
  idCardNumber: "",
  businessAddress: "",
  // division: "",
  // district: "",
  // province: "",
  companyId: "",
  companyTypeCategory: "",
  accountHolderName: "",
  bankName: "",
  accountNumber: "",
  IBAN: "",
};

export default function DealerList({
  token,
  activeKey,
  hideTable,
  text = "",
  companyId,
  permissions,
  userId,
  startDate,
  endDate,
}) {
  console.log(companyId, "companyId");
  const [data, setData] = useState({});

  const [category, setCategory] = useState("");
  const [dataDealer, setDataDealer] = useState([]);
  const [dataDealerModal, setDataDealerModal] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [password, setPassword] = useState("");

  const confirm = async (rowRecordDealer) => {
    try {
      const uri = `${baseUrlOfCommerce}/api/v1/dealers/getDealers/${rowRecordDealer._id}`;

      await axios.patch(
        uri,
        {},
        {
          headers: {
            Authorization: "bearer " + token,
            "api-key": "poultrybaba@1234Sumandri",
          },
        },
      );
      getDealers();
      message.success("Dealer Deleted Successfully!");
    } catch (error) {
      console.log(error);
    }
  };

  const cancel = (e) => {};

  const menuDealer = (rowRecordDealer) => (
    <Menu>
      <Menu.Item
        onClick={() => {
          setDataDealerModal(rowRecordDealer);
          setDealerModal(true);
        }}
      >
        View
      </Menu.Item>
      <Menu.Item>
        <Popconfirm
          title={
            <>
              Please give authorization pass
              <div>
                <Input
                  autoComplete={false}
                  defaultValue={""}
                  type="password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  value={password}
                ></Input>
              </div>
            </>
          }
          onConfirm={() => {
            if (password !== "1345") {
              message.info("Password is incorrect");
              setPassword("");

              return;
            }
            setPassword("");
            setActiveTabDealer("1");
            setCreateDealer({ ...rowRecordDealer });
            setOpenNow(true);
          }}
          onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >
          Edit
        </Popconfirm>
      </Menu.Item>
      <Menu.Item>
        <Popconfirm
          title={
            <>
              Are you sure to delete this dealer?
              <div>
                <Input
                  autoComplete={false}
                  defaultValue={""}
                  type="password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  value={password}
                ></Input>
              </div>
            </>
          }
          onConfirm={() => {
            if (password !== "1345") {
              message.info("Password is incorrect");
              setPassword("");

              return;
            }
            setPassword("");
            confirm(rowRecordDealer);
          }}
          onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >
          Delete
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: "",
      render: (text, record) => (
        <Dropdown overlay={() => menuDealer(record)}>
          <Button style={{ border: "0", padding: "0" }}>
            <HiDotsVertical size={18} />
          </Button>
        </Dropdown>
      ),
    },
    {
      title: "Image",
      dataIndex: "logo",
      render: (logo) => (
        <Image
          style={{ width: "40px", height: "40px", borderRadius: "50%" }}
          width={40}
          height={40}
          src={logo}
          alt={logo}
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      sorter: {
        compare: Sorter.DEFAULT,
        multiple: 6,
      },
    },
    {
      title: "Company",
      dataIndex: "companyId",
      render: (companyId) => `${companyId?.name}`,
      sorter: {
        compare: Sorter.DEFAULT,
        multiple: 5,
      },
    },
    {
      title: "Category Type",
      dataIndex: "companyTypeCategory",
      sorter: {
        compare: Sorter.DEFAULT,
        multiple: 4,
      },
    },

    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      sorter: {
        compare: Sorter.DEFAULT,
        multiple: 2,
      },
    },
    {
      title: "Whatsapp Number",
      dataIndex: "whatsappNumber",
      sorter: {
        compare: Sorter.DEFAULT,
        multiple: 2,
      },
    },
    {
      title: "City",
      dataIndex: "city",
      sorter: {
        compare: Sorter.DEFAULT,
        multiple: 1,
      },
    },
  ];

  const [dealerModal, setDealerModal] = useState(false);

  const [createDealer, setCreateDealer] = useState({
    ...initialState,
    companyId: companyId ?? "",
  });

  const getDealers = async () => {
    const data = await getDealersServiceApi(token, {
      page: page,
      companyTypeCategory: category,
    });

    setData(data.data);
    setDataDealer(data.data.docs);
  };
  const [openNow, setOpenNow] = useState(false);

  useEffect(() => {
    getDealers();
  }, [token, page, activeKey, category, openNow]);

  const createDealerApi = async () => {
    try {
      setLoading(true);
      if (createDealer.hasOwnProperty("_id")) {
        const { deletedAt, district, division, province, companyId, ...rest } =
          createDealer;
        debugger;
        await editDealerServiceApi(
          { ...rest, companyId: companyId?._id ? companyId._id : companyId },
          token,
        );
      } else {
        await createDealerServiceApi(createDealer, token);
      }
      setOpenNow(false);
      setCreateDealer(initialState);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const { categories } = useCategoryAndSubCategories({
    parentValue: undefined,
  });

  const [activeTabDealer, setActiveTabDealer] = useState("1");

  return (
    <div>
      <div>
        <DealerDetailModal
          open={dealerModal}
          dealer={dataDealerModal}
          setOpen={setDealerModal}
        />
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
      >
        <SideDrawer
          openNow={openNow}
          setOpenNow={(check) => {
            setActiveTabDealer("1");
            setOpenNow(check);
          }}
          text={text}
          textButton={"Add Dealer"}
        >
          <AddDealer
            companyId={companyId}
            createDealer={createDealer}
            setCreateDealer={setCreateDealer}
            createDealerApi={createDealerApi}
            setActiveTabDealer={setActiveTabDealer}
            activeTabDealer={activeTabDealer}
            setOpenNow={setOpenNow}
          />
        </SideDrawer>
      </div>
      {!hideTable ? (
        <>
          <Row justify="end" style={{ marginBottom: "20px" }}>
            <Col>
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder="Category"
                optionFilterProp="children"
                onChange={setCategory}
                value={category}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option key={"23423"} value={null}>
                  {"All dealer"}
                </Option>
                {categories.map((cate) => (
                  <Option key={cate.value} value={cate.value.toLowerCase()}>
                    {cate.value}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>

          <Row gutter={20}>
            <Col
              xs={24}
              sm={24}
              lg={24}
              xl={24}
              style={{ marginBottom: "20px" }}
            >
              <Table
                columns={columns}
                pagination={false}
                dataSource={dataDealer}
                loading={loading}
                style={{ overflowX: "scroll" }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  marginTop: "20px",
                }}
              >
                {data?.docs?.length > 0 && (
                  <CustomPagination
                    pageSize={data?.limit}
                    current={data?.page}
                    total={data?.totalDocs}
                    onPageChange={(p) => setPage(p)}
                  />
                )}
              </div>
            </Col>
            {/* <Col
            xs={24}
            sm={24}
            lg={6}
            xl={6}
            style={{
              padding: 0,
              background: "#fff",
              boxShadow: "0px 12px 23px rgba(62, 73, 84, 0.04)",
              marginBottom: "20px",
            }}
          >
            <AsideCategory />
          </Col> */}
          </Row>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}
