import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import {
  createCompanyServiceApi,
  editCompanyServiceApi,
} from "../../../../RestServices/ecommerenceService";

import "./addform.css";

import CompanyDetailForm from "./companyDetailForm";
import Representative from "./Representative";
const initialState = {
  name: "",
  certified: true,
  logo: "http://test.com",
  rating: 5,
  address: "",
  city: "",
  category: "",
  description: "",
  companyRepresentative: [
    {
      name: "",
      phoneNumbers: [""],
      whatsappNumber: null,
      designation: "",
      bankDetail: [
        {
          bankName: "",
          accountNo: "",
        },
      ],
    },
  ],
};

const FormComponent = ({
  token,
  step,
  setStep,
  setOpenNow,
  categories,
  editScreenData,
  createAdminOrSubAdmin,
}) => {
  useEffect(() => {
    setCreateCompany(
      editScreenData
        ? { ...editScreenData }
        : {
            name: "",
            certified: true,
            logo: "http://test.com",
            rating: 5,
            address: "",
            city: "",
            category: "",
            description: "",
            companyRepresentative: [
              {
                name: "",
                phoneNumbers: [""],
                whatsappNumber: null,
                designation: "",
                bankDetail: [
                  {
                    bankName: "",
                    accountNo: "",
                  },
                ],
              },
            ],
          },
    );
  }, [editScreenData]);
  const [createCompany, setCreateCompany] = useState({ ...initialState });
  const [loadingButton, setLoading] = useState(false);
  const [currentRepresentative, setCurrentRepresentative] = useState(0);

  const createCompanyApi = async () => {
    try {
      setLoading(true);
      // delete createCompany.description;
      if (createCompany.hasOwnProperty("_id")) {
        const { deletedAt, ...rest } = createCompany;
        await editCompanyServiceApi(rest, token);
      } else {
        await createCompanyServiceApi(createCompany, token);
        try {
          
          await createAdminOrSubAdmin(createCompany);
        } catch (err) {
          console.log(err)
        }
      }
      setOpenNow(false);

      setCreateCompany({
        name: "",
        certified: true,
        logo: "http://test.com",
        rating: 5,
        address: "",
        city: "",
        category: "",
        description: "",
        companyRepresentative: [
          {
            name: "",
            phoneNumbers: [""],
            whatsappNumber: null,
            designation: "",
            bankDetail: [
              {
                bankName: "",
                accountNo: "",
              },
            ],
          },
        ],
      });
      setStep(1);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row justify="center" align="middle" style={{ marginTop: "20px" }}>
      <Col style={{ marginBottom: "10px" }} span={22}>
        <Row justify="space-around">
          <Col
            style={{
              borderRadius: "12px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "10px",
            }}
            className={step === 2 ? "arrow-pointer" : "arrow-pointer active"}
            span={11}
          >
            Step 1
          </Col>
          <Col
            style={{
              borderRadius: "12px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "10px",
            }}
            className={step === 1 ? "arrow-pointer" : "arrow-pointer active"}
            span={11}
          >
            Step 2
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        {(() => {
          if (step === 1) {
            return (
              <CompanyDetailForm
                categories={categories}
                createCompany={createCompany}
                setCreateCompany={setCreateCompany}
                step={step}
                setStep={setStep}
              />
            );
          }
          if (step === 2) {
            return (
              <Representative
                currentRepresentative={currentRepresentative}
                setCurrentRepresentative={setCurrentRepresentative}
                createCompany={createCompany}
                setCreateCompany={setCreateCompany}
                step={step}
                loadingButton={loadingButton}
                setStep={setStep}
                createCompanyApi={createCompanyApi}
              />
            );
          }
        })()}
      </Col>
    </Row>
  );
};

export default FormComponent;
