import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getStatRequest } from "../../../RestServices/ecommerenceService";
import { sumOfArrayElement } from "../../../shared";
import "./dasbaords.css";

export default function ECommerceDashboard({
  startDate,
  permissions,
  city,
  setSelectTab,
  endDate,
  stat,
}) {
  // const { token, _id: userId } = useSelector(
  //   (state) => state.Authentication.user,
  // );

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const statItem = await getStatRequest(token, {
  //         startDate: startDate.toDate(),
  //         endDate: endDate.toDate(),
  //         city: city,
  //       });
  //       setStat(statItem);
  //     } catch (err) {
  //       console.log(err.response);
  //     }
  //   })();
  // }, [token, city, startDate, endDate]);

  // const [isModalVisiblePost, setIsModalVisiblePost] = useState(false);
  // const [status, setStatus] = useState("");
  // const kFormatter = (num) => {
  //   if (isNaN(num)) {
  //     return "0";
  //   }
  //   return Math.abs(num) > 999
  //     ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(3) + "k"
  //     : Math.sign(num) * Math.abs(num);
  // };

  return (
    <div>
      <Row
        gutter={[10, 10]}
        style={{ paddingRight: "10px", paddingLeft: "10px" }}
      >
        <Col md={6} sm={8} xs={12} lg={3}>
          <div
            className="item_mur"
            onClick={() => {
              setSelectTab("5", "");

              // setSelectedModal({
              //   stat: stat?.sellerStat,
              //   payment: stat?.sellingPaymentDetail,
              // });
              // setIsModalVisible(true);
            }}
          >
            <div className="item_inner_dev">
              <div className="item_overall">Overall</div>
              <div className="item_top_seller">Total Order</div>
              <div className="item_counting">
                {stat?.order
                  ? sumOfArrayElement(Object.values(stat?.order))
                  : "N/A"}
              </div>
              <button className="item_button">View More</button>
            </div>
          </div>
        </Col>
        {/* {stat?.order &&
          Object.keys(stat?.order).map((item) => (
            <Col key={item} md={6} sm={8} xs={12} lg={3}>
              <div
                className="item_mur"
                onClick={() => {
                  setSelectTab("5", item);

                  // setSelectedModal({
                  //   stat: stat?.buyerStat,
                  //   payment: stat?.buyingPaymentDetail,
                  // });
                  // setIsModalVisible(true);
                }}
              >
                <div className="item_inner_dev">
                  <div className="item_overall">Overall</div>
                  <div className="item_top_seller">{item}</div>
                  <div className="item_counting">{stat?.order[item]}</div>
                  <button className="item_button">View More</button>
                </div>
              </div>
            </Col>
          ))} */}
        <Col md={6} sm={8} xs={12} lg={3}>
          <div
            className="item_mur"
            onClick={() => {
              setSelectTab("8", "parentCategory");

              // setSelectedModal({
              //   stat: stat?.buyerStat,
              //   payment: stat?.buyingPaymentDetail,
              // });
              // setIsModalVisible(true);
            }}
          >
            <div className="item_inner_dev">
              <div className="item_overall">Overall</div>
              <div className="item_top_seller">Category</div>
              <div className="item_counting">
                {stat?.parentCategory + stat?.childCategory || "N/A"}
              </div>
              <button className="item_button">View More</button>
            </div>
          </div>
        </Col>{" "}
        <Col md={6} sm={8} xs={12} lg={3}>
          <div
            className="item_mur"
            onClick={() => {
              setSelectTab("12");

              // setStatus("buying");

              // setIsModalVisiblePost(true);
            }}
          >
            <div className="item_inner_dev">
              <div className="item_overall">Overall</div>
              <div className="item_top_seller">Company</div>
              <div className="item_counting">{stat?.company | "N/A"}</div>
              <button className="item_button">View </button>
            </div>
          </div>
        </Col>
        <Col md={6} sm={8} xs={12} lg={3}>
          <div
            className="item_mur"
            onClick={() => {
              setSelectTab("3");
            }}
          >
            <div className="item_inner_dev">
              <div className="item_overall">Overall</div>
              <div className="item_top_seller">Dealer</div>
              <div className="item_counting">{stat?.dealer | "N/A"}</div>
              <button className="item_button">View More</button>
            </div>
          </div>
        </Col>{" "}
        <Col md={6} sm={8} xs={12} lg={3}>
          <div
            className="item_mur"
            onClick={() => {
              setSelectTab("2", false);
            }}
          >
            <div className="item_inner_dev">
              <div className="item_overall">Overall</div>
              <div className="item_top_seller">Interested Company</div>
              <div className="item_counting">
                {stat?.yetToMadeCompany | "N/A"}
              </div>
              <button className="item_button">View More</button>
            </div>
          </div>
        </Col>{" "}
        <Col md={6} sm={8} xs={12} lg={3}>
          <div
            className="item_mur"
            onClick={() => {
              setSelectTab("22", true);
            }}
          >
            <div className="item_inner_dev">
              <div className="item_overall">Overall</div>
              <div className="item_top_seller">Company Made</div>
              <div className="item_counting">{stat?.madeCompany | "N/A"}</div>
              <button className="item_button">View More</button>
            </div>
          </div>
        </Col>
        {stat?.product && (
          <Col md={6} sm={8} xs={12} lg={3}>
            <div
              className="item_mur"
              onClick={() => {
                setSelectTab("4");
              }}
            >
              <div className="item_inner_dev">
                <div className="item_overall">Overall</div>
                <div className="item_top_seller">All Product</div>
                <div className="item_counting">
                  {(() => {
                    const total = [...Object.values(stat?.product)].reduce(
                      (partialSum, a) => partialSum + a,
                      0,
                    );
                    return total;
                  })()}
                </div>
                <button className="item_button">View More</button>
              </div>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
}
