import { Card, Col, Modal, Row } from "antd";
import React from "react";
import "./InqueryOrder.css";
// import InqueryModalText from "./InqueryModalText";

export default function InqueryOrderModal({ open, setOpen, enquiry }) {
  return (
    <>
      <Modal
        centered
        visible={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        footer={false}
        style={{ width: "380px", height: "540px", overflowX: "hidden" }}
      >
        {/* <InqueryModalText /> */}
        <Row className="order-modal">
          <Col span={24}>
            <div className="modal-styles">
              <Row justify="center" style={{ textAlign: "center" }}>
                <Col span={24}>
                  <Card title="Enquiry Details" bordered={false}></Card>
                </Col>
              </Row>
              <Row style={{ marginBottom: "8px" }}>
                <Col span={24}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    <span style={{ fontWeight: 600 }}>Enquiry ID: </span>{" "}
                    <span>{enquiry?._id}</span>
                  </div>
                </Col>
              </Row>
              <Row style={{ marginBottom: "8px" }} gutter={10}>
                <Col span={24}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    <span className="ai-heading">Category: </span>
                    <span style={{ textTransform: "capitalize" }}>{enquiry?.category}</span>
                  </div>
                </Col>
              </Row>
              <Row style={{ marginBottom: "8px" }} gutter={10}>
                <Col span={24}>
                  <div>
                    <span className="ai-heading">SubCategory: </span>
                    <span style={{ textTransform: "capitalize" }}>
                      {enquiry?.subCategory}
                    </span>
                  </div>
                </Col>
              </Row>
              <Row style={{ marginBottom: "8px" }} gutter={10}>
                <Col span={24}>
                  <div>
                    <span className="ai-heading">SpecializeCategory: </span>
                    <span style={{ textTransform: "capitalize" }}>
                      {enquiry?.specializeCategory}
                    </span>
                  </div>
                </Col>
              </Row>
              <Row style={{ marginBottom: "8px" }} gutter={10}>
                <Col span={24}>
                  <div>
                    <span className="ai-heading">Unit: </span>
                    <span style={{ textTransform: "capitalize" }}>
                      {enquiry?.unit}
                    </span>
                  </div>
                </Col>
              </Row>
              <Row style={{ marginBottom: "8px" }} gutter={10}>
                <Col span={24}>
                  <div>
                    <span className="ai-heading">Quantity: </span>
                    <span>{enquiry?.quantity}</span>
                  </div>
                </Col>
              </Row>
              <Row style={{ marginBottom: "8px" }}>
                <Col span={24}>
                  <div>
                    <span className="ai-heading">Status: </span>
                    <span
                      className={`${enquiry?.status === "FULL_FILLED"
                        ? "text-green"
                        : "text-red"
                        }`}
                    >
                      {enquiry?.status}
                    </span>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  );
}
