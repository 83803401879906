import React from "react";
import { Typography } from "antd";

export default function Message({ userId, biddingGet }) {
  return (
    <div>
      {biddingGet.map((value) => {
        return (
          <div
            style={{
              padding: "10px",
              margin: "5px",
              height: "45px",
              paddingLeft: "30px",
              borderRadius: "20px",
              display: "flex",
              justifyContent: "space-around",
              border: userId === value.pidCreatorId ? "1px gray solid" : "0",
            }}
          >
            <Typography.Text style={{ height: "20px" }}>
              {userId === value.pidCreatorId ? (
                "Offered Amount : " + value.offeredPrice
              ) : (
                <span
                  style={{
                    padding: "10px",
                    borderRadius: "10px",
                    color: "white",
                    paddingLeft: "20px",
                    background:
                      value.finalBidForParent === "OFFER_ACCEPTED"
                        ? "green"
                        : "red",
                  }}
                >
                  {value.bidStatus}
                </span>
              )}
            </Typography.Text>
            <Typography.Text>
              Payment :{" "}
              {userId === value.pidCreatorId
                ? value.paymentStatusClient
                : value.paymentStatusOwner}
            </Typography.Text>
          </div>
        );
      })}
    </div>
  );
}
