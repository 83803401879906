import { Button, Col, Row, Select } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  deleteCompanyRequest,
  getCategories,
  getInterestedCompany,
} from "../../../RestServices/ecommerenceService";
import Table from "../../../utils/Table";
import { Sorter } from "../../../utils/sorter";
import CustomPagination from "../../Pagination";
import { SideDrawer } from "../../Utils/SideDrawer";
import FormComponent from "../Company/addCompany/addFormCompany";
const { Option } = Select;

export default function CompanyRequest({
  token,
  permissions,
  filterType,
  userId,
  startDate,
  endDate,
}) {
  const [companies, setInterestCompany] = useState({});
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [editScreenData, setEditScreenData] = useState(undefined);
  const [openNow, setOpenNow] = useState(false);

  const [deleteCheck, setDeleteCheck] = useState(false);

  const authAxios = axios.create({
    baseURL: "https://api.poultrybaba.com/auth-service",
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${token}`,
    },
  });

  const columns = [
    {
      title: "Company Title",
      dataIndex: "companyTitle",
      render: (companyTitle) => `${companyTitle ? companyTitle : "N/A"}`,
      sorter: {
        compare: Sorter.DEFAULT,
        multiple: 8,
      },
    },
    {
      title: "User Name",
      dataIndex: "fullNameOfOwner",
      sorter: {
        compare: Sorter.DEFAULT,
        multiple: 7,
      },
    },
    {
      title: "Category",
      dataIndex: "companyNature",
      sorter: {
        compare: Sorter.DEFAULT,
        multiple: 6,
      },
    },
    {
      title: "Phone Number",
      dataIndex: "whatsAppNumber",
      sorter: {
        compare: Sorter.DEFAULT,
        multiple: 5,
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: {
        compare: Sorter.DEFAULT,
        multiple: 4,
      },
    },
    {
      title: "Designation",
      dataIndex: "designation",
      sorter: {
        compare: Sorter.DEFAULT,
        multiple: 3,
      },
    },
    {
      title: "Date/ Time",
      dataIndex: "createdAt",
      render: (createdAt) => `${new Date(createdAt).toLocaleDateString()}`,
      sorter: {
        compare: Sorter.DEFAULT,
        multiple: 2,
      },
    },
    {
      title: "City",
      dataIndex: "city",
      render: (city) => `${city ? city : "N/A"}`,
      sorter: {
        compare: Sorter.DEFAULT,
        multiple: 1,
      },
    },
    {
      title: "Company Made",
      dataIndex: "companyMade",
      render: (data, companyMade) => {
        console.log(data, companyMade,"asdflkjasdlfk")
        return (
          <div>
            {data ? (
              "Company Already Made"
            ) : (
              <>
                <Button
                  type="primary"
                    onClick={() => {
                    setEditScreenData({
                      password: Math.random().toString(36).slice(-12),
                      companyMakeId: companyMade?._id,
                      name: companyMade.companyTitle,
                      certified: true,
                      logo: "http://test.com",
                      rating: 5,
                      address: companyMade.city,
                      email: companyMade?.email,
                      city: companyMade?.city,
                      category: companyMade.companyNature,
                      companyRepresentative: [
                        {
                          name: companyMade?.fullNameOfOwner,
                          phoneNumbers: [companyMade?.whatsAppNumber],
                          whatsappNumber: `+92${companyMade?.whatsAppNumber}`,
                          designation: "",
                          bankDetail: [
                            {
                              bankName: "",
                              accountNo: "",
                            },
                          ],
                        },
                      ],
                    });
                    setOpenNow(true);
                  }}
                >
                  Make Company
                </Button>
                <Button
                  style={{ marginTop: "5px" }}
                  type="primary"
                  onClick={async () => {
                    try {
                      const confirm = window.confirm(
                        "Are you sure you want to delete",
                      );
                      if (confirm) {
                        await deleteCompanyRequest(token, {
                          id: companyMade?._id,
                        });
                        setDeleteCheck(!deleteCheck);
                      }
                    } catch (err) {}
                  }}
                >
                  Delete Request
                </Button>
              </>
            )}{" "}
          </div>
        );
      },
    },
  ];

  const createAdminOrSubAdminFunc = async (values) => {
    try {
      console.log(values);
      const u = await authAxios.post(
        "/admin/api/v1/admin/createAdminOrSubAdmin",
        {
          name: values.companyRepresentative[0].name,
          email: values.email,
          password: values.password,
          phoneNumber: `${values.companyRepresentative[0].whatsappNumber}`,
          city: values.city,
          type: "ADMIN",
          permissions: ["e-commerce:commerce_tab"],
        },
      );
      console.log(u);
    } catch (err) {
      throw err;
    }
  };
  const [category, setCategory] = useState(null);

  useEffect(() => {
    (async () => {
      getCategoriesRequest();
      const data = await getInterestedCompany(token, {
        page: page,
        companyMade: filterType,
        endDate,
        startDate,
        category,
      });
      setInterestCompany(data.data);
      setData(data.data.docs);
    })();
  }, [token, page, category, deleteCheck, filterType, openNow]);

  const [categories, setCategories] = useState(null);

  const getCategoriesRequest = async () => {
    const data = await getCategories(token, {});

    setCategories(data.data);
  };
  const [step, setStep] = useState(1);


  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
      >
        <SideDrawer
          openNow={openNow}
          setOpenNow={setOpenNow}
          clearState={setEditScreenData}
          textButton={"Make Company"}
        >
         {openNow? <FormComponent
            step={step}
            setStep={setStep}
            setOpenNow={setOpenNow}
            categories={categories}
            editScreenData={editScreenData}
            token={token}
            createAdminOrSubAdmin={createAdminOrSubAdminFunc}
          />:<></>}
        </SideDrawer>

        <Row justify="end" gutter={[16, 16]}>
          <Col style={{ margin: 10 }}>
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Category"
              optionFilterProp="children"
              onChange={setCategory}
              value={category}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option key={"23423"} value={null}>
                {"All Company"}
              </Option>
              {[
                "Medicine or Vaccine company",
                "Equipment company",
                "Feed Company",
                "Chick Company",
                "Other",
              ]?.map((cate) => (
                <Option key={cate} value={cate}>
                  {cate}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col xs={24} sm={24} lg={24} xl={24} style={{ marginBottom: "20px" }}>
            <Table
              columns={columns}
              pagination={false}
              dataSource={data}
              loading={loading}
              style={{ overflowX: "scroll" }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                marginTop: "20px",
              }}
            >
              <CustomPagination
                pageSize={companies?.limit}
                current={companies.page}
                total={companies?.totalDocs}
                onPageChange={(p) => setPage(p)}
              />
            </div>
          </Col>
          {/* <Col
            xs={24}
            sm={24}
            lg={6}
            xl={6}
            style={{
              padding: 0,
              background: "#fff",
              boxShadow: "0px 12px 23px rgba(62, 73, 84, 0.04)",
              marginBottom: "20px",
            }}
          >
            <AsideCategory />
          </Col> */}
        </Row>
      </div>
    </div>
  );
}
