import jwt from "jsonwebtoken";

const TOKEN_KEY = 'jwt_admin';

export const setToken = (token) => {
    localStorage.setItem(TOKEN_KEY, token);
}

export const deleteToken = () => {
    localStorage.removeItem(TOKEN_KEY);
}

export const getToken = () => {
    if (localStorage.getItem(TOKEN_KEY)) {
        return true;
    }
    return false;
}

export const verifyToken = () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (!token) {
        return false;
    }
    const decodedToken = jwt.decode(token);
    const currentTime = Date.now() / 1000;
    if (decodedToken?.exp > currentTime) {
        return true;
    }
    return false;
}