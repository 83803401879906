import { Col, Row } from "antd";
import React from "react";
import '../crm.css'

export default function StatDashboard({ setStatDetailModal, monthCommission }) {
  return (
    <>
      <div>
        <Row gutter={[8, 8]}>
          <Col xs={24} sm={24} md={12} lg={6}>
            <div
              className="crm_block_price"
              onClick={() => setStatDetailModal(["Download"])}
            >
              <div>
                <h3 className="div_text">Download Score</h3>

                {(() => {
                  let amount = monthCommission.find(
                    (com) => com._id === "Download",
                  );
                  return (
                    <span className="price_div">
                      {" "}
                      RS.
                      {amount ? amount.sumQuantity.toFixed(0) : 0}{" "}
                    </span>
                  );
                })()}
              </div>

              <div className="crm_rates_count">
                {(() => {
                  const quantity = monthCommission.find(
                    (com) => com._id === "Download",
                  );
                  return quantity ? quantity.quantity : 0;
                })()}
              </div>
            </div>
          </Col>

          {/* <Col xs={24} sm={24} md={12} lg={6}>
            <div
              className="crm_block_price"
              onClick={() =>
                setStatDetailModal([
                  "Grain Market",
                  "Poultry Bazar",
                  "Poultry Market",
                  "Pets Point",
                  "Meri Zameen",
                  "Meri Rozi",
                ])
              }
            >
              <div>
                <h3 className="div_text">Post Score</h3>

                {(() => {
                  let amount = 0;
                  for (let com of monthCommission) {
                    if (
                      com._id === "Grain Market" ||
                      com._id === "Poultry Bazar" ||
                      com._id === "Poultry Market" ||
                      com._id === "Pets Point" ||
                      com._id === "Meri Zameen" ||
                      com._id === "Meri Rozi"
                    ) {
                      amount += com.sumQuantity;
                    }
                  }
                  return (
                    <span className="price_div">
                      RS.
                      {amount ? amount?.toFixed(0) : 0}
                    </span>
                  );
                })()}
              </div>

              <div className="crm_rates_count">
                {(() => {
                  let postQuantity = 0;
                  for (let com of monthCommission) {
                    if (
                      com._id === "Grain Market" ||
                      com._id === "Poultry Bazar" ||
                      com._id === "Poultry Market" ||
                      com._id === "Pets Point" ||
                      com._id === "Meri Zameen" ||
                      com._id === "Meri Rozi"
                    ) {
                      postQuantity += com.quantity;
                    }
                  }

                  return postQuantity ? postQuantity : 0;
                })()}
              </div>
            </div>
          </Col> */}

          {/* <Col xs={24} sm={24} md={12} lg={6}>
            <div
              className="crm_block_price"
              onClick={() => setStatDetailModal(["Review"])}
            >
              <div>
                <h3 className="div_text">Review Score</h3>

                {(() => {
                  let amount = monthCommission.find(
                    (com) => com._id === "Review",
                  );
                  return (
                    <span className="price_div">
                      {" "}
                      RS.
                      {amount ? amount.sumQuantity.toFixed(0) : 0}{" "}
                    </span>
                  );
                })()}
              </div>

              <div className="crm_rates_count">
                {(() => {
                  const quantity = monthCommission.find(
                    (com) => com._id === "Review",
                  );
                  return quantity ? quantity.quantity : 0;
                })()}
              </div>
            </div>
          </Col> */}

          <Col xs={24} sm={24} md={12} lg={6}>
            <div
              className="crm_block_price"
              // onClick={() => setStatDetailModal()}
            >
              <div>
                <h3 className="div_text">Subscription Score</h3>

                {(() => {
                  // let amount = monthCommission.find(
                  //   (com) => com._id === "Download",
                  // );
                  return (
                    <span className="price_div">
                      {" "}
                      RS. 0
                      {/* {amount ? amount.sumQuantity.toFixed(0) : 0}{" "} */}
                    </span>
                  );
                })()}
              </div>

              <div className="crm_rates_count">
                {(() => {
                  // const quantity = monthCommission.find(
                  //   (com) => com._id === "Download",
                  // );
                  return 0;
                })()}
              </div>
            </div>
          </Col>

          <Col xs={24} sm={24} md={12} lg={6}>
            <div
              className="crm_block_price"
              onClick={() => setStatDetailModal(["MURGHI MANDI"])}
            >
              <div>
                <h3 className="div_text">Murghi Score</h3>

                {(() => {
                  let amount = monthCommission.find(
                    (com) => com._id === "MURGHI MANDI",
                  );
                  return (
                    <span className="price_div">
                      {" "}
                      RS.
                      {amount ? amount.sumQuantity.toFixed(0) : 0}{" "}
                    </span>
                  );
                })()}
              </div>

              <div className="crm_rates_count">
                {(() => {
                  const quantity = monthCommission.find(
                    (com) => com._id === "MURGHI MANDI",
                  );
                  return quantity ? quantity.quantity : 0;
                })()}
              </div>
            </div>
          </Col>

          {/* <Col xs={24} sm={24} md={12} lg={6}>
            <div
              className="crm_block_price"
              onClick={() => setStatDetailModal(["Pets Point"])}
            >
              <div>
                <h3 className="div_text">Pets Point</h3>

                {(() => {
                  let amount = monthCommission.find(
                    (com) => com._id === "Pets Point",
                  );
                  return (
                    <span className="price_div">
                      {" "}
                      RS.
                      {amount ? amount.sumQuantity.toFixed(0) : 0}{" "}
                    </span>
                  );
                })()}
              </div>

              <div className="crm_rates_count">
                {(() => {
                  const quantity = monthCommission.find(
                    (com) => com._id === "Pets Point",
                  );
                  return quantity ? quantity.quantity : 0;
                })()}
              </div>
            </div>
          </Col> */}

          {/* <Col xs={24} sm={24} md={12} lg={6}>
            <div
              className="crm_block_price"
              onClick={() => setStatDetailModal(["Poultry Bazar"])}
            >
              <div>
                <h3 className="div_text">Poultry Bazar</h3>

                {(() => {
                  let amount = monthCommission.find(
                    (com) => com._id === "Poultry Bazar",
                  );
                  return (
                    <span className="price_div">
                      {" "}
                      RS.
                      {amount ? amount.sumQuantity.toFixed(0) : 0}{" "}
                    </span>
                  );
                })()}
              </div>

              <div className="crm_rates_count">
                {(() => {
                  const quantity = monthCommission.find(
                    (com) => com._id === "Poultry Bazar",
                  );
                  return quantity ? quantity.quantity : 0;
                })()}
              </div>
            </div>
          </Col> */}

          <Col xs={24} sm={24} md={12} lg={6}>
            <div
              className="crm_block_price"
              onClick={() => setStatDetailModal(["Poultry Market"])}
            >
              <div>
                <h3 className="div_text">Poultry Plaza</h3>

                {(() => {
                  let amount = monthCommission.find(
                    (com) => com._id === "Poultry Market",
                  );
                  return (
                    <span className="price_div">
                      {" "}
                      RS.
                      {amount ? amount.sumQuantity.toFixed(0) : 0}{" "}
                    </span>
                  );
                })()}
              </div>

              <div className="crm_rates_count">
                {(() => {
                  const quantity = monthCommission.find(
                    (com) => com._id === "Poultry Market",
                  );
                  return quantity ? quantity.quantity : 0;
                })()}
              </div>
            </div>
          </Col>

          {/* <Col xs={24} sm={24} md={12} lg={6}>
            <div
              className="crm_block_price"
              onClick={() => setStatDetailModal(["Grain Market"])}
            >
              <div>
                <h3 className="div_text">Grain Market</h3>

                {(() => {
                  let amount = monthCommission.find(
                    (com) => com._id === "Grain Market",
                  );
                  return (
                    <span className="price_div">
                      {" "}
                      RS.
                      {amount ? amount.sumQuantity.toFixed(0) : 0}{" "}
                    </span>
                  );
                })()}
              </div>

              <div className="crm_rates_count">
                {(() => {
                  const quantity = monthCommission.find(
                    (com) => com._id === "Grain Market",
                  );
                  return quantity ? quantity.quantity : 0;
                })()}
              </div>
            </div>
          </Col> */}

          <Col xs={24} sm={24} md={12} lg={6}>
            <div className="crm_block_price">
              <div>
                <h3 className="div_text">E commence Score</h3>

                {(() => {
                  // let amount = monthCommission.find(
                  //   (com) => com._id === "Download",
                  // );
                  return <span className="price_div"> RS. 0</span>;
                })()}
              </div>

              <div className="crm_rates_count">
                {/* {(() => {
                    const quantity = monthCommission.find(
                      (com) => com._id === "Download",
                    );
                    return quantity ? quantity.quantity : 0;
                  })()} */}
                0
              </div>
            </div>
          </Col>

          <Col xs={24} sm={24} md={12} lg={6}>
            <div
              className="crm_block_price"
              onClick={() => setStatDetailModal(["FOORI ZRORRAT"])}
            >
              <div>
                <h3 className="div_text">Fori Zarorat Score</h3>

                {(() => {
                  let amount = monthCommission.find(
                    (com) => com._id === "FOORI ZRORRAT",
                  );
                  return (
                    <span className="price_div">
                      {" "}
                      RS.
                      {amount ? amount.sumQuantity.toFixed(0) : 0}{" "}
                    </span>
                  );
                })()}
              </div>

              <div className="crm_rates_count">
                {(() => {
                  const quantity = monthCommission.find(
                    (com) => com._id === "FOORI ZRORRAT",
                  );
                  return quantity ? quantity.quantity : 0;
                })()}
              </div>
            </div>
          </Col>

          <Col xs={24} sm={24} md={12} lg={6}>
            <div
              className="crm_block_price"
              onClick={() => setStatDetailModal(["Hazar janab"])}
            >
              <div>
                <h3 className="div_text">Hazar janab Score</h3>

                {(() => {
                  let amount = monthCommission.find(
                    (com) => com._id === "Hazar janab",
                  );
                  return (
                    <span className="price_div">
                      {" "}
                      RS.
                      {amount ? amount.sumQuantity.toFixed(0) : 0}{" "}
                    </span>
                  );
                })()}
              </div>

              <div className="crm_rates_count">
                {(() => {
                  const quantity = monthCommission.find(
                    (com) => com._id === "Hazar janab",
                  );
                  return quantity ? quantity.quantity : 0;
                })()}
              </div>
            </div>
          </Col>
        </Row>
        <Row align="middle">
        <div
          style={{
            borderBottom: "2px",
            margin: "20px",
            borderBottomColor: "#FCB539",
            borderStyle: "solid",
            marginBottom: "20px",
          }}
        ></div>
        </Row>
        
      </div>
    </>
  );
}
