import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  createProductServiceApi,
  updateProductServiceApi,
} from "../../../../RestServices/ecommerenceService";
import "./addform.css";
import * as moment from "moment";
import ProductDetailForm from "./productDetailForm";
const initialState = {
  companyId: "",
  availablePaymentMethods: ["ONLINE"],
  name: "",
  description: "",
  quantity: 0,
  discount: 0,
  priceSettle: false,
  productCategory: "New Arrival",
  pictures: [],
  defaultImage: [],
  availability: false,
  price: 0,
  consumerValue: 0,
  poultryBabaCommission: 0,
  companyTypeCategory: "",
  category: "",
  subCategory: "",
  city: "",
  // division: "",
  // province: "",
  // district: "",
  division: "",
  province: "",
  district: "",
  specialRemarks: "",
  extraDescription: "",
  mixtureContain: "",
  forms: "",
  made: "",
  size: "",
  discountWithCard: 0,
  dealer: [],
  expiryDate: new Date(moment().add("day", 30)),
  manufactureDate: new Date(),
};
const FormProductComponent = ({
  activeTab,
  setActiveTab,
  setOpenNow,
  productDetails,
  getProduct,
}) => {
  const [createProduct, setCreateProduct] = useState({
    ...initialState,
    ...productDetails,
  });

  const { token } = useSelector((state) => state.Authentication.user);
  const [loadingButton, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const createProductApi = async () => {
    try {
      setLoading(true);
      if (createProduct.hasOwnProperty("_id")) {
        const {
          deletedAt,
          division,
          province,
          defaultImage,
          district,
          ...rest
        } = createProduct;
        await updateProductServiceApi(
          { ...rest, defaultImage: defaultImage ?? undefined },
          token,
        );
      } else {
        await createProductServiceApi(
          {
            ...createProduct,
            availability: true,
          },
          token,
        );
      }
      if (productDetails) {
        return;
      }
      getProduct();
      setCreateProduct({ ...initialState });
      setOpenNow(false);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  // console.log(productDetails, "productDetails");
  // console.log(getProduct, "get Product");

  return (
    <Row justify="center" align="middle" style={{ marginTop: "8px" }}>
      <Col span={24}>
        <ProductDetailForm
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          createProduct={createProduct}
          setCreateProduct={setCreateProduct}
          step={step}
          createProductApi={createProductApi}
          setStep={setStep}
          loadingButton={loadingButton}
          setOpenNow={setOpenNow}
        />
      </Col>
    </Row>
  );
};

export default FormProductComponent;
