import { Card, Col, Image, Modal, Row } from "antd";
import React from "react";
import "./dealer.css";
import StarDisplay from "../../Utils/StarDisplay";

export default function DealerDetailModal({ dealer, open, setOpen }) {
  let dealerData = [];
  dealerData.push(dealer);
  return (
    <Modal
      className="ant-modal-dealer"
      title="Dealer Details"
      centered
      visible={open}
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      width={"76%"}
    >
      <Row>
        <Col span={11} style={{ borderRight: "1px solid #e4e4e4" }}>
          <Row justify="center">
            <Col span={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Image
                  style={{
                    borderRadius: "50%",
                    width: "150px",
                    height: "150px",
                    border: "5px solid #ffa200",
                  }}
                  src={dealer?.logo}
                  alt={dealer?.name}
                />
              </div>
            </Col>
          </Row>
          <div className="modal-styles">
            <Row justify="center" style={{ textAlign: "center" }}>
              <Col span={24}>
                <Card title={dealer?.name} bordered={false}>
                  {dealer?.description}
                </Card>
              </Col>
            </Row>
          </div>
          <div className="modal-styles modal-info">
            <Row justify="center" style={{ textAlign: "center" }}>
              <Col span={24}>
                <Card title="Additional Info " bordered={false}>
                  <Row style={{ marginBottom: "8px" }} gutter={10}>
                    <Col span={12}>
                      <div>
                        <span className="ai-heading">Phone Number: </span>
                        <span style={{ textTransform: "capitalize" }}>
                          {dealer?.phoneNumber}
                        </span>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div>
                        <span className="ai-heading">whatsappNumber: </span>
                        <span style={{ textTransform: "capitalize" }}>
                          {dealer?.whatsappNumber}
                        </span>
                      </div>
                    </Col>
                  </Row>

                  <Row style={{ marginBottom: "8px" }} gutter={10}>
                    <Col span={12}>
                      <div>
                        <span className="ai-heading">City: </span>
                        <span style={{ textTransform: "capitalize" }}>
                          {dealer?.city}
                        </span>
                      </div>
                    </Col>
                  </Row>

                  <Row style={{ marginBottom: "8px" }} gutter={10}>
                    <Col span={24}>
                      <div>
                        <p className="ai-heading"> Business Address: </p>
                        <span style={{ textTransform: "capitalize" }}>
                          {dealer?.businessAddress}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </div>
        </Col>
        <Col span={13} style={{ paddingLeft: "10px" }}>
          <div className="modal-styles">
            <Row justify="center" style={{ textAlign: "center" }}>
              <Col span={24}>
                <Card title="Company Details" bordered={false}>
                  {dealer?.companyId ? (
                    <>
                      <Row gutter={[10, 20]}>
                        <Col span={8}>
                          <Image
                            style={{
                              borderRadius: "12px",
                              width: "120px",
                              height: "120px",
                              border: "3px solid #e9e9e9",
                              marginTop: "10px",
                            }}
                            src={dealer?.companyId?.logo}
                            alt={dealer?.companyId?.name}
                          />
                        </Col>
                        <Col
                          span={16}
                          style={{ paddingLeft: "10px", paddingTop: "5px" }}
                        >
                          <div
                            style={{
                              fontSize: "15px",
                              fontWeight: "600",
                              paddingBottom: "5px",
                            }}
                          >
                            {dealer?.companyId?.name}
                          </div>
                          <div style={{ paddingBottom: "10px" }}>
                            {dealer?.companyId?.description}
                          </div>
                        </Col>
                      </Row>
                      <Row style={{ padding: "20px 0" }}>
                        <Col span={24}>
                          <Row style={{ marginBottom: "5px" }}>
                            <Col span={12}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "flex-start",
                                }}
                              >
                                <span className="ai-heading">Rating: </span>
                                <span>
                                  <StarDisplay
                                    rating={dealer?.companyId?.rating}
                                  />
                                </span>
                              </div>
                            </Col>
                            <Col span={12}>
                              <div>
                                <span className="ai-heading">Category: </span>
                                <span style={{ textTransform: "capitalize" }}>
                                  {dealer?.companyId?.category}
                                </span>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={12}>
                              <div>
                                <span className="ai-heading">City: </span>
                                <span style={{ textTransform: "capitalize" }}>
                                  {dealer?.companyId?.city}
                                </span>
                              </div>
                            </Col>
                            <Col span={12}>
                              <div>
                                <span className="ai-heading">Address: </span>
                                <span style={{ textTransform: "capitalize" }}>
                                  {dealer?.companyId?.address}
                                </span>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <>No Record Found</>
                  )}
                </Card>
              </Col>
            </Row>
          </div>

          <div className="modal-styles">
            <Row justify="center" style={{ textAlign: "center" }}>
              <Col span={24}>
                <Card title="Company Representative Details" bordered={false}>
                  {dealer?.companyId ? (
                    <>
                      {dealer?.companyId?.companyRepresentative?.map((crd) => (
                        <>
                          <Row>
                            <Col span={12}>
                              <div>
                                <span className="ai-heading ai-heading-sm">
                                  Name:{" "}
                                </span>
                                <span style={{ textTransform: "capitalize" }}>
                                  {crd?.name}
                                </span>
                              </div>
                            </Col>
                            <Col span={12}>
                              <div>
                                <span className="ai-heading ai-heading-sm">
                                  Phone:{" "}
                                </span>
                                <span style={{ textTransform: "capitalize" }}>
                                  {crd?.phoneNumbers[0]}
                                </span>
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col span={12}>
                              <div>
                                <span className="ai-heading ai-heading-sm">
                                  Whatsapp:{" "}
                                </span>
                                <span style={{ textTransform: "capitalize" }}>
                                  {crd?.whatsappNumber}
                                </span>
                              </div>
                            </Col>
                            <Col span={12}>
                              <div>
                                <span className="ai-heading ai-heading-sm">
                                  Designation:{" "}
                                </span>
                                <span style={{ textTransform: "capitalize" }}>
                                  {crd?.designation}
                                </span>
                              </div>
                            </Col>
                          </Row>
                          {crd?.bankDetail && (
                            <>
                              {crd?.bankDetail?.map((cbd) => (
                                <Row>
                                  <Col span={12}>
                                    <div>
                                      <span className="ai-heading ai-heading-sm">
                                        Bank Name:{" "}
                                      </span>
                                      <span
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        {cbd?.bankName}
                                      </span>
                                    </div>
                                  </Col>
                                  <Col span={12}>
                                    <div>
                                      <span className="ai-heading ai-heading-sm">
                                        Account #:{" "}
                                      </span>
                                      <span
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        {cbd?.accountNo}
                                      </span>
                                    </div>
                                  </Col>
                                </Row>
                              ))}
                            </>
                          )}
                        </>
                      ))}
                    </>
                  ) : (
                    <>No Record Found</>
                  )}
                </Card>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Modal>
  );
}
