import { Typography, Col } from "antd";
import React from "react";

export default function RowStatus({ field, value }) {
  return (
    <Col className="gutter-row" span={12}>
      <Typography.Title style={{ width: "100%" }} level={5}>
        {field}
      </Typography.Title>
      <Typography.Text style={{ width: "100%" }}>{value}</Typography.Text>
    </Col>
  );
}
