import { postingService } from "../shared";
import axios from "axios";

export const createRateList = async ({ rateLists }, token) => {
  const uri = "/api/v1/admin/rateList/createRateList";
  const resp = await axios.post(
    postingService + uri,
    { rateLists },
    {
      headers: {
        Authorization: "bearer " + token,
        "api-key": "poultrybaba@1234Sumandri",
      },
    },
  );
  return resp.data;
};

export const getCommodityRate = async (product, province, token) => {
  const uri =
    "/api/v1/admin/commodityRate/commodityRateToday?product=" +
    product +
    "&province=" +
    province;
  const resp = await axios.get(postingService + uri, {
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp;
};

export const getCommodityStatCount = async (startDate, endDate, token) => {
  const uri =
    "/api/v1/admin/commodityStat/commodityStat?startDate=" +
    startDate +
    "&endDate=" +
    endDate;
  const resp = await axios.get(postingService + uri, {
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp;
};

export const getPostStat = async (
  startDate,
  endDate,
  status,
  token,
  userId,
) => {
  const uri = `/api/v1/admin/posts/getCountMurgiMandi?startDate=${startDate}&endDate=${endDate}&status=${status}${userId ? `&userId=${userId}` : ""
    }`;

  const resp = await axios.get(postingService + uri, {
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp;
};

export const createCommodityRate = async (rateLists, token) => {
  const uri = "/api/v1/admin/commodityRate/commodityRateToday";
  const resp = await axios.post(postingService + uri, rateLists, {
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp;
};

export const createRateTimeString = async (payload, token) => {
  const uri = "/api/v1/admin/rateTime/rateTime";
  const resp = await axios.post(postingService + uri, payload, {
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp;
};

export const createPoster = async (payload, token) => {
  const uri = "/api/v1/admin/posters/createPoster";
  const resp = await axios.post(postingService + uri, payload, {
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp;
};

export const editPoster = async (payload, token) => {
  const uri = "/api/v1/admin/posters/updatePoster";
  const resp = await axios.post(postingService + uri, payload, {
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp;
};

export const getCommissionRequest = async (params, token) => {
  const uri = "/api/v1/admin/commission/commission";
  const resp = await axios.get(postingService + uri, {
    params: { ...params },
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp.data;
};

export const getPosters = async ({ category, displayType }, token) => {
  const uri = "/api/v1/admin/posters/getPosters";
  const resp = await axios.get(postingService + uri, {
    params: { category, displayType },
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp.data;
};

export const createCommission = async (payload, token) => {
  const uri = "/api/v1/admin/commission/commissionRate";
  const resp = await axios.post(postingService + uri, payload, {
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp;
};
export const getCommisionList = async (params, token) => {
  const uri = `/api/v1/admin/commission/commission`;
  const resp = await axios.get(postingService + uri, {
    params: {
      ...params,
    },
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp.data;
};

export const getCommissionsAmount = async (payload, token) => {
  const uri = "/api/v1/admin/commission/commissionForClient";
  const resp = await axios.get(postingService + uri, {
    params: payload,
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp?.data?.results;
};

export const getCommissionForAllAdmin = async (payload, token) => {
  const uri = "/api/v1/admin/commission/commissionForAllAdmin";
  const resp = await axios.get(postingService + uri, {
    params: payload,
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp?.data?.results;
};

export const getCommissionForDownandRev = async (payload, token) => {
  const uri = "/api/v1/admin/commission/commissionForDownloadAndRev";
  const resp = await axios.post(
    postingService + uri,
    {
      ...payload,
    },
    {
      headers: {
        Authorization: "bearer " + token,
        "api-key": "poultrybaba@1234Sumandri",
      },
    },
  );
  return resp.data;
};

export const deleteCommission = async (payload, token) => {
  const uri = "/api/v1/admin/commission/deleteCommissionRate";
  const resp = await axios.post(postingService + uri, payload, {
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp;
};

export const getCommissions = async (payload, token) => {
  const uri = "/api/v1/admin/commission/commissionRate";
  const resp = await axios.get(postingService + uri, {
    params: payload,
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp.data;
};

export const editCommision = async (payload, token) => {
  const uri = "/api/v1/admin/commission/editCommissionRate";
  const resp = await axios.post(postingService + uri, payload, {
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp;
};

export const updateRateTimeString = async (id, payload, token) => {
  const uri = `/api/v1/admin/rateTime/rateTime/${id}`;
  const resp = await axios.patch(postingService + uri, payload, {
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp;
};

export const getRateTimeString = async ({ province, product }, token) => {
  const uri = `/api/v1/admin/rateTime/rateTime?province=${province}&product=${product}`;
  const resp = await axios.get(postingService + uri, {
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp;
};
export const updateCommodityRate = async (id, rateLists, token) => {
  const uri = `/api/v1/admin/commodityRate/commodityRateToday/${id}`;
  const resp = await axios.patch(postingService + uri, rateLists, {
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp;
};

export const deleteCommodityRate = async (id, token) => {
  const uri = `/api/v1/admin/commodityRate/commodityRateToday/${id}`;
  const resp = await axios.delete(postingService + uri, {
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp;
};

export const getRates = async ({ type, city, date, pageNo }, token) => {
  const uri = "/api/v1/admin/rateList/getRates";
  const resp = await axios.get(postingService + uri, {
    params: { type, city, date, pageNo },
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp.data;
};

export const getPostCount = async (
  { startDate, endDate, type, city },
  token,
) => {
  const uri = "/api/v1/admin/posts/getPostCount";
  const resp = await axios.get(postingService + uri, {
    params: { startDate, endDate, type, city },
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp.data;
};

export const getPosts = async (token, payload) => {
  const uri = "/api/v1/admin/posts/getPosts";
  const resp = await axios.get(postingService + uri, {
    params: { ...payload, limit: 20 },
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp.data;
};

export const expireThePost = async ({ postId }, token) => {
  const uri = "/api/v1/admin/posts/expireThePost";
  const resp = await axios.post(
    postingService + uri,
    { postId },
    {
      headers: {
        Authorization: "bearer " + token,
        "api-key": "poultrybaba@1234Sumandri",
      },
    },
  );
  return resp.data;
};

export const activeThePost = async ({ postId }, token) => {
  const uri = "/api/v1/admin/posts/activeThePost";
  const resp = await axios.post(
    postingService + uri,
    { postId },
    {
      headers: {
        Authorization: "bearer " + token,
        "api-key": "poultrybaba@1234Sumandri",
      },
    },
  );
  return resp.data;
};

export const getCommodityTopFan = async (
  { startDate, endDate, page, limit },
  token,
) => {
  const uri = "/api/v1/admin/commodityStat/commodityTopFans";
  const resp = await axios.get(postingService + uri, {
    params: {
      startDate,
      endDate,
      page,
      limit,
    },

    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp.data;
};

export const CommodityGraphData = async (
  { startDate, endDate, city, limit },
  token,
) => {
  const uri = "/api/v1/admin/commodityStat/CommodityGraphData";
  const resp = await axios.get(postingService + uri, {
    params: {
      startDate,
      endDate,
      city,
      limit,
    },

    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp.data;
};

export const customPayment = async (payload, token) => {
  const uri = "/api/v1/admin/payment/receivedPayment";
  const resp = await axios.post(
    postingService + uri,
    { ...payload },
    {
      headers: {
        Authorization: "bearer " + token,
        "api-key": "poultrybaba@1234Sumandri",
      },
    },
  );
  return resp.data;
};
//
export const getMurgiStatApi = async (payload, token) => {
  const uri = "/api/v1/admin/posts/postStatOfMurgiMandi";
  const resp = await axios.get(postingService + uri, {
    params: { ...payload },
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp.data;
};

export const getBiddings = async (payload, token) => {
  const uri = "/api/v1/admin/bidding/getPostBiddings";
  const resp = await axios.get(postingService + uri, {
    params: {
      ...payload,
    },
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp.data;
};

export const getStatBidding = async (payload, token) => {
  const uri = "/api/v1/admin/bidding/getPostBiddingsStat";
  const resp = await axios.get(postingService + uri, {
    params: {
      ...payload,
    },
    headers: {
      Authorization: "bearer " + token,
      "api-key": "poultrybaba@1234Sumandri",
    },
  });
  return resp.data;
};
