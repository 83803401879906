import React from "react";

const StarDisplay = ({ rating }) => {
  const stars = parseInt(rating, 10);

  const starElements = [];
  for (let i = 0; i < stars; i++) {
    starElements.push(stars);
  }

  return (
    <div  style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
      {starElements.length > 0 ? (
        starElements.map((element, index) => (
            <div
          key={index}
          style={{ paddingRight: "2px" }}
        >
          <svg
            width="13"
            height="12"
            viewBox="0 0 13 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.62296 0.866507L8.76684 2.99572C8.92282 3.29212 9.33877 3.57642 9.68974 3.63086L11.763 3.95145C13.0889 4.15712 13.4008 5.05236 12.4454 5.9355L10.8336 7.43563C10.5606 7.68968 10.4112 8.17964 10.4956 8.53048L10.9571 10.3875C11.3211 11.8574 10.4826 12.426 9.0853 11.6578L7.14202 10.5871C6.79106 10.3935 6.21262 10.3935 5.85516 10.5871L3.91187 11.6578C2.52103 12.426 1.67612 11.8513 2.04008 10.3875L2.50153 8.53048C2.58602 8.17964 2.43654 7.68968 2.16357 7.43563L0.551745 5.9355C-0.39715 5.05236 -0.0916836 4.15712 1.23417 3.95145L3.30744 3.63086C3.6519 3.57642 4.06786 3.29212 4.22384 2.99572L5.36771 0.866507C5.99164 -0.288836 7.00553 -0.288836 7.62296 0.866507Z"
              fill="#FCB539"
            />
          </svg>
        </div>
        ))
      ) : (
        <></>
      )}
    </div>
  );
};

export default StarDisplay;
