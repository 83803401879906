import { Button, Col, Drawer, Row, Typography } from "antd";
import React from "react";

export const SideDrawer = ({
  openNow,
  clearState,
  setOpenNow,
  children,
  text,
  textButton,
}) => {
  return (
    <>
      <Row justify="end">
        <Col>
          <Button
            style={{ margin: "20px" }}
            type="primary"
            onClick={() => {
              setOpenNow(true);
              clearState && clearState();
            }}
          >
            {textButton}
          </Button>
          <Typography
            style={{ display: "inline", fontWeight: "bold", fontSize: "20px" }}
          >
            {text}
          </Typography>
        </Col>
      </Row>
      <Drawer
        title={textButton}
        placement="right"
        width={380}
        closable={true}
        onClose={() => setOpenNow(false)}
        visible={openNow}
      >
        {children}
      </Drawer>
    </>
  );
};
