import { SearchOutlined } from "@material-ui/icons";
import { Badge, Col, DatePicker, Input, Row, Tabs, Dropdown, Space, Menu, Spin, Typography, Image } from "antd";
import { BsBag, BsChevronDoubleDown } from 'react-icons/bs';
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Select } from "antd";

import { getCommissionsAmount } from "../../RestServices/postingService";
import { baseUrlOfCommerce, checkPermission } from "../../shared";
import Category from "./Category/Category";
import Company from "./Company/Company";
import CompanyRequest from "./CompanyRequest/CompanyRequst";
import "./crm.css";
import DealerList from "./Dealer/Dealer";
import ECommerceDashboard from "./Ecommerce/Dashboards";
import OrderList from "./Order/OrderList";
import Product from "./Product/Product";
import { getStatRequest } from "../../RestServices/ecommerenceService";
import InqueryOrders from "./InqueryOrders/InqueryOrder";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import NotifyIcon from "../../assets/notification.png";

const { TabPane } = Tabs;
const { Option } = Select;
const { RangePicker } = DatePicker;

export default function ECommerce({ eUser, ...props }) {
  const pusher = window.pusher;

  const [orderNotifications, setOrderNotifications] = useState([]);
  const [inqueryOrderNotifications, setInqueryOrderNotifications] = useState([]);

  useEffect(() => {
    const channel = pusher.subscribe('order-notifications');
    channel.bind('new-orderNotification', (data) => {
      setOrderNotifications((prevNotifications) => [...prevNotifications, data]);
      new Notification('Order notification received.', {
        body: data.orderId,
        icon: NotifyIcon,
      });

      toast(
        <div style={{ paddingLeft: "25px" }}>
          <Typography.Text level={5} style={{ fontSize: "16px", fontWeight: 600, color: "#1D1B1B", marginBottom: 0, }}>Order notification received.</Typography.Text>
          <Typography.Paragraph style={{ fontSize: "14px", fontWeight: 400, color: "#1D1B1B", marginBottom: 0, }}>{data?.orderId}</Typography.Paragraph>
        </div>,
        {
          onClick: () => window.open('https://champions.poultrybaba.com/', '_blank'),
          autoClose: false,
          icon: ({ theme, type }) => (
            <Image
              src={NotifyIcon}
              alt="notify icon"
              style={{ width: "50px", height: "50px" }}
            />
          ),
        },
      );

      const audio = new Audio('../../assets/notification.mp3');
      audio.play();
    });

    return () => {
      pusher.unsubscribe("order-notifications");
    };
  }, []);

  useEffect(() => {
    const channel = pusher.subscribe('inqueryOrder-notifications');
    channel.bind('new-inqueryOrderNotification', (data) => {
      setInqueryOrderNotifications((prevNotifications) => [...prevNotifications, data]);
      new Notification('Enquiry notification received.', {
        body: data.enquiryId,
        icon: NotifyIcon,
      });

      toast(
        <div style={{ paddingLeft: "25px" }}>
          <Typography.Text level={5} style={{ fontSize: "16px", fontWeight: 600, color: "#1D1B1B", marginBottom: 0, }}>Enquiry notification received.</Typography.Text>
          <Typography.Paragraph style={{ fontSize: "14px", fontWeight: 400, color: "#1D1B1B", marginBottom: 0, }}>{data?.enquiryId}</Typography.Paragraph>
        </div>,
        {
          onClick: () => window.open('https://champions.poultrybaba.com/', '_blank'),
          autoClose: false,
          icon: ({ theme, type }) => (
            <Image
              src={NotifyIcon}
              alt="notify icon"
              style={{ width: "50px", height: "50px" }}
            />
          ),
        },
      );

      const audio = new Audio('../../assets/notification.mp3');
      audio.play();
    });

    return () => {
      pusher.unsubscribe("inqueryOrder-notifications");
    };
  }, []);

  const { allcities } = useSelector((state) => state.Cities);

  const [selectedDate, setSelectedDate] = useState(
    moment().format("MMMM YYYY"),
  );

  const [monthCommission, setMonthlyCommission] = useState([]);

  const {
    token,
    _id: userId,
    profileDetails: { permissions },
  } = useSelector((state) => state.Authentication.user);

  const [finalBidForParent] = useState("");
  const [paymentStatusOwner] = useState("");
  const [paymentStatusClient] = useState("");

  const onChangeDatePicker = (date, dateString) => {
    setSelectedDate(date?.format("MMMM YYYY"));
  };

  const [dateRange, setDateRage] = useState({
    startDate: moment(),
    endDate: moment(),
  });

  // INITIAL

  const onChangeTag = (key) => {
    setSelectTab(key);
  };

  const [filterType, setFilterType] = useState(undefined);

  const [selectTab, setSelectTab] = useState("2");

  const [totalStat] = useState(0);

  const [loadingData, setLoadingData] = useState(false);

  const handleRefreshData = () => {
    getCommission();
    getRefreshedData();
  };

  const getOrdersNotifications = async () => {
    const uri = `/api/v1/orders/getOrderNotifications`;
    const { data } = await axios.get(baseUrlOfCommerce + uri, {
      headers: {
        Authorization: "bearer " + token,
        "api-key": "poultrybaba@1234Sumandri",
      },
    });
    console.log(data?.data);
    setOrderNotifications(data?.data);
  }

  const getEnquiryNotifications = async () => {
    const uri = `/api/v1/enquiries/getEnquiryNotifications`;
    const { data } = await axios.get(baseUrlOfCommerce + uri, {
      headers: {
        Authorization: "bearer " + token,
        "api-key": "poultrybaba@1234Sumandri",
      },
    });
    console.log(data?.data);
    setInqueryOrderNotifications(data?.data);
  }

  const getRefreshedData = () => {
    getCommission();
  }

  useEffect(() => {
    getCommission();
    getRefreshedData();
  }, [selectedDate]);

  useEffect(() => {
    getOrdersNotifications();
    getEnquiryNotifications();
  }, []);

  const getCommission = async (taskId) => {
    try {
      setLoadingData(true);
      const data = await getCommissionsAmount(
        {
          referenceOf: checkPermission(permissions, "AllResources")
            ? undefined
            : userId,
          month: selectedDate,
        },
        token,
      );
      setMonthlyCommission(data.commission);
      setLoadingData(false);
    } catch (err) {
      console.log(err);
      setLoadingData(false);
    }
  };

  const [city, setCity] = useState("");

  const dateFormat = "YYYY/MM/DD";

  const [stat, setStat] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const statItem = await getStatRequest(token, {
          startDate: dateRange.startDate.toDate(),
          endDate: dateRange.endDate.toDate(),
          city: city,
        });
        setStat(statItem);
      } catch (err) {
        console.log(err.response);
      }
    })();
  }, [token, city, dateRange]);

  const CustomTab = ({ tab, count, bgColor, MenuItems }) => (
    <div>
      {tab}
      {count > 0 && (
        <>
          <Dropdown overlay={MenuItems} placement="bottomRight">
            <Space>
              <Badge
                count={count}
                style={{ backgroundColor: bgColor, marginLeft: "8px" }}
              />
              <BsChevronDoubleDown size={20} color="#4a0600" />
            </Space>
          </Dropdown>
        </>
      )}
    </div>
  );

  const menuStyle = {
    backgroundColor: 'transparent',
  };

  const menuItemStyle = {
    color: '#f00',
    width: '238px',
    backgroundColor: '#fff',
  };

  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [selectedNotifyId, setSelectedNotifyId] = useState(null);

  const [selectedEnquiryId, setSelectedEnquiryId] = useState(null);
  const [selectedEnquiryNotifyId, setSelectedEnquiryNotifyId] = useState(null);

  const handleOrderSelect = (orderId) => {
    console.log(orderId);
    setSelectedOrderId(orderId);
  };

  const handleNotifySelect = (notifyId) => {
    console.log(notifyId);
    setSelectedNotifyId(notifyId);
  };

  const handleEnquirySelect = (enquiryId) => {
    setSelectedEnquiryId(enquiryId);
  };

  const handleEnquiryNotifySelect = (enquirynotifyId) => {
    setSelectedEnquiryNotifyId(enquirynotifyId);
  };

  return (
    <div style={{ padding: "10px" }}>
      <Row align="center" gutter={[16, 16]} justify="end">
        <Col>
          {loadingData ? (
            <div
              className="refreshButton_button"
            >
              <Spin />
            </div>
          ) : (
            <button
              className="refreshButton_button"
              onClick={handleRefreshData}
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 3C9.72 3 3 9.72 3 18C3 26.28 9.72 33 18 33C26.28 33 33 26.28 33 18C33 9.72 26.28 3 18 3ZM9.9 16.845C10.155 15.06 10.95 13.455 12.195 12.195C15.195 9.21 19.92 9.045 23.13 11.655V10.23C23.13 9.615 23.64 9.105 24.255 9.105C24.87 9.105 25.38 9.615 25.38 10.23V14.235C25.38 14.85 24.87 15.36 24.255 15.36H20.25C19.635 15.36 19.125 14.85 19.125 14.235C19.125 13.62 19.635 13.11 20.25 13.11H21.375C19.05 11.49 15.84 11.715 13.77 13.785C12.87 14.685 12.3 15.855 12.105 17.16C12.03 17.715 11.55 18.12 10.995 18.12C10.935 18.12 10.89 18.12 10.83 18.105C10.245 18.03 9.81 17.46 9.9 16.845ZM23.805 23.805C22.2 25.41 20.1 26.205 18 26.205C16.17 26.205 14.355 25.56 12.855 24.345V25.755C12.855 26.37 12.345 26.88 11.73 26.88C11.115 26.88 10.605 26.37 10.605 25.755V21.75C10.605 21.135 11.115 20.625 11.73 20.625H15.735C16.35 20.625 16.86 21.135 16.86 21.75C16.86 22.365 16.35 22.875 15.735 22.875H14.61C16.935 24.495 20.145 24.27 22.215 22.2C23.115 21.3 23.685 20.13 23.88 18.825C23.97 18.21 24.525 17.775 25.155 17.865C25.77 17.955 26.19 18.525 26.115 19.14C25.845 20.955 25.05 22.56 23.805 23.805Z"
                  fill="white"
                />
              </svg>
              <p className="tearning">Refresh</p>
            </button>
          )}
        </Col>

        <Col>
          <RangePicker
            onChange={([startDate, endDate]) =>
              setDateRage({
                startDate,
                endDate,
              })
            }
            defaultValue={[dateRange.startDate, dateRange.endDate]}
            format={dateFormat}
          />
        </Col>

        <Col>
          <Input
            style={{ borderRadius: "50px" }}
            prefix={<SearchOutlined />}
            className="search"
            type="text"
            placeholder="Search"
          />
        </Col>

        <Col>
          <Select
            className="add_city_picker"
            defaultValue=""
            value={city}
            style={{ width: 120 }}
            onChange={(e) => setCity(e)}
          >
            <Option value="">Select city</Option>
            {allcities?.map((city) => (
              <Option value={city?.en}>{city?.en}</Option>
            ))}
          </Select>
        </Col>
      </Row>
      <div style={{ marginTop: "20px" }}>
        {!eUser ? (
          <ECommerceDashboard
            stat={stat}
            setSelectTab={(tag, filter) => {
              onChangeTag(tag);
              setFilterType(filter);
            }}
            commodity={undefined}
            permissions={permissions}
          />
        ) : (
          <></>
        )}
      </div>
      <Row>
        <Col lg={24} md={24} sm={24} xs={24}>
          {eUser ? (
            <div className="tab-group">
              <Row
                gutter={[16, 16]}
                style={{
                  marginTop: "20px",
                  width: "100%",
                  display: "flex",
                  flexWrap: "nowrap",
                  overflowX: "auto",
                }}
              >
                <Col md={24} sm={24}>
                  <Tabs
                    activeKey={selectTab}
                    onChange={(e) => {
                      onChangeTag(e);
                      setFilterType(undefined);
                    }}
                    defaultActiveKey={"1"}
                  >
                    <TabPane tab="Dealer" key="3">
                      <DealerList
                        finalBidForParent={finalBidForParent}
                        paymentStatusOwner={paymentStatusOwner}
                        paymentStatusClient={paymentStatusClient}
                        userId={userId}
                        token={token}
                        totalStat={totalStat}
                        startDate={dateRange.startDate}
                        endDate={dateRange.endDate}
                        permissions={permissions}
                      />
                    </TabPane>

                    <TabPane tab="Products" key="4">
                      <Product
                        userId={userId}
                        stat={stat}
                        token={token}
                        startDate={dateRange.startDate}
                        endDate={dateRange.endDate}
                        permissions={permissions}
                      />
                    </TabPane>

                    {/* <TabPane tab="Orders" key="5">
                      <OrderList
                        token={token}
                        permissions={permissions}
                        userId={userId}
                        startDate={dateRange.startDate}
                        endDate={dateRange.endDate}
                      />
                    </TabPane> */}
                  </Tabs>
                </Col>

                {/* <Col className="div_back" md={6} sm={24}>
                <Row
                  style={{ borderBottom: "1px solid #F8F8F8", padding: "10px" }}
                >
                  <Col span={24} className="overall">
                    Overall Stat
                  </Col>
                </Row>

                <div
                  style={{
                    borderBottom: "1px solid #F8F8F8",
                    padding: "10px",
                    height: "50vh",
                    overflow: "auto",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {stat?.bidding &&
                    stat?.bidding?.map((value) => (
                      <div className="contain_number">
                        <div className="inner-contain-number">
                          <div className="circle">
                            {((value.count / totalStat) * 100).toFixed(0)}%
                          </div>
                          <div>
                            <h3 className="number_count">{value.count}</h3>
                            <h3 className="number_heading">
                              {value.type.split("_").join(" ")}
                            </h3>
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            if (selectTab !== "2") {
                              setSelectTab("2");
                            }
                            setFinalBidForParent(value.type);
                            setPaymentStatusOwner("");
                            setPaymentStatusClient("");
                          }}
                          className="view_list"
                        >
                          View list
                        </div>
                      </div>
                    ))}

                  {stat?.paidStatus?.length && (
                    <>
                      <div className="contain_number">
                        <div className="inner-contain-number">
                          <div className="circle">
                            {(
                              (stat.paidStatus[0].customerPaid / totalStat) *
                              100
                            ).toFixed(0)}
                            %
                          </div>
                          <div>
                            <h3 className="number_count">
                              {stat.paidStatus[0].customerPaid}
                            </h3>
                            <h3 className="number_heading">Customer Paid</h3>
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            if (selectTab !== "2") {
                              setSelectTab("2");
                            }
                            setFinalBidForParent("");
                            setPaymentStatusOwner("");
                            setPaymentStatusClient("PAID");
                          }}
                          className="view_list"
                        >
                          View list
                        </div>
                      </div>
                      <div className="contain_number">
                        <div className="inner-contain-number">
                          <div className="circle">
                            {(
                              (stat.paidStatus[0].ownerPaid / totalStat) *
                              100
                            ).toFixed(0)}
                            %
                          </div>
                          <div>
                            <h3 className="number_count">
                              {stat.paidStatus[0].ownerPaid}
                            </h3>
                            <h3 className="number_heading">Owner Paid</h3>
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            if (selectTab !== "2") {
                              setSelectTab("2");
                            }
                            setFinalBidForParent("");
                            setPaymentStatusOwner("PAID");
                            setPaymentStatusClient("");
                          }}
                          className="view_list"
                        >
                          View list
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </Col> */}
              </Row>
            </div>
          ) : (
            <div className="tab-group">
              <Row
                gutter={[16, 16]}
                style={{
                  marginTop: "20px",
                  width: "100%",
                  display: "flex",
                  flexWrap: "nowrap",
                  overflowX: "auto",
                }}
              >
                <Col md={24} sm={24}>
                  <Tabs
                    activeKey={selectTab}
                    onChange={(e) => {
                      onChangeTag(e);
                      setFilterType(undefined);
                    }}
                    defaultActiveKey={"2"}
                  >
                    {/* <TabPane tab="Dashboards" key="1">
                      <ECommerceDashboard
                        startDate={dateRange.startDate}
                        endDate={dateRange.endDate}
                        city={city}
                        commodity={undefined}
                        permissions={permissions}
                      />
                    </TabPane> */}
                    <TabPane tab="Company Requests" key="2">
                      <CompanyRequest
                        userId={userId}
                        filterType={filterType ?? false}
                        token={token}
                        startDate={dateRange.startDate}
                        endDate={dateRange.endDate}
                        permissions={permissions}
                      />
                    </TabPane>
                    <TabPane tab="Company Made" key="22">
                      <CompanyRequest
                        userId={userId}
                        filterType={filterType ?? true}
                        token={token}
                        startDate={dateRange.startDate}
                        endDate={dateRange.endDate}
                        permissions={permissions}
                      />
                    </TabPane>

                    <TabPane tab="Companies" key="12">
                      <Company
                        userId={userId}
                        stat={stat}
                        token={token}
                        startDate={dateRange.startDate}
                        endDate={dateRange.endDate}
                        permissions={permissions}
                      />
                    </TabPane>

                    <TabPane tab="Dealer" key="3">
                      <DealerList
                        activeKey={selectTab}
                        finalBidForParent={finalBidForParent}
                        paymentStatusOwner={paymentStatusOwner}
                        paymentStatusClient={paymentStatusClient}
                        userId={userId}
                        token={token}
                        totalStat={totalStat}
                        startDate={dateRange.startDate}
                        endDate={dateRange.endDate}
                        permissions={permissions}
                      />
                    </TabPane>

                    <TabPane tab="Products" key="4">
                      <Product
                        stat={stat}
                        userId={userId}
                        filterType={filterType}
                        token={token}
                        startDate={dateRange.startDate}
                        endDate={dateRange.endDate}
                        permissions={permissions}
                      />
                    </TabPane>

                    <TabPane tab="Category" key="8">
                      <Category
                        userId={userId}
                        token={token}
                        filterType={filterType}
                        startDate={dateRange.startDate}
                        endDate={dateRange.endDate}
                        permissions={permissions}
                      />
                    </TabPane>

                    <TabPane tab={<CustomTab tab="Inquery Orders" count={inqueryOrderNotifications?.length} bgColor={'#ff4d4f'} MenuItems={
                      <Menu style={menuStyle}>
                        {inqueryOrderNotifications?.map((inqueryOrder, index) => (
                          <Menu.Item key={inqueryOrder?._id} style={menuItemStyle} onClick={() => {
                            handleEnquirySelect(inqueryOrder?.enquiryId);
                            handleEnquiryNotifySelect(inqueryOrder?._id);
                          }}>
                            <div style={{ width: "100%", height: "100%" }}>
                              <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <div style={{ marginRight: "10px" }}>
                                  <BsBag size={22} color="red" />
                                </div>
                                <div style={{ display: "flex", justifyContent: "flex-start", flexDirection: "column", alignItems: "center" }}>
                                  <Typography.Paragraph style={{ fontSize: "16px", marginBottom: 0, marginleft: 0, color: "red" }}>New enquiry received.</Typography.Paragraph>
                                  <Typography.Paragraph style={{ fontSize: "12px", marginBottom: 0, marginTop: 0, color: "red" }}>{inqueryOrder?.enquiryId}</Typography.Paragraph>
                                </div>
                              </div>
                            </div>
                          </Menu.Item>
                        ))}
                      </Menu>
                    } />} key="6">
                      <InqueryOrders
                        stat={stat}
                        tab={selectTab}
                        filterType={filterType}
                        token={token}
                        permissions={permissions}
                        userId={userId}
                        startDate={dateRange.startDate}
                        endDate={dateRange.endDate}
                        selectedEnquiryId={selectedEnquiryId}
                        selectedEnquiryNotifyId={selectedEnquiryNotifyId}
                        getEnquiryNotifications={getEnquiryNotifications}
                      />
                    </TabPane>

                    <TabPane tab={<CustomTab tab="Orders" count={orderNotifications?.length} bgColor={'#ff4d4f'} MenuItems={
                      <Menu style={menuStyle}>
                        {orderNotifications?.map((order, index) => (
                          <Menu.Item key={order?._id} style={menuItemStyle} onClick={() => {
                            handleOrderSelect(order?.orderId);
                            handleNotifySelect(order?._id);
                          }}>
                            <div style={{ width: "100%", height: "100%" }}>
                              <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <div style={{ marginRight: "10px" }}>
                                  <BsBag size={22} color="red" />
                                </div>
                                <div style={{ display: "flex", justifyContent: "flex-start", flexDirection: "column", alignItems: "center" }}>
                                  <Typography.Paragraph style={{ fontSize: "16px", marginBottom: 0, marginleft: 0, color: "red" }}>New order received.</Typography.Paragraph>
                                  <Typography.Paragraph style={{ fontSize: "12px", marginBottom: 0, marginTop: 0, color: "red" }}>{order?.orderId}</Typography.Paragraph>
                                </div>
                              </div>
                            </div>
                          </Menu.Item>
                        ))}
                      </Menu>
                    } />} key="5">
                      <OrderList
                        stat={stat}
                        filterType={filterType}
                        token={token}
                        permissions={permissions}
                        userId={userId}
                        startDate={dateRange.startDate}
                        endDate={dateRange.endDate}
                        selectedOrderId={selectedOrderId}
                        selectedNotifyId={selectedNotifyId}
                        getOrdersNotifications={getOrdersNotifications}
                      />
                    </TabPane>
                  </Tabs>
                </Col>

                {/* <Col className="div_back" md={6} sm={24}>
                <Row
                  style={{ borderBottom: "1px solid #F8F8F8", padding: "10px" }}
                >
                  <Col span={24} className="overall">
                    Overall Stat
                  </Col>
                </Row>

                <div
                  style={{
                    borderBottom: "1px solid #F8F8F8",
                    padding: "10px",
                    height: "50vh",
                    overflow: "auto",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {stat?.bidding &&
                    stat?.bidding?.map((value) => (
                      <div className="contain_number">
                        <div className="inner-contain-number">
                          <div className="circle">
                            {((value.count / totalStat) * 100).toFixed(0)}%
                          </div>
                          <div>
                            <h3 className="number_count">{value.count}</h3>
                            <h3 className="number_heading">
                              {value.type.split("_").join(" ")}
                            </h3>
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            if (selectTab !== "2") {
                              setSelectTab("2");
                            }
                            setFinalBidForParent(value.type);
                            setPaymentStatusOwner("");
                            setPaymentStatusClient("");
                          }}
                          className="view_list"
                        >
                          View list
                        </div>
                      </div>
                    ))}

                  {stat?.paidStatus?.length && (
                    <>
                      <div className="contain_number">
                        <div className="inner-contain-number">
                          <div className="circle">
                            {(
                              (stat.paidStatus[0].customerPaid / totalStat) *
                              100
                            ).toFixed(0)}
                            %
                          </div>
                          <div>
                            <h3 className="number_count">
                              {stat.paidStatus[0].customerPaid}
                            </h3>
                            <h3 className="number_heading">Customer Paid</h3>
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            if (selectTab !== "2") {
                              setSelectTab("2");
                            }
                            setFinalBidForParent("");
                            setPaymentStatusOwner("");
                            setPaymentStatusClient("PAID");
                          }}
                          className="view_list"
                        >
                          View list
                        </div>
                      </div>
                      <div className="contain_number">
                        <div className="inner-contain-number">
                          <div className="circle">
                            {(
                              (stat.paidStatus[0].ownerPaid / totalStat) *
                              100
                            ).toFixed(0)}
                            %
                          </div>
                          <div>
                            <h3 className="number_count">
                              {stat.paidStatus[0].ownerPaid}
                            </h3>
                            <h3 className="number_heading">Owner Paid</h3>
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            if (selectTab !== "2") {
                              setSelectTab("2");
                            }
                            setFinalBidForParent("");
                            setPaymentStatusOwner("PAID");
                            setPaymentStatusClient("");
                          }}
                          className="view_list"
                        >
                          View list
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </Col> */}
              </Row>
            </div>
          )}
        </Col>
      </Row>
      <ToastContainer />
    </div>
  );
}

//  <Col>
//               <div className="d-flex flex-column">
//                 <h3 className="t">Post Score</h3>
//                 <Button
//                   style={{ backgroundColor: "lightpink", marginRight: "5px" }}
//                 >
//                   {(() => {
//                     let postQuantity = 0;
//                     for (let com of monthCommission) {
//                       if (
//                         com._id === "Grain Market" ||
//                         com._id === "Poultry Bazar" ||
//                         com._id === "Poultry Market" ||
//                         com._id === "Pets Point" ||
//                         com._id === "FOORI ZRORRAT" ||
//                         com._id === "Meri Zameen" ||
//                         com._id === "Meri Rozi"
//                       ) {
//                         postQuantity += com.quantity;
//                       }
//                     }

//                     return postQuantity ? postQuantity : 0;
//                   })()}
//                 </Button>
//                 {(() => {
//                   let amount = 0;
//                   for (let com of monthCommission) {
//                     if (
//                       com._id === "Grain Market" ||
//                       com._id === "Poultry Bazar" ||
//                       com._id === "Poultry Market" ||
//                       com._id === "Pets Point" ||
//                       com._id === "FOORI ZRORRAT" ||
//                       com._id === "Meri Zameen" ||
//                       com._id === "Meri Rozi"
//                     ) {
//                       amount += com.sumQuantity;
//                     }
//                   }

//                   return (
//                     <span className="p">
//                       {" "}
//                       RS.
//                       {amount ? amount.toFixed(2) : 0}{" "}
//                     </span>
//                   );
//                 })()}
//                 {/* <span className='p'>Rs. 2000</span> */}
//               </div>
//             </Col>
//             <Col>
//               <div className="d-flex flex-column">
//                 <h3 className="t">Review Score</h3>
//                 <Button
//                   style={{ backgroundColor: "lightpink", marginRight: "5px" }}
//                 >
//                   {(() => {
//                     const quantity = monthCommission.find(
//                       (com) => com._id === "Review",
//                     );
//                     return quantity ? quantity.quantity : 0;
//                   })()}
//                 </Button>
//                 {(() => {
//                   let amount = monthCommission.find(
//                     (com) => com._id === "Review",
//                   );
//                   return (
//                     <span className="p">
//                       {" "}
//                       RS.
//                       {amount ? amount.sumQuantity.toFixed(2) : 0}{" "}
//                     </span>
//                   );
//                 })()}
//                 {/* <span className='p'>Rs. 2000</span> */}
//               </div>
//             </Col>

//             <Col>
//               <div className="d-flex flex-column">
//                 <h3 className="t">Murgi Mandi</h3>
//                 <Button
//                   style={{ backgroundColor: "lightpink", marginRight: "5px" }}
//                 >
//                   {(() => {
//                     const quantity = monthCommission.find(
//                       (com) => com._id === "MURGHI MANDI",
//                     );
//                     return quantity ? quantity.quantity : 0;
//                   })()}
//                 </Button>
//                 {(() => {
//                   let amount = monthCommission.find(
//                     (com) => com._id === "MURGHI MANDI",
//                   );
//                   return (
//                     <span className="p">
//                       {" "}
//                       RS.
//                       {amount ? amount.sumQuantity.toFixed(2) : 0}{" "}
//                     </span>
//                   );
//                 })()}

//                 {/* <span className='p'>Rs. 2000</span> */}
//               </div>
//             </Col>

//             <Col>
//               <div className="d-flex flex-column">
//                 <h3 className="t">E commerce</h3>
//                 <Button
//                   style={{ backgroundColor: "lightpink", marginRight: "5px" }}
//                 >
//                   N/A
//                 </Button>
//                 {(() => {
//                   return <span className="p">Rs 0</span>;
//                 })()}
//                 {/* <span className='p'>Rs. 2000</span> */}
//               </div>
//             </Col>

//             <Col>
//               <div className="d-flex flex-column">
//                 <h3 className="t">Fori Zarorat</h3>
//                 <Button
//                   style={{ backgroundColor: "lightpink", marginRight: "5px" }}
//                 >
//                   {(() => {
//                     const quantity = monthCommission.find(
//                       (com) => com._id === "FOORI ZRORRAT",
//                     );
//                     return quantity ? quantity.quantity : 0;
//                   })()}
//                 </Button>
//                 {(() => {
//                   let amount = monthCommission.find(
//                     (com) => com._id === "FOORI ZRORRAT",
//                   );
//                   return (
//                     <span className="p">
//                       {" "}
//                       RS.
//                       {amount ? amount.sumQuantity.toFixed(2) : 0}{" "}
//                     </span>
//                   );
//                 })()}
//                 {/* <span className='p'>Rs. 2000</span> */}
//               </div>
//             </Col>

// {
//   /* <TabPane tab="Overall Statistics" key="3">
//                 <UserStat {...props} userDetails={userDetails} />
//                 <Row>
//                   <Col lg={6} md={12} sm={12} xs={24}>
//                     <div
//                       className="order-box"
//                       style={{
//                         background: "linear-gradient(45deg, #FC9C39, #FBB438)",
//                       }}
//                     >
//                       <div className="order-text">Total Posts</div>
//                       <div className="order-count">
//                         {
//                           <span className="NumberStyle">
//                             {(() => {
//                               let total = 0;
//                               if (userAndProfile.length) {
//                                 for (let value of userAndProfile) {
//                                   total += value?.TotalCount;
//                                 }
//                                 return total;
//                               } else {
//                                 return 0;
//                               }
//                             })()}
//                           </span>
//                         }
//                       </div>
//                     </div>
//                   </Col>
//                   <Col lg={9} md={12} sm={14} xs={24}>
//                     <div className="order-box order-box2">
//                       <div
//                         style={{
//                           display: "flex",
//                           justifyContent: "space-around",
//                         }}
//                       >
//                         <div
//                           onClick={() => {
//                             props.setSelected(
//                               "Dashboard/User",
//                               "Crm Golden User",
//                             );
//                           }}
//                           style={{
//                             flexDirection: "column",
//                             flexGrow: 1,
//                             paddingTop: "12px",
//                             paddingBottom: "12px",
//                             marginRight: "5px",
//                             background:
//                               "linear-gradient(45deg, #FC9C39, #FBB438)",
//                           }}
//                         >
//                           <div className="order-text2 order-text-lg">
//                             Golden <br /> Members
//                           </div>
//                           <div className="order-count2">
//                             {userDetails.activeUser + 20}
//                           </div>
//                         </div>

//                         <div
//                           onClick={() => {
//                             props.setSelected(
//                               "Dashboard/User",
//                               "Crm Rising User",
//                             );
//                           }}
//                           style={{
//                             flexDirection: "column",
//                             flexGrow: 1,
//                             paddingTop: "12px",
//                             paddingBottom: "12px",
//                             background:
//                               "linear-gradient(0deg, #FC9C39, #FBB438)",
//                           }}
//                         >
//                           <div className="order-text2  order-text-lg">
//                             Rising <br />
//                             Members
//                           </div>

//                           <div className="order-count2">
//                             {userDetails.activeUser > 25
//                               ? userDetails.activeUser - 25
//                               : 0}
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </Col>
//                   {/* <Col lg={9} md={24} sm={24} xs={24}>
//                     <div
//                       className='order-box ml-sm-0'
//                       style={{
//                         marginLeft: '20px',
//                         background: 'linear-gradient(45deg, #FFAAD5, #F64AEE)',
//                       }}
//                     >
//                       <div className='order-text'>Total Fans</div>
//                       <div className='order-count'>
//                         {userDetails.activeUser}
//                       </div>
//                     </div>
//                   </Col>

//                   */
// }

//     <RejectUSer />
//   </Row>
//   {/* <Row>
//         <Col lg={24} md={24} sm={24} xs={24}>
//           <div style={{overflowX: 'scroll'}}>
//             <Table1/>
//           </div>
//         </Col>
//       </Row> */}
// </TabPane>
// <TabPane tab="Murghi mandi Stat" key="4">
//   <MurghiMundiState userId={userId} />
// </TabPane>

// const menu = (
//   <Menu>
//     <Menu.Item key="1">
//       {/* <Row gutter={[12, 12]}>
//         <Col span={16}>Assigned Numbers</Col>
//         <Col span={3}></Col>
//         <Col span={5}>12</Col>
//       </Row> */}
//     </Menu.Item>
//     <Menu.Item key="2">
//       {" "}
//       <Row gutter={[12, 12]}>
//         <Col span={16}>Assigned Download</Col>
//         <Col span={3}></Col>
//         <Col span={5}>{taskDetail ? taskDetail.downloads : "Not assign"}</Col>
//       </Row>
//     </Menu.Item>
//     <Menu.Item key="2">
//       {" "}
//       <Row gutter={[12, 12]}>
//         <Col span={16}>Assigned Review</Col>
//         <Col span={3}></Col>
//         <Col span={5}>{taskDetail ? taskDetail.reviews : "Not assign"}</Col>
//       </Row>
//     </Menu.Item>
//     <Menu.Item key="3">
//       <Row gutter={[12, 12]}>
//         <Col span={16}>Assigned Post's</Col>
//         <Col span={3}></Col>
//         <Col span={5}>{taskDetail ? taskDetail.posts : "Not assign"}</Col>
//       </Row>
//     </Menu.Item>
//   </Menu>
// );
