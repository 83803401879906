import { Card, Col, Image, Modal, Row } from "antd";
import React from "react";
import StarDisplay from "../../Utils/StarDisplay";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import { baseUrlOfCommerce } from "../../../shared";
import axios from "axios";

export default function ProductDetailModal({ order, open, setOpen, token }) {
  const [dealerData, setDealerData] = useState([]);

  const getDealerInfo = async () => {
    try {
      const uri = `${baseUrlOfCommerce}/api/v1/orders/getOrderDealerInfo/`;

      const { data } = await axios.get(uri, {
        headers: {
          Authorization: "bearer " + token,
          "api-key": "poultrybaba@1234Sumandri",
        },
      });
      setDealerData(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getDealerInfo();
  }, []);
  return (
    <Modal
      title="Order Details"
      centered
      visible={open}
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      width={"80%"}
    >
      <Row className="order-modal">
        <Col span={8} style={{ borderRight: "1px solid #e4e4e4" }}>
          <div className="modal-styles">
            <Row justify="center" style={{ textAlign: "center" }}>
              <Col span={24}>
                <Card title="Order Details" bordered={false}>
                  <span style={{ fontWeight: 600 }}>Order No: </span>{" "}
                  {order?.orderNumber}
                </Card>
              </Col>
            </Row>
          </div>
          <div className="modal-styles modal-info">
            <Row justify="center" style={{ textAlign: "center" }}>
              <Col span={24}>
                <Card title="Order's Invoice" bordered={false}>
                  <Row style={{ marginBottom: "8px" }}>
                    <Col span={24}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                        }}
                      >
                        <span className="ai-heading">Actual Amount: </span>
                        <span>{order?.actualAmount}</span>
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: "8px" }} gutter={10}>
                    <Col span={24}>
                      <div>
                        <span className="ai-heading">Amount to be Paid: </span>
                        <span style={{ textTransform: "capitalize" }}>
                          {order?.amountToBePaid}
                        </span>
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: "8px" }} gutter={10}>
                    <Col span={24}>
                      <div>
                        <span className="ai-heading">Discounted Amount: </span>
                        <span style={{ textTransform: "capitalize" }}>
                          {order?.discountedAmount}
                        </span>
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: "8px" }} gutter={10}>
                    <Col span={24}>
                      <div>
                        <span className="ai-heading">Order Status: </span>
                        <span
                          className={`${
                            order?.orderStatus === "PENDING"
                              ? "text-red"
                              : "text-green"
                          }`}
                        >
                          {order?.orderStatus}
                        </span>
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: "8px" }} gutter={10}>
                    <Col span={24}>
                      <div>
                        <span className="ai-heading">Payment Method: </span>
                        <span>{order?.paymentMethod}</span>
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: "8px" }}>
                    <Col span={24}>
                      <div>
                        <span className="ai-heading">Payment Status: </span>
                        <span
                          className={`${
                            order?.paymentStatus === "PENDING"
                              ? "text-red"
                              : "text-green"
                          }`}
                        >
                          {order?.paymentStatus}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </div>
        </Col>
        <Col
          span={8}
          style={{ borderRight: "1px solid #e4e4e4", paddingLeft: "15px" }}
        >
          <div className="modal-styles modal-info">
            <Row justify="center" style={{ textAlign: "center" }}>
              <Col span={24}>
                <Card title="Dealer Details" bordered={false}>
                  {order?.productsInfo?.map((productsInfo) => (
                    <Row style={{ marginBottom: "8px" }} gutter={10}>
                      <Col span={24}>
                        <div>
                          <span className="ai-heading">Name: </span>
                          <span style={{ textTransform: "capitalize" }}>
                            {productsInfo?.dealer?.name}
                            {productsInfo?.dealer?.phoneNumber}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  ))}
                </Card>
              </Col>

              <Col span={24}>
                <Card title="Customer's Details" bordered={false}>
                  {order?.shipmentInfo ? (
                    <Row style={{ marginBottom: "8px" }} gutter={10}>
                      <Col span={24}>
                        <div style={{ marginBottom: "8px" }}>
                          <span className="ai-heading">Name: </span>
                          <span style={{ textTransform: "capitalize" }}>
                            {order?.shipmentInfo?.name}
                          </span>
                        </div>
                      </Col>
                      <Col span={24}>
                        <div style={{ marginBottom: "8px" }}>
                          <span className="ai-heading">Phone: </span>
                          <a
                            href={`tel:${order?.shipmentInfo?.phoneNumber}}`}
                            style={{
                              textTransform: "capitalize",
                              color: "black",
                            }}
                          >
                            {order?.shipmentInfo?.phoneNumber}
                          </a>
                        </div>
                      </Col>
                      <Col span={24}>
                        <div style={{ marginBottom: "8px" }}>
                          <span className="ai-heading">City: </span>
                          <span style={{ textTransform: "capitalize" }}>
                            {order?.shipmentInfo?.city}
                          </span>
                        </div>
                      </Col>
                      <Col span={24}>
                        <div style={{ marginBottom: "8px" }}>
                          <span className="ai-heading">Address: </span>
                          <span style={{ textTransform: "capitalize" }}>
                            {order?.shipmentInfo?.address}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )}
                </Card>
              </Col>
            </Row>
          </div>
        </Col>
        <Col span={8} style={{ paddingLeft: "15px" }}>
          <div className="modal-styles modal-info">
            <Row justify="center" style={{ textAlign: "center" }}>
              <Col span={24}>
                <Card title="Product Details" bordered={false}>
                  {order?.productsInfo?.map((productsInfo, index) => (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Image
                          style={{
                            borderRadius: "12px",
                            width: "100px",
                            height: "100px",
                            border: "3px solid #ffa200",
                          }}
                          src={
                            productsInfo?.pictures?.length
                              ? productsInfo?.pictures[0]
                              : ""
                          }
                          alt={productsInfo?.name}
                        />
                      </div>
                      <div className="modal-styles">
                        <Row justify="center" style={{ textAlign: "center" }}>
                          <Col span={24}>
                            <Card title={productsInfo?.name} bordered={false}>
                              {productsInfo?.description}
                            </Card>
                          </Col>
                        </Row>
                      </div>
                      <div className="modal-styles modal-info">
                        <Row justify="center" style={{ textAlign: "center" }}>
                          <Col span={24}>
                            <Card title="Product's Receipt" bordered={false}>
                              <Row style={{ marginBottom: "8px" }} gutter={10}>
                                <Col span={24}>
                                  <div>
                                    <span className="ai-heading">
                                      Product Invoice No:{" "}
                                    </span>
                                    <span
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {order?.orderNumber} - {index + 1}
                                    </span>
                                  </div>
                                </Col>
                                <Col span={24}>
                                  <div>
                                    <span className="ai-heading">
                                      Payment Methods:{" "}
                                    </span>
                                    <span
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {productsInfo?.availablePaymentMethods ? (
                                        <>
                                          {productsInfo?.availablePaymentMethods.map(
                                            (pm) => pm.split("_").join(" "),
                                          )}
                                        </>
                                      ) : (
                                        <>N/A</>
                                      )}
                                    </span>
                                  </div>
                                </Col>
                              </Row>

                              <Row style={{ marginBottom: "8px" }} gutter={10}>
                                <Col span={12}>
                                  <div>
                                    <span className="ai-heading">
                                      Quantity:{" "}
                                    </span>
                                    <span
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {productsInfo?.quantity}
                                    </span>
                                  </div>
                                </Col>
                                <Col span={24}>
                                  <div>
                                    <span className="ai-heading">
                                      Unit price:{" "}
                                    </span>
                                    <span
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {productsInfo?.price}
                                    </span>
                                  </div>
                                </Col>
                                <Col span={24}>
                                  <div>
                                    <span className="ai-heading">
                                      T. Price:{" "}
                                    </span>
                                    <span
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {(
                                        productsInfo?.quantity *
                                        productsInfo.price
                                      ).toFixed(2)}
                                    </span>
                                  </div>
                                </Col>
                                <Col span={24}>
                                  <div>
                                    <span className="ai-heading">
                                      Customer discount:{" "}
                                    </span>
                                    <span
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {(
                                        ((productsInfo?.quantity *
                                          productsInfo.price) /
                                          100) *
                                        productsInfo.discount
                                      ).toFixed(2)}
                                      {/* {productsInfo?.price} */}
                                    </span>
                                  </div>
                                </Col>
                                <Col span={24}>
                                  <div>
                                    <span className="ai-heading">
                                      PB commission:
                                    </span>
                                    <span
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {(
                                        ((productsInfo?.quantity *
                                          productsInfo.price) /
                                          100) *
                                        productsInfo.poultryBabaCommission
                                      ).toFixed(2)}
                                      {/* {productsInfo?.price} */}
                                    </span>
                                  </div>
                                </Col>
                                <Col span={24}>
                                  <div>
                                    <span className="ai-heading">
                                      Price to show:{" "}
                                    </span>
                                    <span
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {(
                                        productsInfo?.price *
                                          productsInfo.quantity -
                                        ((productsInfo?.quantity *
                                          productsInfo.price) /
                                          100) *
                                          productsInfo.discount
                                      ).toFixed(2)}
                                    </span>
                                  </div>
                                </Col>
                                <Col span={24}>
                                  <div>
                                    <span className="ai-heading">Total: </span>
                                    <span
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {(
                                        productsInfo?.price *
                                          productsInfo.quantity +
                                        ((productsInfo?.quantity *
                                          productsInfo.price) /
                                          100) *
                                          productsInfo.poultryBabaCommission -
                                        ((productsInfo?.quantity *
                                          productsInfo.price) /
                                          100) *
                                          productsInfo.discount
                                      ).toFixed(2)}
                                    </span>
                                  </div>
                                </Col>
                              </Row>
                            </Card>
                          </Col>
                          <Col span={24}>
                            <Card title="Additional Info" bordered={false}>
                              <Row style={{ marginBottom: "8px" }}>
                                <Col span={24}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      alignItems: "flex-start",
                                    }}
                                  >
                                    <span className="ai-heading">Rating: </span>
                                    <span>
                                      <StarDisplay
                                        rating={productsInfo?.rating}
                                      />
                                    </span>
                                  </div>
                                </Col>
                              </Row>
                              <Row style={{ marginBottom: "8px" }} gutter={10}>
                                <Col span={24}>
                                  <div>
                                    <span className="ai-heading">
                                      Availability:{" "}
                                    </span>
                                    <span
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {productsInfo?.availability
                                        ? "Yes"
                                        : "No"}
                                    </span>
                                  </div>
                                </Col>
                              </Row>

                              <Row style={{ marginBottom: "8px" }} gutter={10}>
                                <Col span={24}>
                                  <div>
                                    <span className="ai-heading">
                                      Category:{" "}
                                    </span>
                                    <span
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {productsInfo?.category}
                                    </span>
                                  </div>
                                </Col>
                              </Row>

                              <Row style={{ marginBottom: "8px" }}>
                                <Col span={24}>
                                  <div>
                                    <span className="ai-heading">
                                      Sub Category:{" "}
                                    </span>
                                    <span
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {productsInfo?.subCategory}
                                    </span>
                                  </div>
                                </Col>
                              </Row>

                              <Row style={{ marginBottom: "8px" }} gutter={10}>
                                <Col span={12}>
                                  <div>
                                    <span className="ai-heading">City: </span>
                                    <span
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {productsInfo?.city}
                                    </span>
                                  </div>
                                </Col>
                              </Row>
                              <Row style={{ marginBottom: "8px" }} gutter={10}>
                                <Col span={24}>
                                  <div>
                                    <span className="ai-heading">
                                      Booking Date:{" "}
                                    </span>
                                    <span
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {moment(order.createdAt).format(
                                        "D MMMM YYYY",
                                      )}
                                    </span>
                                  </div>
                                </Col>
                              </Row>
                              <Row style={{ marginBottom: "8px" }} gutter={10}>
                                <Col span={24}>
                                  <div>
                                    <span className="ai-heading">
                                      Expiry Date:{" "}
                                    </span>
                                    <span
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {moment(productsInfo?.expiryDate).format(
                                        "D MMMM YYYY",
                                      )}
                                    </span>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={24}>
                                  <div>
                                    <span className="ai-heading">
                                      Manufacture Date:{" "}
                                    </span>
                                    <span
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {moment(
                                        productsInfo?.manufactureDate,
                                      ).format("D MMMM YYYY")}
                                    </span>
                                  </div>
                                </Col>
                              </Row>
                            </Card>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  ))}
                </Card>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Modal>
  );
}
