import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllBiddings } from "../../../Redux/ActionCreaters/Order";
import { checkPermission, RemoveColonFromPermission } from "../../../shared";
import CustomPagination from "../../Pagination";
import PostBidDetailsModal from "./PostBidDetails";
// import AggOrderModal from './aggOrders/aggOrderModal';

export const bidStatusArray = [
  {
    value: "",
    text: "All Offers",
  },
  {
    value: "OFFER_SEND",
    text: "Pending Offers",
  },

  {
    value: "OFFER_ACCEPTED",
    text: "Pending to pay",
  },
  {
    value: "OFFER_PAID",
    text: "Paid Offer",
  },
  {
    value: "OFFER_REJECTED",
    text: "Rejected Offers",
  },
];

export default function MurgiMandiOffer({
  finalBidForParent,
  paymentStatusOwner,
  paymentStatusClient,
  totalStat,
  startDate,
  endDate,
}) {
  const [selectedCommodity, setSelectedCommodity] = useState("");
  const { bidding } = useSelector((state) => state.Order);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [bidStatus, setBidStatus] = useState("");

  const [selectedBit, setSelectedBit] = useState(null);

  const {
    token,
    profileDetails,
    _id: userId,
  } = useSelector((state) => state.Authentication.user);

  const [currentPage, setCurrentPage] = useState(1);
  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    getOrders();
  }, [
    currentPage,
    isModalVisible,
    bidStatus,
    selectedCommodity,
    paymentStatusOwner,
    paymentStatusClient,
    finalBidForParent,
    totalStat,
    startDate,
    endDate,
  ]);
  const dispatch = useDispatch();
  const getOrders = async () => {
    try {
      let crmUserId = "";
      const PermissionsRomovingColor = RemoveColonFromPermission(
        profileDetails.permissions,
      );

      if (PermissionsRomovingColor.includes("Crm_System")) {
        crmUserId = userId;
      }
      if (checkPermission(profileDetails.permissions, "AllResources")) {
        crmUserId = undefined;
      }
      let obj = {
        finalBidForParent,
        parentId: true,
        paymentStatusOwner,
        paymentStatusClient,
        startDate: new Date(startDate),
        endDate: new Date(endDate),
      };
      dispatch(
        getAllBiddings(selectedCommodity, currentPage, token, crmUserId, obj),
      );
    } catch (err) {
      console.log(err);
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      <PostBidDetailsModal
        selectedBit={selectedBit}
        isModalVisible={isModalVisible}
        showModal={showModal}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <CustomPagination
          current={currentPage}
          total={bidding?.totalDocs}
          onPageChange={onPageChange}
        />
      </div>
      {/* <Filter
        CategoryTypes={bidStatusArray}
        selectedValue={bidStatus}
        setFilter={setBidStatus}
        selectedCommodity={selectedCommodity}
        setSelectedCommodity={setSelectedCommodity}
      /> */}

      <Row
        justify={"space-around"}
        style={{ borderRadius: "15px", marginTop: "20px" }}
      >
        {bidding?.docs.length && (
          <Col
            span={22}
            style={{
              background: "white",
              border: ".5px solid gray",
              margin: 0,
              backgroundColor: "white",
              cursor: "pointer",
            }}
          >
            <Row
              align="middle"
              justify={"space-between"}
              style={{
                paddingTop: "10px",
                paddingLeft: "10px",
              }}
            >
              <Col span={2}>
                <p style={{ color: "black" }}>
                  <b>Id </b>
                </p>
              </Col>
              <Col span={3}>
                <p style={{ color: "black" }}>
                  <b>Date </b>
                </p>
              </Col>
              <Col span={2}>
                <p style={{ color: "black" }}>
                  <b>Types </b>
                </p>
              </Col>
              <Col span={2}>
                <p style={{ color: "black" }}>
                  <b>City </b>
                </p>
              </Col>{" "}
              <Col span={2}>
                <p style={{ color: "black" }}>
                  <b>Offer Price</b>{" "}
                </p>
              </Col>
              <Col span={3}>
                <b>Status</b>
              </Col>
              <Col span={2}>
                <p style={{ color: "black" }}>
                  <b>Total Order </b>
                </p>
              </Col>
            </Row>
          </Col>
        )}
        {bidding?.docs?.map((value) => (
          <Col
            onClick={() => {
              setSelectedBit(value);
              setIsModalVisible(true);
            }}
            key={value._id}
            span={22}
            style={{
              background: "white",
              border: ".5px solid gray",
              backgroundColor: "white",
              cursor: "pointer",
              position: "relative",
            }}
          >
            <Row
              align="middle"
              justify={"space-between"}
              style={{ paddingTop: "10px", paddingLeft: "10px" }}
            >
              <Col span={2}>
                <p style={{ color: "black" }}>{value?._id.slice(0, 8)}</p>
              </Col>
              <Col span={3}>
                <p style={{ color: "black" }}>
                  {new Date(value?.updatedAt).toDateString()}
                </p>
              </Col>
              <Col span={2}>
                <p style={{ color: "black" }}>
                  {value?.postDetails?.postDetails?.type}{" "}
                  {value?.postDetails?.postDetails?.subType}
                </p>
              </Col>
              <Col span={2}>
                <p style={{ color: "black" }}>{value?.postDetails?.city}</p>
              </Col>{" "}
              <Col span={2}>
                <p style={{ color: "black" }}>{value?.offeredPrice}/-</p>
              </Col>
              <Col span={3}>
                <p
                  style={{
                    width: "130px",
                    textAlign: "center",
                    borderRadius: "20px",
                    padding: "5px",
                    color:
                      value.finalBidForParent === "OFFER_REJECTED"
                        ? "red"
                        : value.finalBidForParent === "OFFER_PAID"
                        ? "blue"
                        : value.paymentStatusOwner === "PAID" ||
                          value.paymentStatusClient === "PAID"
                        ? "orange"
                        : "#dce600",

                    background:
                      value.finalBidForParent === "OFFER_REJECTED"
                        ? "rgba(255, 9, 2, 0.08)"
                        : value.finalBidForParent === "OFFER_PAID"
                        ? "rgba(0, 162, 255, 0.1)"
                        : value.paymentStatusOwner === "PAID" ||
                          value.paymentStatusClient === "PAID"
                        ? "rgba(43, 193, 85, 0.08);"
                        : "rgba(255, 162, 0, 0.1)",
                  }}
                >
                  {value.finalBidForParent}
                </p>
              </Col>
              <Col span={2}>
                <p style={{ color: "black" }}>{value?.maxTryInDeal}</p>
              </Col>
            </Row>
          </Col>
        ))}
      </Row>
    </div>
  );
}
