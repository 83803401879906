import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getMurgiStatApi } from "../../../RestServices/postingService";
import { checkPermission } from "../../../shared";
import "./dasbaords.css";
import PostDetails from "./postModalShow";
import TotalPostModal from "./TotalPostModal";
import UserDetails from "./UserDetailsModal";

export default function MughiMandiDashboards({
  startDate,
  permissions,
  city,
  endDate,
}) {
  const [selectedCommodities, setSelectedCommodities] = useState("");
  const [stat, setStat] = useState(null);
  const { token, _id: userId } = useSelector(
    (state) => state.Authentication.user,
  );

  useEffect(() => {
    (async () => {
      try {
        const statItem = await getMurgiStatApi(
          {
            startDate: startDate.toDate(),
            endDate: endDate.toDate(),
            subType: selectedCommodities,
            city: city,
            crmUserId: checkPermission(permissions, "AllResources")
              ? undefined
              : userId,
          },
          token,
        );
        setStat(statItem?.posts);
      } catch (err) {}
    })();
  }, [token, city, selectedCommodities, startDate, endDate]);

  const [isModalVisiblePost, setIsModalVisiblePost] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedModal, setSelectedModal] = useState(undefined);
  const [status, setStatus] = useState("");
  const [mode, setMode] = useState("");
  const [modeModal, setModeModal] = useState(false);
  const kFormatter = (num) => {
    if (isNaN(num)) {
      return "0";
    }
    return Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(3) + "k"
      : Math.sign(num) * Math.abs(num);
  };

  return (
    <div>
      <UserDetails
        isModalVisible={isModalVisible}
        handleOk={() => setIsModalVisible(false)}
        handleCancel={() => setIsModalVisible(false)}
        data={selectedModal}
      />

      <divostDetails
        isModalVisible={isModalVisiblePost}
        handleOk={() => setIsModalVisiblePost(false)}
        handleCancel={() => setIsModalVisiblePost(false)}
        data={selectedModal}
        status={status}
        filters={{
          startDate: startDate.toDate(),
          endDate: endDate.toDate(),
          subType: selectedCommodities,
          city: city,
          crmUserId: userId,
        }}
      />

      <TotalPostModal
        isModalVisible={modeModal}
        handleOk={() => setModeModal(false)}
        handleCancel={() => setModeModal(false)}
        data={selectedModal}
        mode={mode}
        filters={{
          startDate: startDate.toDate(),
          endDate: endDate.toDate(),
          subType: selectedCommodities,
          city: city,
          crmUserId: userId,
        }}
      />
      <Row gutter={[12, 12]}>
        <Col xs={12} sm={12} md={8} lg={6}>
          <div
            className="other_item-mur"
            onClick={() => {
              setSelectedModal({
                stat: stat?.sellerStat,
                payment: stat?.sellingPaymentDetail,
              });
              setIsModalVisible(true);
            }}
          >
            <span className="item-overall">Overall</span>
            <div className="item-top-seller">Top Seller</div>
            <div className="item-counting">{stat?.sellerStat?.length}</div>
            <button className="item-button">View More</button>
          </div>
        </Col>
        <Col xs={12} sm={12} md={8} lg={6}>
          <div
            className="other_item-mur"
            onClick={() => {
              setSelectedModal({
                stat: stat?.buyerStat,
                payment: stat?.buyingPaymentDetail,
              });
              setIsModalVisible(true);
            }}
          >
            <span className="item-overall">Overall</span>
            <div className="item-top-seller">Top Buyer</div>
            <div className="item-counting">{stat?.buyerStat?.length}</div>
            <button className="item-button">View More</button>
          </div>
        </Col>{" "}
        <Col xs={12} sm={12} md={8} lg={6}>
          <div
            className="other_item-mur"
            onClick={() => {
              setStatus("selling");

              setIsModalVisiblePost(true);
            }}
          >
            <span className="item-overall">Overall</span>
            <div className="item-top-seller">View Selling</div>
            <div className="item-counting">
              {!stat?.postStat.length
                ? stat?.postStat.length
                : stat?.postStat[0]?.views}
            </div>
            <button className="item-button">View (Selling)</button>
          </div>
        </Col>
        <Col xs={12} sm={12} md={8} lg={6}>
          <div
            className="other_item-mur"
            onClick={() => {
              setStatus("buying");

              setIsModalVisiblePost(true);
            }}
          >
            <span className="item-overall">Overall</span>
            <div className="item-top-seller">View buying</div>
            <div className="item-counting">
              {!stat?.postStat.length
                ? stat?.postStat.length
                : stat?.postStat[0]?.views}
            </div>
            <button className="item-button">View (buying)</button>
          </div>
        </Col>
        <Col xs={12} sm={12} md={8} lg={6}>
          <div className="other_item-mur">
            <span className="item-overall">Overall</span>
            <div className="item-top-seller">Total Offer</div>
            <div className="item-counting">
              {!stat?.dealStat.length
                ? stat?.dealStat.length
                : stat?.dealStat[0].count}
            </div>
            {/* <button className="item-button">View More</button> */}
          </div>
        </Col>{" "}
        <Col xs={12} sm={12} md={8} lg={6}>
          <div className="other_item-mur">
            <span className="item-overall">Overall</span>
            <div className="item-top-seller">Unit Sold(selling)</div>
            <div className="item-counting">
              {" "}
              {!stat?.postStat.length
                ? stat?.postStat.length
                : stat?.postStat[0]?.numberOfPatties}
            </div>
            {/* <button className="item-button">View More</button> */}
          </div>
        </Col>{" "}
        <Col xs={12} sm={12} md={8} lg={6}>
          <div className="other_item-mur">
            <span className="item-overall">Overall</span>
            <div className="item-top-seller">Unit Sold (buying)</div>
            <div className="item-counting">
              {!stat?.buyingPost.length
                ? stat?.buyingPost.length
                : stat?.buyingPost[0]?.numberOfPatties}
            </div>
            {/* <button className="item-button">View More</button> */}
          </div>
        </Col>{" "}
        <Col xs={12} sm={12} md={8} lg={6}>
          <div
            className="other_item-mur"
            onClick={() => {
              setMode("selling");
              setModeModal(true);
            }}
          >
            <span className="item-overall">Overall</span>
            <div className="item-top-seller">Total Commission</div>
            <div className="item-counting">
              {" "}
              {stat?.postStat.length
                ? kFormatter(stat?.paymentDetail[0]?.total)
                : 0}
            </div>
          </div>
        </Col>
        <Col xs={12} sm={12} md={8} lg={6}>
          <div
            className="other_item-mur"
            onClick={() => {
              setMode("selling");
              setModeModal(true);
            }}
          >
            <span className="item-overall">Overall</span>
            <div className="item-top-seller">Total Post (Selling)</div>
            <div className="item-counting">
              {" "}
              {!stat?.postStat.length
                ? stat?.postStat.length
                : stat?.postStat[0]?.count}
            </div>
            <button className="item-button">View More</button>
          </div>
        </Col>
        <Col
          xs={12} sm={12} md={8} lg={6}
          onClick={() => {
            setMode("buying");
            setModeModal(true);
          }}
        >
          <div className="other_item-mur">
            <span className="item-overall">Overall</span>
            <div className="item-top-seller">Total Post (Buying)</div>
            <div className="item-counting">
              {" "}
              {!stat?.buyingPost.length
                ? stat?.buyingPost.length
                : stat?.buyingPost[0]?.count}
            </div>
            <button className="item-button">View More</button>
          </div>
        </Col>
      </Row>
    </div>
  );
}
