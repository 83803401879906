/* eslint-disable no-undef */
import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyCS-t6ORn8PNbCzirOCtmeYBScPvvIcQhc",
  authDomain: "poultry-baba.firebaseapp.com",
  databaseURL: "https://poultry-baba.firebaseio.com",
  projectId: "poultry-baba",
  storageBucket: "poultry-baba.appspot.com",
  messagingSenderId: "753763365567",
  appId: "1:753763365567:web:d486420caa64cc6d6b4c0a",
  measurementId: "G-42ZHTETTT7",
};

export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
