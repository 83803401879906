import { Star } from "@material-ui/icons";
import { Col, Drawer, Image, Rate, Row, Typography } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import React from "react";
import { MAIN_BACKGROUND_COLOR } from "../../styleConstants";
import RowStatus from "./RowStatus";

const desc = ["terrible", "bad", "normal", "good", "wonderful"];

const AddUser = (props) => {
  const {
    isOpen,
    closeModal,
    inputHandler,
    submitForm,
    isFormLoading,
    placement,
    selectedUser,
  } = props;
  return (
    <Drawer
      title="Profile History"
      placement={placement || "right"}
      closable={false}
      onClose={closeModal}
      visible={isOpen}
      width={"300px"}
    >
      <Row justify="center" align="center">
        <Col span={6}>
          <Avatar
            size={50}
            src={
              selectedUser?.profileImage ? (
                <Image src={selectedUser?.profileImage} alt="profile" />
              ) : (
                "https://via.placeholder.com/100"
              )
            }
          />
        </Col>
        <Col span={12}>
          <Typography.Title
            style={{
              textTransform: "capitalize",
              lineHeight: 0,
              fontSize: "12px",
            }}
            level={5}
          >
            {selectedUser?.name}
          </Typography.Title>

          <Typography.Title
            style={{
              textTransform: "capitalize",
              lineHeight: 0,
              fontSize: "12px",
            }}
            level={5}
          >
            City: {selectedUser?.city}
          </Typography.Title>

          <Typography.Title
            style={{
              textTransform: "capitalize",
              lineHeight: 0,
              fontSize: "12px",
            }}
            level={5}
          >
            {selectedUser?.phoneNumber}
          </Typography.Title>
          <Typography.Title
            style={{
              textTransform: "capitalize",
              lineHeight: 0,
              fontSize: "12px",
            }}
            level={5}
          >
            Status {selectedUser?.status}
          </Typography.Title>
        </Col>
        <Col span={6}>
          <span>
            <Star style={{ color: "yellow" }} />
          </span>
          <span style={{ position: "relative", top: "-5px" }}>2.5</span>
        </Col>
      </Row>

      <Row gutter={[12, 12]} style={{ marginTop: "50px" }} align="middle">
        <RowStatus field={"Total Post"} value={23} />
        <RowStatus
          field={"Date of Creation"}
          value={new Date(selectedUser?.createdAt).toLocaleDateString()}
        />

        <RowStatus field={"Total Order"} value={5} />
        {selectedUser?.lastSignIn && (
          <RowStatus
            field={"Last Login"}
            value={new Date(selectedUser?.lastSignIn).toLocaleDateString()}
          />
        )}

        <RowStatus
          field={"Login History"}
          value={selectedUser?.signInCount ? selectedUser?.signInCount : 1}
        />
        <RowStatus
          field={"Edit Hitory"}
          value={
            selectedUser?.profileUpdateCount
              ? selectedUser?.profileUpdateCount
              : 1
          }
        />
        <RowStatus field={"Got Reported"} value={5} />

        <RowStatus field={"Made Report"} value={5} />
        <RowStatus field={"Like"} value={5} />
        <RowStatus field={"Dislike"} value={15} />
        <RowStatus field={"Comments"} value={5} />

        <RowStatus field={"Total Business"} value={"23423 PKR"} />
      </Row>
    </Drawer>
  );
};

export default AddUser;
