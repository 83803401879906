import { Button, Col, Drawer, Dropdown, Menu, Row } from "antd";
import * as moment from "moment";
import React, { useEffect, useState } from "react";
import { HiDotsVertical } from "react-icons/hi";
import {
  createOfferOnEnquiry,
  findMyOfferOnEnquire,
  getInqueryOrdersServiceApi,
} from "../../../RestServices/ecommerenceService";
import Table from "../../../utils/Table";
import { Sorter } from "../../../utils/sorter";
import CustomPagination from "../../Pagination";
import InqueryOrderDetails from "./InqueryOrderDrawer";
import axios from "axios";
import InqueryOrderModal from "./InqueryOrderModal";
import { baseUrlOfCommerce } from "../../../shared";

const initialState = {
  productId: "",
  originPrice: "",
  offerPrice: "",
  productSource: "",
  deliveryTimeInDays: "",
};

export default function InqueryOrders({
  token,
  tab,
  selectedEnquiryId,
  selectedEnquiryNotifyId,
  getEnquiryNotifications,
}) {
  const [inqueryOrderList, setInqueryOrdersList] = useState([]);
  const [inqueryOrders, setInqueryOrders] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [openNow, setOpenNow] = useState("");
  const [productData, setProductData] = useState({});
  const [createinqueryOrder, setCreateInqueryOrder] = useState(initialState);
  const [InqData, setInqData] = useState([]);
  const [enquiryModal, setEnquiryModal] = useState(false);
  const [enquiryDataModal, setEnquiryDataModal] = useState({});

  const getRespondInquery = async () => {
    try {
      const uri = `https://prod-ecommerce.poultrybaba.com/api/v1/offerOnEnquiry/getOffers`;

      const { data } = await axios.get(uri, {
        params: {
          page: currentPage,
        },
        headers: {
          "api-key": "poultrybaba@1234Sumandri",
          Authorization: `bearer ${token}`,
        },
      });
      setInqData(data.data);
    } catch (error) {
      console.log(error);
    }
  }

  const updateEnquiryList = async () => {
    const res = await axios.patch(`${baseUrlOfCommerce}/api/v1/enquiries/updateEnquiryNotificationStatus/${selectedEnquiryNotifyId}`, {}, {
      headers: {
        Authorization: "bearer " + token,
        "api-key": "poultrybaba@1234Sumandri",
      },
    });
    console.log(res);
  }

  const getEnquiryById = async () => {
    const uri = `/api/v1/enquiries/getEnquiryById`;
    const { data } = await axios.get(baseUrlOfCommerce + uri, {
      params: {
        enquiryId: selectedEnquiryId
      },
      headers: {
        Authorization: "bearer " + token,
        "api-key": "poultrybaba@1234Sumandri",
      },
    });
    console.log(data?.data);
    if (selectedEnquiryId) {
      setEnquiryDataModal(data?.data);
      setEnquiryModal(true);
      updateEnquiryList();
      getEnquiryNotifications();
    }
  }

  useEffect(() => {
    getEnquiryById();
  }, [selectedEnquiryId]);

  useEffect(() => {
    getRespondInquery();

    const interval = setInterval(getRespondInquery, 5000);

    return () => clearInterval(interval);

  }, []);

  const createInqueryApi = async () => {
    try {
      setLoading(true);

      await createOfferOnEnquiry(
        { enquiryId: openNow, ...createinqueryOrder },
        token,
      );
      getInqueryOrdersServiceApi(token, {
        page: currentPage
      });
      getRespondInquery();
      setOpenNow("");
      // setCreateInqueryOrder(initialState);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getInqueryOrders = async () => {
    const data = await getInqueryOrdersServiceApi(token, {
      page: currentPage,
    });
    setInqueryOrders(data.data);
    setInqueryOrdersList(data.data.docs);
  }

  useEffect(() => {
    getInqueryOrders();
  }, [token, currentPage, tab]);

  useEffect(() => {
    (async () => {
      if (openNow) {
        try {
          const { data } = await findMyOfferOnEnquire(
            {
              enquiryId: openNow,
            },
            token,
          );
          if (data.length) {
            setCreateInqueryOrder({ ...data[0] });
          } else {
            setCreateInqueryOrder({ ...initialState });
          }
        } catch (err) {
          console.log(err);
        }
      }
    })();
  }, [openNow]);

  const menu = (id, record) => (
    <Menu>
      <Menu.Item
        onClick={() => {
          setOpenNow(id);
          setProductData(record);
        }}
      >
        Place Offer
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setOpenNow(id);
          setProductData(record);
        }}
      >
        Place Bid
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: "Date & Time",
      dataIndex: "createdAt",
      render: (createdAt) => <div style={{ width: "130px" }}>
        {moment(createdAt).format('DD-MM-YYYY HH:mm:ss')}
      </div>,
      sorter: {
        compare: Sorter.DEFAULT,
        multiple: 7,
      },
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      render: (_unit, record) => <>{record?.customerProfile?.name}</>,
    },

    {
      title: "Product Name",
      dataIndex: "specializeCategory",
      sorter: {
        compare: Sorter.DEFAULT,
        multiple: 5,
      },
    },
    {
      title: "Category",
      dataIndex: "category",
      sorter: {
        compare: Sorter.DEFAULT,
        multiple: 4,
      },
    },
    {
      title: "Sub-Category",
      dataIndex: "subCategory",
      sorter: {
        compare: Sorter.DEFAULT,
        multiple: 3,
      },
    },
    {
      title: "Demand/Unit",
      dataIndex: "unit",
      render: (unit, record) => (
        <div
          style={{
            background: "rgba(43, 193, 85, 0.15)",
            borderRadius: "28px",
            color: "#2BC155",
            fontSize: "14px",
            border: "0",
            textAlign: "center",
            padding: "5px 10px",
            width: "80px"
          }}
        >
          {record.quantity} {unit}
        </div>
      ),
    },
    {
      title: "City",
      dataIndex: "city",
      render: (_unit, record) => <>{record?.customerProfile?.city}</>,
    },
    {
      title: "Offer Price",
      dataIndex: "",
      render: (record) => (
        <>
          {InqData?.docs?.find(item => item?.enquiryId?._id === record._id) ? (
            <div
              style={{
                background: "rgba(43, 193, 85, 0.15)",
                borderRadius: "28px",
                color: "#2BC155",
                fontSize: "14px",
                border: "0",
                textAlign: "center",
                padding: "5px 10px",
                width: "80px"
              }}
            >
              {InqData?.docs?.find(item => item?.enquiryId?._id === record._id)?.offerPrice}
            </div>
          ) : (
            <></>
          )}
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "",
      render: (record) => (
        <>
          <Button
            style={{
              background: "#FFA200",
              borderRadius: "28px",
              color: "#4A0600",
              padding: "0 20px",
              fontSize: "14px",
            }}
            type="primary"
          >

            {InqData?.docs?.find(item => item?.enquiryId?._id === record._id)?.status === "REJECT" || InqData?.docs?.find(item => item?.enquiryId?._id === record._id)?.status === "OFFER_SEND" ? (
              <div >
                Rate offered
              </div>
            ) : (
              <div onClick={() => {
                setOpenNow(record._id);
                setProductData(record);
              }}>
                Offer Rate
              </div>
            )}
          </Button>
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "",
      render: (record) => (
        <>
          {InqData?.docs?.find(item => item?.enquiryId?._id === record._id) ? (
            <div style={{
              background: InqData?.docs?.find(item => item?.enquiryId?._id === record._id)?.status === "REJECT" ? "red" : "#2BC155",
              borderRadius: "28px",
              color: "#fff",
              padding: "5px 10px",
              fontSize: "14px",
              border: "0",
              textAlign: "center",
            }}>
              {InqData?.docs?.find(item => item?.enquiryId?._id === record._id)?.status}
            </div>
          ) : (
            <></>
          )}
        </>
      )
    },
    {
      title: "Min-Sec",
      dataIndex: "minsec",
      render: (_i, record) => (
        <Button
          style={{
            background: "rgba(43, 193, 85, 0.15)",
            borderRadius: "28px",
            color: "#2BC155",
            padding: "0 10px",
            fontSize: "14px",
            border: "0",
          }}
        >
          {moment(record.createdAt).fromNow()}
        </Button>
      ),
    },
    {
      title: "",
      dataIndex: "_id",
      render: (id, record) => (
        <Dropdown overlay={() => menu(id, record)}>
          <Button style={{ border: "0", padding: "0" }}>
            <HiDotsVertical size={18} />
          </Button>
        </Dropdown>
      ),
    },
  ];

  return (
    <div>
      <InqueryOrderModal
        open={enquiryModal}
        enquiry={enquiryDataModal}
        setOpen={setEnquiryModal}
        token={token}
      />
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
      >
        <Drawer
          title={"Place your offer rate"}
          placement="right"
          width={400}
          closable={true}
          onClose={() => setOpenNow("")}
          visible={Boolean(openNow)}
        >
          {Boolean(openNow) ? (
            <InqueryOrderDetails
              token={token}
              onClose={() => setOpenNow("")}
              createinqueryOrder={createinqueryOrder}
              setCreateInqueryOrder={setCreateInqueryOrder}
              createInqueryApi={createInqueryApi}
              productData={productData}
            />
          ) : (
            <></>
          )}
        </Drawer>

        <Row gutter={20}>
          <Col xs={24} sm={24} lg={24} xl={24} style={{ marginBottom: "20px" }}>
            <Table
              columns={columns}
              pagination={false}
              dataSource={inqueryOrderList}
              loading={loading}
              style={{ overflowX: "scroll" }}
            />

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                marginTop: "20px",
              }}
            >
              <CustomPagination
                current={inqueryOrders?.page}
                total={inqueryOrders?.totalDocs}
                onPageChange={(p) => setCurrentPage(p)}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
