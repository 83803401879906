import { ROLES } from "./permission-maps";

const CURRENT_USER_TYPE = ROLES.admin;

const PermissionsGateAdmin = ({
  children
}) => {
  if (CURRENT_USER_TYPE === ROLES.admin){
    return <>{children}</>;
  }else{
    return <></>
  }   
}

const PermissionsGateUser = ({
  children
})  => {

  if (CURRENT_USER_TYPE === ROLES.user) {
    return <>{children}</>;
  }else{
    return <></>
  }
}


export {PermissionsGateAdmin, PermissionsGateUser}
