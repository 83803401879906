import {
  CloseCircleOutlined,
  EditOutlined,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Col, Form, Input, Row } from "antd";
import React from "react";
import "../company.css";

export default function Representative({
  createCompany,
  setCreateCompany,
  onFinish,
  loadingButton,
  createCompanyApi,
  setStep,
  currentRepresentative,
  setCurrentRepresentative,
}) {
  const companyRepresentative =
    createCompany.companyRepresentative[currentRepresentative];

  return (
    <div>
      <Row justify="center" align="top">
        <Col span={24}>
          <span style={{ fontWeight: 600, marginBottom: "5px" }}>
            Representatives
          </span>
          {createCompany?.companyRepresentative?.map((value, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  setCurrentRepresentative(index);
                }}
                style={{
                  width: "100%",
                  display: "flex",
                  flex: "row",
                  justifyContent: "space-around",
                  alignItems: "center",
                  color: "black",
                  paddingTop: "30px",
                  position: "relative",
                  height: "60px",
                  border:
                    currentRepresentative === index
                      ? "5px solid #c69c6d"
                      : "1px solid gray",
                }}
              >
                {" "}
                <div
                  style={{
                    position: "absolute",
                    width: "60px",

                    top: "5px",
                    right: 0,
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <EditOutlined
                    onClick={() => {
                      setCurrentRepresentative(index);
                    }}
                    color="#c69c6d"
                  />
                  {createCompany.companyRepresentative?.length > 1 ? (
                    <CloseCircleOutlined
                      onClick={() => {
                        let { companyRepresentative } = createCompany;
                        companyRepresentative?.splice(index, 1);
                        setCreateCompany({
                          ...createCompany,
                        });
                        setCurrentRepresentative(0);
                      }}
                      color="#c69c6d"
                    />
                  ) : (
                    <></>
                  )}
                </div>
                <p>R. Name</p>
                <p>{value.name}</p>
              </div>
            );
          })}
        </Col>

        <div className="ant-company-form-2">
          <Col span={24}>
            <Form layout={"vertical"} onFinish={onFinish}>
              <Form.Item requiredMark label={"Representative Name"} required>
                <Input
                  allowClear
                  placeholder="Representative Name"
                  name={"name"}
                  value={companyRepresentative?.name}
                  onChange={(e) => {
                    companyRepresentative.name = e.target.value;
                    setCreateCompany({
                      ...createCompany,
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                requiredMark
                label={"Whatsapp Number"}
                // name='designation'
                required
                // initialValue={companyRepresentative?.designation}
              >
                <Input
                  allowClear
                  value={companyRepresentative?.whatsappNumber}
                  onChange={(e) => {
                    companyRepresentative.whatsappNumber = e.target.value;
                    setCreateCompany({
                      ...createCompany,
                    });
                  }}
                  size="large"
                  // style={{ width: '300px', float: 'left' }}
                  placeholder="whatsapp number"
                  name="whatsappNumber"
                />
              </Form.Item>
              <Form.Item
                requiredMark
                label={"Designation"}
                // name='designation'
                required
                // initialValue={companyRepresentative?.designation}
              >
                <Input
                  allowClear
                  value={companyRepresentative?.designation}
                  onChange={(e) => {
                    companyRepresentative.designation = e.target.value;
                    setCreateCompany({
                      ...createCompany,
                      companyRepresentative: [
                        ...createCompany.companyRepresentative,
                      ],
                    });
                  }}
                  size="large"
                  // style={{ width: '300px', float: 'left' }}
                  placeholder="Designation"
                  name="designation"
                />
              </Form.Item>
              {/* <Form.List name="phone" initialValue={phoneInputs}> */}
              {/* {(fields, { add, remove }) => (
                <>
                  {console.log(`fields`, fields)}
                  {fields.map((field, i) => (
                    <Space
                      size="small"
                      key={field.key}
                      style={{ display: "flex", marginBottom: 0 }}
                      align=""
                    > */}
              <Form.Item
                requiredMark
                label={"Phone Number"}
                required
                // {...field}
                initialValue={companyRepresentative?.phoneNumbers}
                // name={[field.name, "phone"]}
                // fieldKey={[field.fieldKey, "phone"]}
              >
                {companyRepresentative?.phoneNumbers.map((phone, i) => (
                  <div style={{ display: "flex", marginBottom: "10px" }}>
                    <Input
                      allowClear
                      value={phone}
                      onChange={(e) => {
                        let phoneNumbers = companyRepresentative?.phoneNumbers;
                        phoneNumbers[i] = e.target.value;
                        setCreateCompany({
                          ...createCompany,
                          companyRepresentative: [
                            ...createCompany.companyRepresentative,
                          ],
                        });
                      }}
                      name="phoneNumber"
                      size="large"
                      style={{ width: "300px" }}
                      placeholder="+923------"
                    />
                    <PlusOutlined
                      onClick={() => {
                        let phoneNumbers = companyRepresentative?.phoneNumbers;
                        phoneNumbers.push("");

                        setCreateCompany({
                          ...createCompany,
                          companyRepresentative: [
                            ...createCompany.companyRepresentative,
                          ],
                        });
                      }}
                      style={{
                        fontSize: "15px",
                        padding: "12px",
                        marginLeft: "5px",
                        backgroundColor: "#c69c6d",
                        color: "white",
                        textAlign: "left",
                      }}
                    />

                    {companyRepresentative?.phoneNumbers?.length > 1 ? (
                      <MinusOutlined
                        onClick={() => {
                          let { phoneNumbers } = companyRepresentative;
                          phoneNumbers.splice(i, 1);
                          setCreateCompany({
                            ...createCompany,
                            companyRepresentative: [
                              ...createCompany.companyRepresentative,
                            ],
                          });
                        }}
                        style={{
                          fontSize: "15px",
                          padding: "12px",
                          marginLeft: "5px",
                          backgroundColor: "#c69c6d",
                          color: "white",
                          textAlign: "left",
                        }}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                ))}
              </Form.Item>
              {companyRepresentative?.bankDetail?.map((value, index) => {
                return (
                  <Row align="middle" gutter={[12, 12]}>
                    <Col span={16}>
                      <Form.Item
                        // requiredMark
                        label={"Bank Name"}
                        // required
                        // {...field}
                        // name={[field.name, "bankAccount"]}
                        // fieldKey={[field.fieldKey, "bankAccount"]}
                      >
                        <Input
                          allowClear
                          size="large"
                          // style={{ width: "300px" }}
                          value={value.bankName}
                          onChange={(e) => {
                            let bankDetail = companyRepresentative?.bankDetail;
                            bankDetail[index].bankName = e.target.value;
                            setCreateCompany({
                              ...createCompany,
                              companyRepresentative: [
                                ...createCompany.companyRepresentative,
                              ],
                            });
                          }}
                          name="bankName"
                          placeholder="Which Bank"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <PlusOutlined
                        onClick={() => {
                          let bankDetail = companyRepresentative?.bankDetail;
                          bankDetail.push({
                            bankName: "",
                            accountNo: "",
                          });

                          setCreateCompany({
                            ...createCompany,
                            companyRepresentative: [
                              ...createCompany.companyRepresentative,
                            ],
                          });
                        }}
                        style={{
                          fontSize: "15px",
                          padding: "12px",
                          marginLeft: "5px",
                          backgroundColor: "#c69c6d",
                          color: "white",
                          textAlign: "left",
                        }}
                      />
                    </Col>

                    <Col span={16}>
                      <Form.Item
                        // requiredMark
                        label={"Account Number"}
                        // required
                        // {...field}
                        // name={[field.name, "bankAccount"]}
                        // fieldKey={[field.fieldKey, "bankAccount"]}
                      >
                        <Input
                          allowClear
                          size="large"
                          // style={{ width: "300px" }}
                          placeholder="Account Number"
                          value={value.accountNo}
                          onChange={(e) => {
                            let bankDetail = companyRepresentative?.bankDetail;
                            bankDetail[index].accountNo = e.target.value;
                            setCreateCompany({
                              ...createCompany,
                              companyRepresentative: [
                                ...createCompany.companyRepresentative,
                              ],
                            });
                          }}
                          name="accountNo"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      {companyRepresentative?.bankDetail?.length > 1 ? (
                        <MinusOutlined
                          onClick={() => {
                            let { bankDetail } = companyRepresentative;
                            bankDetail.splice(index, 1);
                            setCreateCompany({
                              ...createCompany,
                              companyRepresentative: [
                                ...createCompany.companyRepresentative,
                              ],
                            });
                          }}
                          style={{
                            fontSize: "15px",
                            padding: "12px",
                            marginLeft: "5px",
                            backgroundColor: "#c69c6d",
                            color: "white",
                            textAlign: "left",
                          }}
                        />
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                );
              })}{" "}
              <Form.Item>
                <Row gutter={[12, 12]}>
                  <Col span={22}>
                    <Button
                      // type="primary"
                      // htmlType="submit"
                      onClick={() => setStep(1)}
                      style={{
                        backgroundColor: "#c69c6d",
                        color: "white",
                        border: "none",
                      }}
                    >
                      Back
                    </Button>
                  </Col>
                  <Col span={22}>
                    <Button
                      disabled={
                        !companyRepresentative?.name ||
                        !companyRepresentative?.phoneNumbers.length ||
                        // !companyRepresentative?.bankDetail.length ||
                        !companyRepresentative?.designation
                          ? true
                          : false
                      }
                      type="primary"
                      htmlType="submit"
                      onClick={() => {
                        setCreateCompany({
                          ...createCompany,
                          companyRepresentative: [
                            ...createCompany.companyRepresentative,
                            {
                              name: "",
                              phoneNumbers: [""],
                              designation: "",
                              bankDetail: [
                                {
                                  bankName: "",
                                  accountNo: "",
                                },
                              ],
                            },
                          ],
                        });
                        setCurrentRepresentative(++currentRepresentative);
                      }}
                      style={{
                        backgroundColor: "#c69c6d",
                        color: "white",
                        border: "none",
                      }}
                    >
                      Add an other Representative
                    </Button>
                  </Col>

                  <Col span={22}>
                    <Button
                      disabled={
                        !companyRepresentative?.name ||
                        !companyRepresentative?.phoneNumbers.length ||
                        // !companyRepresentative?.bankDetail.length ||
                        !companyRepresentative?.designation
                          ? true
                          : false
                      }
                      type="primary"
                      htmlType="submit"
                      onClick={createCompanyApi}
                      style={{
                        backgroundColor: "#c69c6d",
                        color: "white",
                        border: "none",
                      }}
                      loading={loadingButton}
                    >
                      {" "}
                      {createCompany.hasOwnProperty("_id")
                        ? "Update Company"
                        : "Create Company"}
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </Col>
        </div>
      </Row>
    </div>
  );
}
