import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCategories } from "../RestServices/ecommerenceService";

export default function useCategoryAndSubCategories({ parentValue }) {
  const [categories, setCategory] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const { token } = useSelector((state) => state.Authentication.user);

  useEffect(() => {
    getParentCategoryRequest();
  }, [parentValue]);

  useEffect(() => {
    if (parentValue) getSubCategoriesRequest();
  }, [parentValue]);

  const getParentCategoryRequest = async () => {
    try {
      const data = await getCategories(token, {});
      setCategory(data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getSubCategoriesRequest = async () => {
    try {
      const data = await getCategories(token, { parentValue: parentValue });
      setSubCategories(data.data);
    } catch (err) {
      console.log(err);
    }
  };

  return { categories, subCategories, getParentCategoryRequest };
}
