import {
  loginFailed,
  loginLoading,
  loginSuccessfull,
} from "../Actions/authentication";
import { message } from "antd";
import { authService } from "../../shared";
import axios from "axios";
import { setToken } from "../../utils/token";

export const signinActionCreater = ({ email, password, props }) => async (
  dipsatch
) => {
  dipsatch(loginLoading());
  try {
    const resp = await axios.post(
      authService + "/api/v1/customers/signIn",
      {
        email: email,
        password,
      },
      {
        headers: {
          "api-key": "poultrybaba@1234Sumandri",
        },
      }
    );
    dipsatch(loginSuccessfull(resp.data.customer));
    setToken(resp.data.customer?.token);
    props.history.push('/');
  } catch (err) {
    dipsatch(loginFailed(err.message));
    if (err.response) {
      message.error(err.response.data.message);
    } else {
      message.error(err.message);
    }
  }
};
