import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { getPosts } from "../../../RestServices/postingService";
import { checkPermission } from "../../../shared";
import { Sorter } from "../../../utils/sorter";
import Table from "../../../utils/Table";
import AddUser from "../../AppUsers/AddUser";
import CustomPagination from "../../Pagination";

const columns = [
  {
    title: "Sr",
    dataIndex: "autoId",
    sorter: {
      compare: Sorter.DEFAULT,
      multiple: 5
    }
  },
  {
    title: "Name",
    dataIndex: "customerProfile",
    render: (customerProfile) =>
    `${customerProfile.name}`,
    sorter: {
      compare: Sorter.DEFAULT,
      multiple: 4
    }
  },
  {
    title: "Phone Number",
    dataIndex: "customerProfile",
    render: (customerProfile) =>
    `${customerProfile.phoneNumber}`,
  },
  {
    title: "Category",
    dataIndex: "postDetails",
    render: (postDetails) =>
    `${postDetails.subType}`,
  },
  {
    title: "Demand",
    dataIndex: "postDetails",
    render: (postDetails) =>
      `${postDetails?.demand ||
        postDetails?.demandPerKg ||
        postDetails?.demandPerBird ||
        "N/A"}`,
        sorter: {
          compare: Sorter.DEFAULT,
          multiple: 3
        }
  },
  {
    title: "Total Unit",
    dataIndex: "postDetails",
    render: (postDetails) =>
    `${postDetails?.numberOfBirds ||
      postDetails?.totalWeightInKg ||
      postDetails?.quantity ||
      "N/A"}`,
  },
  {
    title: "Date/Time",
    dataIndex: "updatedAt",
    render: (updatedAt) =>
      `${new Date(updatedAt).toLocaleDateString()}`,
      sorter: {
        compare: Sorter.DEFAULT,
        multiple: 2
      }
  },
  {
    title: "Sell out",
    dataIndex: "postSold",
    render: (postSold) =>
      `${postSold ? "Post Sold" : "Not sale"}`,
      sorter: {
        compare: Sorter.DEFAULT,
        multiple: 1
      }
  },
];

export default function MarketSummary({
  token,
  permissions,
  userId,
  startDate,
  endDate,
}) {
  const [posts, setPosts] = useState({});
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const [customerDetails, setCustomerDetails] = useState(undefined);

  const [userListModal, setUserListModal] = useState(false);

  useEffect(() => {
    (async () => {
      const posts = await getPosts(token, {
        crmUserId: checkPermission(permissions, "AllResources")
          ? undefined
          : userId,
        type: "MURGHI MANDI",
        pageNo: page,
        startDate: startDate ? new Date(startDate).toDateString() : "",
        endDate: endDate ? new Date(endDate).toDateString() : "",
      });

      setPosts(posts.posts);
      setData(posts.posts.docs);
    })();
  }, [token, page]);

  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
      >
        <AddUser
          selectedUser={customerDetails}
          submitForm={() => setUserListModal(false)}
          isFormLoading={false}
          isOpen={userListModal}
          closeModal={() => setUserListModal(false)}
          inputHandler={console.log}
        />
        <Row gutter={20}>
          <Col xs={24} sm={24} lg={24} xl={24} style={{ marginBottom: "20px" }}>
            <Table
              columns={columns}
              pagination={false}
              dataSource={data}
              loading={loading}
              onRow={(recordPost, rowIndex) => {
                return {
                  onClick: event => { 
                    setUserListModal(true);
                    setCustomerDetails(recordPost?.customerProfile);
                  },
                };
              }}
              style={{ overflowX: "scroll" }}
            />
            
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                marginTop: "20px",
              }}
            >
              <CustomPagination
                current={posts.page}
                total={posts?.totalDocs}
                onPageChange={(p) => setPage(p)}
              />
            </div>
          </Col>
         
          {/* <Col
            xs={24}
            sm={24}
            lg={6}
            xl={6}
            style={{
              padding: 0,
              background: "#fff",
              boxShadow: "0px 12px 23px rgba(62, 73, 84, 0.04)",
              marginBottom: "20px",
            }}
          >
          </Col> */}
        </Row>
      </div>
    </div>
  );
}
