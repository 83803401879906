import React, { Component } from 'react';
import { Switch } from 'react-router-dom';
import Dashboard from './Dashboard';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import Signin from './Signin';

class Main extends Component {

  render() {
    return (
      <Switch>
        <PublicRoute restricted={true} component={Signin} path="/signin" exact />
        <PrivateRoute component={Dashboard} path="/" exact />
      </Switch>
    );
  }
}

export default Main;
