import { SearchOutlined } from "@material-ui/icons";
import { Button, Col, DatePicker, Input, Row, Spin, Tabs } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Select } from "antd";

import {
  getCommissionsAmount,
  getStatBidding,
} from "../../RestServices/postingService";
import { checkPermission, sumCalculate } from "../../shared";
import StatDashboard from "../Crm/statDashboard/StatDashboard";
import StatDetails from "../Crm/statDetails/StatDetails";
import MurgiMandiOffer from "../Dashboard/DashboardBody/MurghiMundiOffer";
import "./crm.css";
import MughiMandiDashboards from "./MurghiMandi/Dashboards";
import MarketSummary from "./MarketSumary/MarketSummay";

const { TabPane } = Tabs;
const { Option } = Select;
const { RangePicker } = DatePicker;
export default function MurghiMandi({ ...props }) {
  const [phoneNumberFilter, setPhoneNumberFilter] = useState(false);
  const showModal = () => {
    setPhoneNumberFilter(true);
  };
  const { allcities } = useSelector((state) => state.Cities);

  const [addPhoneNumberLoading, setAddPhoneNumberLoading] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState("");

  const [selectedDate, setSelectedDate] = useState(
    moment().format("MMMM YYYY"),
  );

  const [modelAddNumVisible, setModelAddNumVisible] = useState(false);

  const [taskDetail, setTaskDetail] = useState(undefined);

  const [monthCommission, setMonthlyCommission] = useState([]);

  const {
    token,
    _id: userId,
    profileDetails: { permissions },
  } = useSelector((state) => state.Authentication.user);

  const [finalBidForParent, setFinalBidForParent] = useState("");
  const [paymentStatusOwner, setPaymentStatusOwner] = useState("");
  const [paymentStatusClient, setPaymentStatusClient] = useState("");

  const onChangeDatePicker = (date, dateString) => {
    setSelectedDate(date?.format("MMMM YYYY"));
  };

  const [dateRange, setDateRage] = useState({
    startDate: moment(),
    endDate: moment(),
  });

  // INITIAL

  const onChangeTag = (key) => {
    setSelectTab(key);
  };

  const [selectTab, setSelectTab] = useState("1");

  const [stat, setStat] = useState(undefined);
  const [totalStat, setTotalStat] = useState(0);
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingContent, setLoadingContent] = useState(false);

  useEffect(() => {
    getStatRequest();
    getCommission();
  }, [selectedDate]);

  const [city, setCity] = useState("");

  const getStatRequest = async () => {
    try {
      setLoadingContent(true);
      setTimeout(async () => {
        const response = await getStatBidding(
          {
            crmUserId: checkPermission(permissions, "AllResources")
              ? undefined
              : userId,
            parentId: true,
            startDate: new Date(dateRange.startDate),
            endDate: new Date(dateRange.endDate),
          },
          token,
        );

        setTotalStat(
          sumCalculate(response.biddings?.bidding.map((bid) => bid.count)),
        );
        setStat(response.biddings);
        setLoadingContent(false);
      }, 2000);
    } catch (err) {
      console.log(err);
      setLoadingContent(false);
    }
  };

  const getCommission = async (taskId) => {
    try {
      setLoadingButton(true);
      const data = await getCommissionsAmount(
        {
          referenceOf: checkPermission(permissions, "AllResources")
            ? undefined
            : userId,
          month: selectedDate,
        },
        token,
      );
      setMonthlyCommission(data);
      setLoadingButton(false);
    } catch (err) {
      console.log(err);
      setLoadingButton(false);
    }
  };

  const handleRefreshButton = () => {
    getCommission();
  };

  const handleRefreshContent = () => {
    getStatRequest();
  };

  const [statDetailModal, setStatDetailModal] = useState([]);
  const dateFormat = "YYYY/MM/DD";

  return (
    <div style={{ padding: "10px" }}>
      <StatDetails
        statDetailModal={statDetailModal}
        setStatDetailModal={setStatDetailModal}
        selectedDate={selectedDate}
      />

      <Row gutter={[16, 16]}>
        <Col>
          <div className="CrmHeading">Welcome to CRM System!</div>
          <div className="crmSystempm">Poultry Baba CRM System</div>
        </Col>
        <Col>
          {loadingButton ? (
            <div
              className="refreshButton1"
            >
              <Spin size="large" />
            </div>
          ) : (
            <div
              className="refreshButton1"
              onClick={handleRefreshButton}
            >
              <div>
                <div className="tearning1">Total Earnings</div>
                <div className="t_amount1">
                  Rs{" "}
                  {(() => {
                    let total = 0;
                    for (let value of monthCommission) {
                      total += value?.sumQuantity;
                    }
                    return total.toFixed(2);
                  })()}
                </div>
              </div>
              <div style={{ marginLeft: "10px" }}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15 0C6.72 0 0 6.72 0 15C0 23.28 6.72 30 15 30C23.28 30 30 23.28 30 15C30 6.72 23.28 0 15 0ZM6.9 13.845C7.155 12.06 7.95 10.455 9.195 9.195C12.195 6.21 16.92 6.045 20.13 8.655V7.23C20.13 6.615 20.64 6.105 21.255 6.105C21.87 6.105 22.38 6.615 22.38 7.23V11.235C22.38 11.85 21.87 12.36 21.255 12.36H17.25C16.635 12.36 16.125 11.85 16.125 11.235C16.125 10.62 16.635 10.11 17.25 10.11H18.375C16.05 8.49 12.84 8.715 10.77 10.785C9.87 11.685 9.3 12.855 9.105 14.16C9.03 14.715 8.55 15.12 7.995 15.12C7.935 15.12 7.89 15.12 7.83 15.105C7.245 15.03 6.81 14.46 6.9 13.845ZM20.805 20.805C19.2 22.41 17.1 23.205 15 23.205C13.17 23.205 11.355 22.56 9.855 21.345V22.755C9.855 23.37 9.345 23.88 8.73 23.88C8.115 23.88 7.605 23.37 7.605 22.755V18.75C7.605 18.135 8.115 17.625 8.73 17.625H12.735C13.35 17.625 13.86 18.135 13.86 18.75C13.86 19.365 13.35 19.875 12.735 19.875H11.61C13.935 21.495 17.145 21.27 19.215 19.2C20.115 18.3 20.685 17.13 20.88 15.825C20.97 15.21 21.525 14.775 22.155 14.865C22.77 14.955 23.19 15.525 23.115 16.14C22.845 17.955 22.05 19.56 20.805 20.805Z"
                    fill="#1DBF73"
                  />
                </svg>
              </div>
            </div>
          )}
        </Col>

        <Col style={{ flexGrow: 1 }}></Col>
        <Col>
          <div>
            <DatePicker
              className="date-picker-crm"
              value={moment(selectedDate)}
              onChange={onChangeDatePicker}
              picker="month"
              style={{ marginBottom: "5px" }}
            />
          </div>
        </Col>
      </Row>

      <Row align="middle">
        <Col>
          <StatDashboard
            setStatDetailModal={setStatDetailModal}
            monthCommission={monthCommission}
          />
          <div
            style={{
              borderBottom: "2px",
              margin: "20px",
              borderBottomColor: "#FCB539",
              borderStyle: "solid",
              marginBottom: "20px",
            }}
          ></div>
        </Col>
      </Row>

      <Row align="center" gutter={[16, 16]} justify="end">
        <Col>
          {loadingContent ? (
            <div
              className="refreshButton_button"
            >
              <Spin />
            </div>
          ) : (
            <div
              className="refreshButton_button"
              onClick={handleRefreshContent}
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 3C9.72 3 3 9.72 3 18C3 26.28 9.72 33 18 33C26.28 33 33 26.28 33 18C33 9.72 26.28 3 18 3ZM9.9 16.845C10.155 15.06 10.95 13.455 12.195 12.195C15.195 9.21 19.92 9.045 23.13 11.655V10.23C23.13 9.615 23.64 9.105 24.255 9.105C24.87 9.105 25.38 9.615 25.38 10.23V14.235C25.38 14.85 24.87 15.36 24.255 15.36H20.25C19.635 15.36 19.125 14.85 19.125 14.235C19.125 13.62 19.635 13.11 20.25 13.11H21.375C19.05 11.49 15.84 11.715 13.77 13.785C12.87 14.685 12.3 15.855 12.105 17.16C12.03 17.715 11.55 18.12 10.995 18.12C10.935 18.12 10.89 18.12 10.83 18.105C10.245 18.03 9.81 17.46 9.9 16.845ZM23.805 23.805C22.2 25.41 20.1 26.205 18 26.205C16.17 26.205 14.355 25.56 12.855 24.345V25.755C12.855 26.37 12.345 26.88 11.73 26.88C11.115 26.88 10.605 26.37 10.605 25.755V21.75C10.605 21.135 11.115 20.625 11.73 20.625H15.735C16.35 20.625 16.86 21.135 16.86 21.75C16.86 22.365 16.35 22.875 15.735 22.875H14.61C16.935 24.495 20.145 24.27 22.215 22.2C23.115 21.3 23.685 20.13 23.88 18.825C23.97 18.21 24.525 17.775 25.155 17.865C25.77 17.955 26.19 18.525 26.115 19.14C25.845 20.955 25.05 22.56 23.805 23.805Z"
                  fill="white"
                />
              </svg>
              <p className="tearning">Refresh</p>
            </div>
          )}
        </Col>

        <Col>
          <RangePicker
            onChange={([startDate, endDate]) =>
              setDateRage({
                startDate,
                endDate,
              })
            }
            defaultValue={[dateRange.startDate, dateRange.endDate]}
            format={dateFormat}
          />
        </Col>

        <Col>
          <Input
            style={{ borderRadius: "50px" }}
            prefix={<SearchOutlined />}
            className="search"
            type="text"
            placeholder="Search"
          />
        </Col>

        <Col>
          <Select
            className="add_city_picker"
            defaultValue=""
            value={city}
            style={{ width: 120 }}
            onChange={(e) => setCity(e)}
          >
            <Option value="">Select city</Option>
            {allcities?.map((city) => (
              <Option value={city?.en}>{city?.en}</Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Row>
        <Col lg={24} md={24} sm={24} xs={24}>
          <div className="tab-group">
            <Row
              gutter={[16, 16]}
              style={{
                marginTop: "20px",
                width: "100%",
                display: "flex",
                flexWrap: "nowrap",
                overflowX: "auto",
              }}
            >
              <Col lg={18} md={18} sm={24} xs={24}>
                <Tabs
                  activeKey={selectTab}
                  onChange={onChangeTag}
                  defaultActiveKey={"1"}
                >
                  <TabPane tab="Dashboards" key="1">
                    <MughiMandiDashboards
                      startDate={dateRange.startDate}
                      endDate={dateRange.endDate}
                      city={city}
                      commodity={undefined}
                      permissions={permissions}
                    />
                  </TabPane>
                  <TabPane tab="Offers" key="2">
                    <MurgiMandiOffer
                      finalBidForParent={finalBidForParent}
                      paymentStatusOwner={paymentStatusOwner}
                      paymentStatusClient={paymentStatusClient}
                      userId={userId}
                      token={token}
                      totalStat={totalStat}
                      startDate={dateRange.startDate}
                      endDate={dateRange.endDate}
                      permissions={permissions}
                    />
                  </TabPane>

                  <TabPane tab="Summary" key="3">
                    <MarketSummary
                      token={token}
                      permissions={permissions}
                      userId={userId}
                      startDate={dateRange.startDate}
                      endDate={dateRange.endDate}
                    />
                  </TabPane>
                  {/* <TabPane tab="Commission Rates" key="3">
                    <MurgiMandiOffer userId={userId} />
                  </TabPane>
                  <TabPane tab="Market Summary" key="4">
                    <MurgiMandiOffer userId={userId} />
                  </TabPane> */}
                </Tabs>
              </Col>

              <Col className="div_back" lg={6} md={6} sm={24} xs={24}>
                <Row
                  style={{ borderBottom: "1px solid #F8F8F8", padding: "10px" }}
                >
                  <Col span={24} className="overall">
                    Overall Stat
                  </Col>
                </Row>

                <div
                  style={{
                    borderBottom: "1px solid #F8F8F8",
                    padding: "10px",
                    height: "50vh",
                    overflow: "auto",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {stat?.bidding &&
                    stat?.bidding?.map((value) => (
                      <div className="contain_number">
                        <div className="inner-contain-number">
                          <div className="circle">
                            {((value.count / totalStat) * 100).toFixed(0)}%
                          </div>
                          <div>
                            <h3 className="number_count">{value.count}</h3>
                            <h3 className="number_heading">
                              {value.type.split("_").join(" ")}
                            </h3>
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            if (selectTab !== "2") {
                              setSelectTab("2");
                            }
                            setFinalBidForParent(value.type);
                            setPaymentStatusOwner("");
                            setPaymentStatusClient("");
                          }}
                          className="view_list"
                        >
                          View list
                        </div>
                      </div>
                    ))}

                  {stat?.paidStatus?.length && (
                    <>
                      <div className="contain_number">
                        <div className="inner-contain-number">
                          <div className="circle">
                            {(
                              (stat.paidStatus[0].customerPaid / totalStat) *
                              100
                            ).toFixed(0)}
                            %
                          </div>
                          <div>
                            <h3 className="number_count">
                              {stat.paidStatus[0].customerPaid}
                            </h3>
                            <h3 className="number_heading">Customer Paid</h3>
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            if (selectTab !== "2") {
                              setSelectTab("2");
                            }
                            setFinalBidForParent("");
                            setPaymentStatusOwner("");
                            setPaymentStatusClient("PAID");
                          }}
                          className="view_list"
                        >
                          View list
                        </div>
                      </div>
                      <div className="contain_number">
                        <div className="inner-contain-number">
                          <div className="circle">
                            {(
                              (stat.paidStatus[0].ownerPaid / totalStat) *
                              100
                            ).toFixed(0)}
                            %
                          </div>
                          <div>
                            <h3 className="number_count">
                              {stat.paidStatus[0].ownerPaid}
                            </h3>
                            <h3 className="number_heading">Owner Paid</h3>
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            if (selectTab !== "2") {
                              setSelectTab("2");
                            }
                            setFinalBidForParent("");
                            setPaymentStatusOwner("PAID");
                            setPaymentStatusClient("");
                          }}
                          className="view_list"
                        >
                          View list
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
}

//  <Col>
//               <div className="d-flex flex-column">
//                 <h3 className="t">Post Score</h3>
//                 <Button
//                   style={{ backgroundColor: "lightpink", marginRight: "5px" }}
//                 >
//                   {(() => {
//                     let postQuantity = 0;
//                     for (let com of monthCommission) {
//                       if (
//                         com._id === "Grain Market" ||
//                         com._id === "Poultry Bazar" ||
//                         com._id === "Poultry Market" ||
//                         com._id === "Pets Point" ||
//                         com._id === "FOORI ZRORRAT" ||
//                         com._id === "Meri Zameen" ||
//                         com._id === "Meri Rozi"
//                       ) {
//                         postQuantity += com.quantity;
//                       }
//                     }

//                     return postQuantity ? postQuantity : 0;
//                   })()}
//                 </Button>
//                 {(() => {
//                   let amount = 0;
//                   for (let com of monthCommission) {
//                     if (
//                       com._id === "Grain Market" ||
//                       com._id === "Poultry Bazar" ||
//                       com._id === "Poultry Market" ||
//                       com._id === "Pets Point" ||
//                       com._id === "FOORI ZRORRAT" ||
//                       com._id === "Meri Zameen" ||
//                       com._id === "Meri Rozi"
//                     ) {
//                       amount += com.sumQuantity;
//                     }
//                   }

//                   return (
//                     <span className="p">
//                       {" "}
//                       RS.
//                       {amount ? amount.toFixed(2) : 0}{" "}
//                     </span>
//                   );
//                 })()}
//                 {/* <span className='p'>Rs. 2000</span> */}
//               </div>
//             </Col>
//             <Col>
//               <div className="d-flex flex-column">
//                 <h3 className="t">Review Score</h3>
//                 <Button
//                   style={{ backgroundColor: "lightpink", marginRight: "5px" }}
//                 >
//                   {(() => {
//                     const quantity = monthCommission.find(
//                       (com) => com._id === "Review",
//                     );
//                     return quantity ? quantity.quantity : 0;
//                   })()}
//                 </Button>
//                 {(() => {
//                   let amount = monthCommission.find(
//                     (com) => com._id === "Review",
//                   );
//                   return (
//                     <span className="p">
//                       {" "}
//                       RS.
//                       {amount ? amount.sumQuantity.toFixed(2) : 0}{" "}
//                     </span>
//                   );
//                 })()}
//                 {/* <span className='p'>Rs. 2000</span> */}
//               </div>
//             </Col>

//             <Col>
//               <div className="d-flex flex-column">
//                 <h3 className="t">Murgi Mandi</h3>
//                 <Button
//                   style={{ backgroundColor: "lightpink", marginRight: "5px" }}
//                 >
//                   {(() => {
//                     const quantity = monthCommission.find(
//                       (com) => com._id === "MURGHI MANDI",
//                     );
//                     return quantity ? quantity.quantity : 0;
//                   })()}
//                 </Button>
//                 {(() => {
//                   let amount = monthCommission.find(
//                     (com) => com._id === "MURGHI MANDI",
//                   );
//                   return (
//                     <span className="p">
//                       {" "}
//                       RS.
//                       {amount ? amount.sumQuantity.toFixed(2) : 0}{" "}
//                     </span>
//                   );
//                 })()}

//                 {/* <span className='p'>Rs. 2000</span> */}
//               </div>
//             </Col>

//             <Col>
//               <div className="d-flex flex-column">
//                 <h3 className="t">E commerce</h3>
//                 <Button
//                   style={{ backgroundColor: "lightpink", marginRight: "5px" }}
//                 >
//                   N/A
//                 </Button>
//                 {(() => {
//                   return <span className="p">Rs 0</span>;
//                 })()}
//                 {/* <span className='p'>Rs. 2000</span> */}
//               </div>
//             </Col>

//             <Col>
//               <div className="d-flex flex-column">
//                 <h3 className="t">Fori Zarorat</h3>
//                 <Button
//                   style={{ backgroundColor: "lightpink", marginRight: "5px" }}
//                 >
//                   {(() => {
//                     const quantity = monthCommission.find(
//                       (com) => com._id === "FOORI ZRORRAT",
//                     );
//                     return quantity ? quantity.quantity : 0;
//                   })()}
//                 </Button>
//                 {(() => {
//                   let amount = monthCommission.find(
//                     (com) => com._id === "FOORI ZRORRAT",
//                   );
//                   return (
//                     <span className="p">
//                       {" "}
//                       RS.
//                       {amount ? amount.sumQuantity.toFixed(2) : 0}{" "}
//                     </span>
//                   );
//                 })()}
//                 {/* <span className='p'>Rs. 2000</span> */}
//               </div>
//             </Col>

{
  /* <TabPane tab="Overall Statistics" key="3">
                <UserStat {...props} userDetails={userDetails} />
                <Row>
                  <Col lg={6} md={12} sm={12} xs={24}>
                    <div
                      className="order-box"
                      style={{
                        background: "linear-gradient(45deg, #FC9C39, #FBB438)",
                      }}
                    >
                      <div className="order-text">Total Posts</div>
                      <div className="order-count">
                        {
                          <span className="NumberStyle">
                            {(() => {
                              let total = 0;
                              if (userAndProfile.length) {
                                for (let value of userAndProfile) {
                                  total += value?.TotalCount;
                                }
                                return total;
                              } else {
                                return 0;
                              }
                            })()}
                          </span>
                        }
                      </div>
                    </div>
                  </Col>
                  <Col lg={9} md={12} sm={14} xs={24}>
                    <div className="order-box order-box2">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <div
                          onClick={() => {
                            props.setSelected(
                              "Dashboard/User",
                              "Crm Golden User",
                            );
                          }}
                          style={{
                            flexDirection: "column",
                            flexGrow: 1,
                            paddingTop: "12px",
                            paddingBottom: "12px",
                            marginRight: "5px",
                            background:
                              "linear-gradient(45deg, #FC9C39, #FBB438)",
                          }}
                        >
                          <div className="order-text2 order-text-lg">
                            Golden <br /> Members
                          </div>
                          <div className="order-count2">
                            {userDetails.activeUser + 20}
                          </div>
                        </div>

                        <div
                          onClick={() => {
                            props.setSelected(
                              "Dashboard/User",
                              "Crm Rising User",
                            );
                          }}
                          style={{
                            flexDirection: "column",
                            flexGrow: 1,
                            paddingTop: "12px",
                            paddingBottom: "12px",
                            background:
                              "linear-gradient(0deg, #FC9C39, #FBB438)",
                          }}
                        >
                          <div className="order-text2  order-text-lg">
                            Rising <br />
                            Members
                          </div>

                          <div className="order-count2">
                            {userDetails.activeUser > 25
                              ? userDetails.activeUser - 25
                              : 0}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  {/* <Col lg={9} md={24} sm={24} xs={24}>
                    <div
                      className='order-box ml-sm-0'
                      style={{
                        marginLeft: '20px',
                        background: 'linear-gradient(45deg, #FFAAD5, #F64AEE)',
                      }}
                    >
                      <div className='order-text'>Total Fans</div>
                      <div className='order-count'>
                        {userDetails.activeUser}
                      </div>
                    </div>
                  </Col>

                  */
}

//     <RejectUSer />
//   </Row>
//   {/* <Row>
//         <Col lg={24} md={24} sm={24} xs={24}>
//           <div style={{overflowX: 'scroll'}}>
//             <Table1/>
//           </div>
//         </Col>
//       </Row> */}
// </TabPane>
// <TabPane tab="Murghi mandi Stat" key="4">
//   <MurghiMundiState userId={userId} />
// </TabPane>

// const menu = (
//   <Menu>
//     <Menu.Item key="1">
//       {/* <Row gutter={[12, 12]}>
//         <Col span={16}>Assigned Numbers</Col>
//         <Col span={3}></Col>
//         <Col span={5}>12</Col>
//       </Row> */}
//     </Menu.Item>
//     <Menu.Item key="2">
//       {" "}
//       <Row gutter={[12, 12]}>
//         <Col span={16}>Assigned Download</Col>
//         <Col span={3}></Col>
//         <Col span={5}>{taskDetail ? taskDetail.downloads : "Not assign"}</Col>
//       </Row>
//     </Menu.Item>
//     <Menu.Item key="2">
//       {" "}
//       <Row gutter={[12, 12]}>
//         <Col span={16}>Assigned Review</Col>
//         <Col span={3}></Col>
//         <Col span={5}>{taskDetail ? taskDetail.reviews : "Not assign"}</Col>
//       </Row>
//     </Menu.Item>
//     <Menu.Item key="3">
//       <Row gutter={[12, 12]}>
//         <Col span={16}>Assigned Post's</Col>
//         <Col span={3}></Col>
//         <Col span={5}>{taskDetail ? taskDetail.posts : "Not assign"}</Col>
//       </Row>
//     </Menu.Item>
//   </Menu>
// );
