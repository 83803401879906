/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Button, Col, Image, Modal, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCustomers } from "../../../RestServices/authService";
import { getBiddings } from "../../../RestServices/postingService";
import AddUser from "../../AppUsers/AddUser";
import Message from "../../message/Message";
import PaySlip from "../../payslip/Payslip";
import "./postBiddingDetails.css";

export default function PostDetailsModal({
  isModalVisible,
  handleOk,
  selectedBit,
  handleCancel,
}) {
  const [paySlip, setPaySlip] = useState(false);
  const [paySlipBid, setPaySlipBid] = useState(false);
  const { token } = useSelector((state) => state.Authentication.user);

  const [biddingGet, setBiddingGet] = useState([]);
  const [placement, setPlacement] = useState("left");

  const [userDetailModal, setUserDetailModal] = useState(false);
  const [userList, setUserList] = useState([null, null]);
  useEffect(() => {
    (async () => {
      try {
        const userList = [null, null];
        const { customers } = await getCustomers(token, {
          userId: selectedBit.customerId,
        });
        if (customers) {
          userList[0] = customers.docs[0];
        }
        const { customers: ownerCustomer } = await getCustomers(token, {
          userId: selectedBit.postOwnerId,
        });
        if (ownerCustomer) {
          userList[1] = ownerCustomer.docs[0];
        }
        setUserList(userList);
      } catch (err) {
        console.log(err);
      }
    })();
  }, [isModalVisible, paySlip, token]);

  useEffect(() => {
    (async () => {
      if (selectedBit) {
        try {
          const response = await getBiddings(
            {
              postOwnerId: selectedBit.postOwnerId,
              customerId: selectedBit.customerId,
              postId: selectedBit.postId,
            },
            token,
          );
          setBiddingGet(response.biddings.docs);
        } catch (err) {
          setBiddingGet([]);
        }
      }
    })();
  }, [selectedBit, token]);

  console.log("userList", userList, selectedBit);
  const [userToShowIndex, setUserToShowIndex] = useState(0);
  return (
    <Modal
      title={
        <>
          Details and Cards
          <svg
            style={{
              marginTop: "10px",
              marginLeft: "10px",
              position: "relative",
              top: "5px",
            }}
            width="66"
            height="20"
            viewBox="0 0 66 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M37.248 8.288C37.248 8.88533 37.104 9.44533 36.816 9.968C36.5387 10.4907 36.096 10.912 35.488 11.232C34.8907 11.552 34.1333 11.712 33.216 11.712H31.344V16H29.104V4.832H33.216C34.08 4.832 34.816 4.98133 35.424 5.28C36.032 5.57867 36.4853 5.98933 36.784 6.512C37.0933 7.03467 37.248 7.62667 37.248 8.288ZM33.12 9.904C33.7387 9.904 34.1973 9.76533 34.496 9.488C34.7947 9.2 34.944 8.8 34.944 8.288C34.944 7.2 34.336 6.656 33.12 6.656H31.344V9.904H33.12ZM41.0784 8.512C41.3664 8.04267 41.7397 7.67467 42.1984 7.408C42.6677 7.14133 43.201 7.008 43.7984 7.008V9.36H43.2064C42.5024 9.36 41.969 9.52533 41.6064 9.856C41.2544 10.1867 41.0784 10.7627 41.0784 11.584V16H38.8384V7.136H41.0784V8.512ZM46.4431 6.08C46.0485 6.08 45.7178 5.95733 45.4511 5.712C45.1951 5.456 45.0671 5.14133 45.0671 4.768C45.0671 4.39467 45.1951 4.08533 45.4511 3.84C45.7178 3.584 46.0485 3.456 46.4431 3.456C46.8378 3.456 47.1631 3.584 47.4191 3.84C47.6858 4.08533 47.8191 4.39467 47.8191 4.768C47.8191 5.14133 47.6858 5.456 47.4191 5.712C47.1631 5.95733 46.8378 6.08 46.4431 6.08ZM47.5471 7.136V16H45.3071V7.136H47.5471ZM54.6723 7.008C55.7283 7.008 56.5816 7.344 57.2323 8.016C57.8829 8.67733 58.2083 9.60533 58.2083 10.8V16H55.9683V11.104C55.9683 10.4 55.7923 9.86133 55.4403 9.488C55.0883 9.104 54.6083 8.912 54.0003 8.912C53.3816 8.912 52.8909 9.104 52.5283 9.488C52.1763 9.86133 52.0003 10.4 52.0003 11.104V16H49.7603V7.136H52.0003V8.24C52.2989 7.856 52.6776 7.55733 53.1363 7.344C53.6056 7.12 54.1176 7.008 54.6723 7.008ZM62.9464 8.976V13.264C62.9464 13.5627 63.0157 13.7813 63.1544 13.92C63.3037 14.048 63.549 14.112 63.8904 14.112H64.9304V16H63.5224C61.6344 16 60.6904 15.0827 60.6904 13.248V8.976H59.6344V7.136H60.6904V4.944H62.9464V7.136H64.9304V8.976H62.9464Z"
              fill="#355DFF"
            />
            <rect
              x="4.16699"
              y="14.166"
              width="11.6667"
              height="5"
              stroke="#355DFF"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <rect
              x="4.16699"
              y="0.833984"
              width="11.6667"
              height="4.16667"
              stroke="#355DFF"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M0.833008 8C0.833008 6.34315 2.17615 5 3.83301 5H16.1663C17.8232 5 19.1663 6.34315 19.1663 8V14.1667H0.833008V8Z"
              stroke="#355DFF"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <rect
              x="14.167"
              y="9.16602"
              width="1.66667"
              height="1.66667"
              rx="0.833333"
              stroke="#355DFF"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </>
      }
      visible={isModalVisible}
      onOk={handleOk}
      width="800px"
      userDetailModal
      onCancel={handleCancel}
    >
      <PaySlip
        paySlipBid={paySlipBid}
        visible={paySlip}
        handleOk={() => {
          setPaySlip(!paySlip);
          handleCancel();
        }}
        handleCancel={() => {
          setPaySlip(!paySlip);
        }}
      />

      <AddUser
        isOpen={userDetailModal}
        placement={placement}
        closeModal={() => setUserDetailModal(false)}
        submitForm={() => setUserDetailModal(false)}
        isFormLoading={false}
        selectedUser={userList[userToShowIndex]}
      />
      <Row>
        <Col>
          <Typography.Title className="titleOnDetails">
            {`${selectedBit?.postDetails?.postDetails.subType} (${selectedBit?.postDetails?.postDetails.status}) by ${userList[1]?.name}`}{" "}
            <a
              style={{
                fontWeight: 400,
                fontSize: "12px",
                color: "black",
                marginLeft: "12px",
                cursor: "pointer",
              }}
              href={`tel:${userList[1]?.phoneNumber}`}
            >
              {userList[1]?.phoneNumber}
            </a>
          </Typography.Title>
        </Col>
      </Row>

      <Row gutter={[16, 0]} justify="space-between" align="center">
        <Col span={5}>
          <Typography.Title className="tableOfHeading">Id:</Typography.Title>
        </Col>
        <Col span={5}>
          {selectedBit?.postDetails?.autoId}{" "}
          <svg
            onClick={() => {
              navigator.clipboard.writeText(selectedBit?.postDetails?.autoId);
            }}
            style={{ cursor: "pointer" }}
            width="18"
            height="18"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16 12.9V17.1C16 20.6 14.6 22 11.1 22H6.9C3.4 22 2 20.6 2 17.1V12.9C2 9.4 3.4 8 6.9 8H11.1C14.6 8 16 9.4 16 12.9Z"
              fill="#292D32"
            />
            <path
              opacity="0.4"
              d="M17.0998 2H12.8998C9.44976 2 8.04977 3.37 8.00977 6.75H11.0998C15.2998 6.75 17.2498 8.7 17.2498 12.9V15.99C20.6298 15.95 21.9998 14.55 21.9998 11.1V6.9C21.9998 3.4 20.5998 2 17.0998 2Z"
              fill="#292D32"
            />
          </svg>
        </Col>
        <Col span={2}></Col>
        <Col span={6}>
          <Typography.Title className="tableOfHeading">
            Category:
          </Typography.Title>
        </Col>
        <Col span={5}>{selectedBit?.postDetails?.postDetails.type}</Col>
        <Col span={1}></Col>
        <Col span={6}>
          <Typography.Title className="tableOfHeading">
            Size / Floor:{" "}
          </Typography.Title>
        </Col>
        <Col span={5}>
          {`${selectedBit?.postDetails?.postDetails?.size} gram` || "N/A"}
        </Col>
        <Col span={1}></Col>
        <Col span={6}>
          <Typography.Title className="tableOfHeading">
            Quantity
          </Typography.Title>
        </Col>
        <Col span={5}>
          {selectedBit?.postDetails?.postDetails.quantity || "N/A"}
        </Col>
        <Col span={1}></Col>
        <Col span={6}>
          <Typography.Title className="tableOfHeading">
            Loading Points
          </Typography.Title>
        </Col>
        <Col span={5}>
          {selectedBit?.postDetails?.postDetails.loadingPoints || "N/A"}
        </Col>
        <Col span={1}></Col>
        <Col span={6}>
          <Typography.Title className="tableOfHeading">Demand</Typography.Title>
        </Col>
        <Col span={5}>
          {selectedBit?.postDetails?.postDetails.demand || "N/A"}
        </Col>
        <Col span={1}></Col>
        <Col span={6}>
          <Typography.Title className="tableOfHeading">
            Demand Per Kgs:
          </Typography.Title>
        </Col>
        <Col span={5}>
          {selectedBit?.postDetails?.postDetails.demandPerKg || "N/A"}
        </Col>
        <Col span={1}></Col>
        <Col span={6}>
          <Typography.Title className="tableOfHeading">
            Demand Per Bird:
          </Typography.Title>
        </Col>
        <Col span={5}>
          {selectedBit?.postDetails?.postDetails.demandPerBird || "N/A"}
        </Col>
        <Col span={1}></Col>
        <Col span={6}>
          <Typography.Title className="tableOfHeading">
            Age (Weeks):
          </Typography.Title>
        </Col>
        <Col span={5}>
          {selectedBit?.postDetails?.postDetails.ageInWeeks || "N/A"}
        </Col>
        <Col span={1}></Col>
        <Col span={6}>
          <Typography.Title className="tableOfHeading">
            No. of Birds:
          </Typography.Title>
        </Col>
        <Col span={5}>
          {selectedBit?.postDetails?.postDetails.numberOfBirds || "N/A"}
        </Col>
        <Col span={1}></Col>
        <Col span={6}>
          <Typography.Title className="tableOfHeading">
            Total Weight In Kgs:
          </Typography.Title>
        </Col>
        <Col span={5}>
          {selectedBit?.postDetails?.postDetails.totalWeightInKg || "N/A"}
        </Col>
        <Col span={1}></Col>

        <Col span={6}>
          <Typography.Title className="tableOfHeading">
            Total Weight(Kgs):
          </Typography.Title>
        </Col>
        <Col span={5}>
          {selectedBit?.postDetails?.postDetails.totalWeightInKg || "N/A"}
        </Col>
        <Col span={1}></Col>
      </Row>

      <Row>
        <Col className="box_chat" span={12}>
          <Row>
            <Col
              span={24}
              style={{
                padding: "10px",
                height: "170px",
              }}
            >
              <div
                style={{ background: "white", cursor: "pointer" }}
                onClick={() => {
                  setUserDetailModal(true);
                  setUserToShowIndex(1);
                  setPlacement("left");
                }}
                className="blockOrderDashboardStat primaryColor"
              >
                <Avatar
                  src={
                    userList[1]?.profileImage ? (
                      <Image src={userList[1]?.profileImage} alt="profile" />
                    ) : (
                      "https://via.placeholder.com/100"
                    )
                  }
                  shape="circle"
                />
                <h4 className="primaryColor">{userList[1]?.name}</h4>

                <p>(Seller)</p>

                {selectedBit?.paymentStatusOwner === "PAID" ? (
                  <h4
                    className={
                      selectedBit?.paymentStatusOwner === "PAID"
                        ? "paid"
                        : "unpaid"
                    }
                  >
                    Paid
                  </h4>
                ) : (
                  <h4
                    className={
                      selectedBit?.paymentStatusOwner === "PAID"
                        ? "paid"
                        : "unpaid"
                    }
                  >
                    UN Paid
                  </h4>
                )}
              </div>
              {selectedBit?.paymentStatusOwner === "PAID" ? (
                <></>
              ) : (
                <Button
                  className="Upload_Button"
                  onClick={() => {
                    setPaySlip(!paySlip);
                    setPaySlipBid({ user: userList[1], bid: selectedBit });
                  }}
                  type="primary"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.083 8.49101H14.6747C12.6997 8.49101 11.0913 6.88268 11.0913 4.90768V2.49935C11.0913 2.04102 10.7163 1.66602 10.258 1.66602H6.72467C4.15801 1.66602 2.08301 3.33268 2.08301 6.30768V13.691C2.08301 16.666 4.15801 18.3327 6.72467 18.3327H13.2747C15.8413 18.3327 17.9163 16.666 17.9163 13.691V9.32435C17.9163 8.86601 17.5413 8.49101 17.083 8.49101ZM9.60801 11.2743C9.48301 11.3993 9.32467 11.4577 9.16634 11.4577C9.00801 11.4577 8.84967 11.3993 8.72467 11.2743L8.12467 10.6743V14.166C8.12467 14.5077 7.84134 14.791 7.49967 14.791C7.15801 14.791 6.87467 14.5077 6.87467 14.166V10.6743L6.27467 11.2743C6.03301 11.516 5.63301 11.516 5.39134 11.2743C5.14967 11.0327 5.14967 10.6327 5.39134 10.391L7.05801 8.72435C7.11634 8.67435 7.17467 8.63268 7.24134 8.59935C7.25801 8.59102 7.28301 8.58268 7.29967 8.57435C7.34967 8.55768 7.39967 8.54935 7.45801 8.54102C7.48301 8.54102 7.49967 8.54102 7.52467 8.54102C7.59134 8.54102 7.65801 8.55768 7.72467 8.58268C7.73301 8.58268 7.73301 8.58268 7.74134 8.58268C7.80801 8.60768 7.87467 8.65768 7.92467 8.70768C7.93301 8.71602 7.94134 8.71602 7.94134 8.72435L9.60801 10.391C9.84967 10.6327 9.84967 11.0327 9.60801 11.2743Z"
                      fill="white"
                    />
                    <path
                      d="M14.5251 7.34207C15.3167 7.3504 16.4167 7.3504 17.3584 7.3504C17.8334 7.3504 18.0834 6.79207 17.7501 6.45873C16.5501 5.2504 14.4001 3.0754 13.1667 1.84207C12.8251 1.5004 12.2334 1.73373 12.2334 2.20873V5.11707C12.2334 6.33373 13.2667 7.34207 14.5251 7.34207Z"
                      fill="white"
                    />
                  </svg>
                  <span style={{ position: "relative", top: "-6px" }}>
                    Upload Slip
                  </span>
                </Button>
              )}
            </Col>
            <Col
              span={24}
              style={{
                padding: "10px",
              }}
            >
              <div
                style={{
                  background: "white",
                  height: "300px",
                  overflow: "scroll",
                }}
                className=" primaryColor"
              >
                <h4>Message from seller</h4>
                <Message userId={userList[1]?._id} biddingGet={biddingGet} />
              </div>
            </Col>
          </Row>
        </Col>
        <Col className="box_chat_Second" span={12}>
          <Row>
            <Col
              span={24}
              style={{
                padding: "10px",
                height: "170px",
              }}
            >
              <div
                style={{ background: "white", cursor: "pointer" }}
                className="blockOrderDashboardStat primaryColor"
                onClick={() => {
                  setUserDetailModal(true);
                  setUserToShowIndex(0);
                  setPlacement("right");
                }}
              >
                <Avatar
                  src={
                    userList[0]?.profileImage ? (
                      <Image src={userList[0]?.profileImage} alt="profile" />
                    ) : (
                      "https://via.placeholder.com/100"
                    )
                  }
                  shape="circle"
                />
                <h4 className="primaryColor">{userList[0]?.name}</h4>

                <p>(Buyer)</p>

                {selectedBit?.paymentStatusClient === "PAID" ? (
                  <h4
                    className={
                      selectedBit?.paymentStatusClient === "PAID"
                        ? "paid"
                        : "unpaid"
                    }
                  >
                    Paid
                  </h4>
                ) : (
                  <h4
                    className={
                      selectedBit?.paymentStatusClient === "PAID"
                        ? "paid"
                        : "unpaid"
                    }
                  >
                    Un Paid
                  </h4>
                )}
              </div>

              {selectedBit?.paymentStatusClient === "PAID" ? (
                <></>
              ) : (
                <Button
                  className="Upload_Button"
                  onClick={() => {
                    setPaySlip(!paySlip);
                    setPaySlipBid({ user: userList[0], bid: selectedBit });
                  }}
                  type="primary"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.083 8.49101H14.6747C12.6997 8.49101 11.0913 6.88268 11.0913 4.90768V2.49935C11.0913 2.04102 10.7163 1.66602 10.258 1.66602H6.72467C4.15801 1.66602 2.08301 3.33268 2.08301 6.30768V13.691C2.08301 16.666 4.15801 18.3327 6.72467 18.3327H13.2747C15.8413 18.3327 17.9163 16.666 17.9163 13.691V9.32435C17.9163 8.86601 17.5413 8.49101 17.083 8.49101ZM9.60801 11.2743C9.48301 11.3993 9.32467 11.4577 9.16634 11.4577C9.00801 11.4577 8.84967 11.3993 8.72467 11.2743L8.12467 10.6743V14.166C8.12467 14.5077 7.84134 14.791 7.49967 14.791C7.15801 14.791 6.87467 14.5077 6.87467 14.166V10.6743L6.27467 11.2743C6.03301 11.516 5.63301 11.516 5.39134 11.2743C5.14967 11.0327 5.14967 10.6327 5.39134 10.391L7.05801 8.72435C7.11634 8.67435 7.17467 8.63268 7.24134 8.59935C7.25801 8.59102 7.28301 8.58268 7.29967 8.57435C7.34967 8.55768 7.39967 8.54935 7.45801 8.54102C7.48301 8.54102 7.49967 8.54102 7.52467 8.54102C7.59134 8.54102 7.65801 8.55768 7.72467 8.58268C7.73301 8.58268 7.73301 8.58268 7.74134 8.58268C7.80801 8.60768 7.87467 8.65768 7.92467 8.70768C7.93301 8.71602 7.94134 8.71602 7.94134 8.72435L9.60801 10.391C9.84967 10.6327 9.84967 11.0327 9.60801 11.2743Z"
                      fill="white"
                    />
                    <path
                      d="M14.5251 7.34207C15.3167 7.3504 16.4167 7.3504 17.3584 7.3504C17.8334 7.3504 18.0834 6.79207 17.7501 6.45873C16.5501 5.2504 14.4001 3.0754 13.1667 1.84207C12.8251 1.5004 12.2334 1.73373 12.2334 2.20873V5.11707C12.2334 6.33373 13.2667 7.34207 14.5251 7.34207Z"
                      fill="white"
                    />
                  </svg>
                  <span style={{ position: "relative", top: "-6px" }}>
                    Upload Slip
                  </span>
                </Button>
              )}
            </Col>

            <Col
              span={24}
              style={{
                padding: "10px",
                overflow: "auto",
              }}
            >
              <div
                style={{ background: "white", height: "300px" }}
                className=" primaryColor"
              >
                <h4>Message from buyer</h4>
                <Message userId={userList[0]?._id} biddingGet={biddingGet} />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
}

{
  /* <Row justify="center" align="middle">
  <Col
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      marginBottom: "20px",
      background: "yellow",
    }}
    span={16}
  >
    <div
      style={{
        width: "100px",
        display: "flex",
        justifyContent: "center",
        alignItem: "center",
        height: "100px",
        flexDirection: "column",
        cursor: "pointer",
      }}
      className=" primaryColor"
    >
      <h4 className="primaryColor">Post Detail</h4>
    </div>

    {selectedBit ? (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: "10px",
        }}
        className="primaryColor"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <p
            style={{
              width: "150px",
            }}
          >
            Id
          </p>
          <p style={{ textDecorationStyle: "capitalize" }}>
            {selectedBit?.postDetails?.autoId}
          </p>
        </div>
        {Object.keys(selectedBit?.postDetails?.postDetails).map((key) => {
          if (key !== "_id")
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  textDecoration: "capitalize",
                }}
                key={key}
              >
                <p
                  style={{
                    textTransform: "capitalize",
                    width: "150px",
                  }}
                >
                  {key.replace("status", "Post Type")}
                </p>
                <p
                  style={{
                    textTransform: "capitalize",
                  }}
                >
                  {selectedBit.postDetails.postDetails[key]}
                </p>
              </div>
            );
        })}
      </div>
    ) : (
      <></>
    )}
  </Col>
  <Col
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      marginBottom: "20px",
      background: "yellow",
    }}
    span={16}
  >
    <div
      style={{
        width: "100px",
        display: "flex",
        justifyContent: "center",
        alignItem: "center",
        height: "100px",
        flexDirection: "column",
        cursor: "pointer",
      }}
      onClick={() => {
        setUserDetailModal(true);
        setUserToShowIndex(1);
      }}
      className=" primaryColor"
    >
      <Avatar
        src={
          userList[1]?.profileImage ? (
            <Image src={userList[1]?.profileImage} alt="profile" />
          ) : (
            "https://via.placeholder.com/100"
          )
        }
        shape="circle"
      />
      <h4 className="primaryColor">{userList[1]?.name}</h4>
      <p>(Seller)</p>
    </div>

    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px",
      }}
      className="primaryColor"
    >
      <h2> Order Number</h2>
      <h2> {selectedBit?._id}</h2>

      <h2> Order Detail</h2>
      <h1>PKR {selectedBit?.offeredPrice}/-</h1>
    </div>

    <div
      style={{
        width: "100px",
        display: "flex",
        justifyContent: "center",
        alignItem: "center",
        height: "100px",
        cursor: "pointer",
        flexDirection: "column",
      }}
      onClick={() => {
        setUserDetailModal(true);
        setUserToShowIndex(0);
      }}
      className=" primaryColor"
    >
      <Avatar
        src={
          userList[0]?.profileImage ? (
            <Image src={userList[0]?.profileImage} alt="profile" />
          ) : (
            "https://via.placeholder.com/100"
          )
        }
        shape="circle"
      />
      <h4 className="primaryColor">{userList[0]?.name}</h4>
      <p>(Buyer)</p>
    </div>
  </Col>
</Row>; */
}
